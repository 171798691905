import { CanvasUtil } from './canvas-util.js';
import { calcurateRadian, calculateVertexCoordinate } from './calculate-helper.js';

export class ChartB extends CanvasUtil {
  static CANVAS_RATIO = [2, 1];
  static RECTANGLE_RATIO = [5, 4];
  static COL_AND_ROW = [6, 4];
  static LEFT_LABEL = ['行動する', 'きめ細か', '現実的', '合理的', '計画的'];
  static RIGHT_LABEL = ['考える', '広い視野', '革新的', '感性的', '臨機応変'];
  #baseCircleRadius;
  #baseRectangle;
  #renderQue = [];

  constructor(canvas, canvasWrapper) {
    super(canvas, canvasWrapper);
  }

  addChart({color = CanvasUtil.C_BLACK, score = [...Array(ChartC.ITEM_LABEL.length)].map(() => 50)} = {}) {
    this.#renderQue.push({ color, score });
    return this;
  }

  render() {
    this.#calculateCanvas();
    this.#renderDotmap();
    this.#renderText();
    this.#renderQue.forEach((chart, index) => {
      this.#renderData(chart, index);
    });
  }

  #renderDotmap() {
    for (let rowIndex  = 0; rowIndex <= ChartB.COL_AND_ROW[1]; rowIndex++) {
      for (let colIndex = 0; colIndex <= ChartB.COL_AND_ROW[0]; colIndex++) {
        const coordinate = [
          this.#baseRectangle.tl[0] + (this.#baseRectangle.xInterval * colIndex),
          this.#baseRectangle.tl[1] + (this.#baseRectangle.yInterval * rowIndex),
        ];
        this.drawCircle(coordinate, 4, 0, 360, { fillStyle: CanvasUtil.C_GRAY }, true, false, false);
      }
    }
  }

  #renderText() {
    ChartB.LEFT_LABEL.forEach((label, index) => {
      const coordinate = [
        this.#baseRectangle.tl[0] - (this.#baseRectangle.tl[0] * 0.2),
        this.#baseRectangle.tl[1] + (this.#baseRectangle.yInterval * index),
      ];
      this.drawText(coordinate, label, { font: `bold 13px ${CanvasUtil.DEFAULT_FONT}`, textAlign: 'right', textBaseline: 'middle' });
    });
    ChartB.RIGHT_LABEL.forEach((label, index) => {
      const coordinate = [
        this.#baseRectangle.tr[0] + (this.#baseRectangle.tl[0] * 0.2),
        this.#baseRectangle.tr[1] + (this.#baseRectangle.yInterval * index),
      ];
      this.drawText(coordinate, label, { font: `bold 13px ${CanvasUtil.DEFAULT_FONT}`, textAlign: 'left', textBaseline: 'middle' });
    })
  }

  #renderData({ color, score }, chartQueIndex) {
    const coordinateList = score.map((value, index) => {
      return [
        this.#baseRectangle.tl[0] + (this.#baseRectangle.xInterval * this.#calculateScoreLevel(100 - value)),
        this.#baseRectangle.tl[1] + (this.#baseRectangle.yInterval * index)
      ];
    });
    this.drawStraightLineShape(coordinateList, { strokeStyle: color, lineWidth: 2, setLineDash: [2, 4], lineCap: 'round' }, false, true, false);
    coordinateList.forEach((coordinate) => {
      if ((chartQueIndex + 1) % 2 === 0) {
        this.drawCircle(coordinate, 4, 0, 360, { fillStyle: color }, true, false, false);
      } else {
        this.drawCircle(coordinate, 5, 0, 360, { strokeStyle: color, fillStyle: '#fff', lineWidth: 3 }, true, true, false);
      }
    });
  }

  #calculateCanvas() {
    super.calculateCanvas(ChartB.CANVAS_RATIO);
    const angle = Math.atan((ChartB.RECTANGLE_RATIO[1]) / ChartB.RECTANGLE_RATIO[0])  * 180 / Math.PI;
    this.#baseCircleRadius = this.width * 0.3;
    this.#baseRectangle = {
      tl: calculateVertexCoordinate(this.canvasCenterCoordinate, this.#baseCircleRadius, calcurateRadian(180 + angle), 100),
      tr: calculateVertexCoordinate(this.canvasCenterCoordinate, this.#baseCircleRadius, calcurateRadian(360 - angle), 100),
      br: calculateVertexCoordinate(this.canvasCenterCoordinate, this.#baseCircleRadius, calcurateRadian(angle), 100),
      bl: calculateVertexCoordinate(this.canvasCenterCoordinate, this.#baseCircleRadius, calcurateRadian(180 - angle), 100),
      get width() {
        return this.tr[0] - this.tl[0];
      },
      get height() {
        return this.bl[1] - this.tl[1];
      },
      get xInterval() {
        return this.width / ChartB.COL_AND_ROW[0];
      },
      get yInterval() {
        return this.height / ChartB.COL_AND_ROW[1];
      }
    };
  }

  #calculateScoreLevel(score) {
    if (score < 20) {
      return 0;
    } else if (score < 40) {
      return 1;
    } else if (score < 48) {
      return 2;
    } else if (score < 52) {
      return 3;
    } else if (score < 60) {
      return 4;
    } else if (score < 80) {
      return 5;
    } else if (score <= 100) {
      return 6;
    }
  }
}
