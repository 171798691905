<template>
  <div>
    <div class="dl-table-join -collapse0 mt20" v-for="(mail, i) in mails" :key="`${mail}-${i}`">
      <dl>
        <dt class="fz95">メールアドレス{{i + 1}}</dt>
        <dd>
          <div class="validate-flag field-wrap">
            <input class="w100" placeholder="noreply@ciy-work.com" required="required" type="email" name="notify_add_mail[]" @input="onInput($event, i)" :value="mail">
            <div class="delete hov" @click="deleteInput(i)"></div>
          </div>
        </dd>
      </dl>
    </div>
    <div class="add hov m20" @click="addInput">
      <div class="add-icon"></div>
      <p class="fw-b c-key">通知用メールアドレス追加</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propNotifyAddMail: Array,
  },
  data() {
    return {
      mails: null
    }
  },
  created() {
    this.mails = this.propNotifyAddMail;
  },
  methods: {
    onInput(e, i) {
      this.mails[i] = e.target.value;
    },
    addInput() {
      this.mails.push('');
    },
    deleteInput(i) {
      this.mails.splice(i, 1);
    }
  }
}
</script>

<style>
</style>
