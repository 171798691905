$(window).on('turbolinks:load', function() {
  //【共通】トーテム診断
  {
    /* 関数：次のカードが出現 */
    function card_answer(card) {
      //このカード
      $(card).parents(".card").removeClass("-current").addClass("-answered");
      //この前以前のカード
      $(card).parents(".card").prev(".card").removeClass("-answered").addClass("-hide");
      //この次以降のカード
      $(card).parents(".card").next(".card").removeClass("-unanswered").addClass("-current").next(".card").removeClass("-hide").addClass("-unanswered");
    }
    /* 関数：前のカードに戻る */
    function card_back(card) {
      //このカード
      $(card).parents(".card").removeClass("-answered").addClass("-current");
      //この前以前のカード
      $(card).parents(".card").prev(".card").removeClass("-hide").addClass("-answered");
      //この次以降のカード
      $(card).parents(".card").next(".card").removeClass("-current").addClass("-unanswered").next(".card").removeClass("-unanswered").addClass("-hide");
    }
    /* 非言語診断E, I, G, H, J, C, B, K, L */
    /* タップしたら次へ */
    $(".form-flame").find(".card.-question-e, .card.-question-i, .card.-question-g, .card.-question-h, .card.-question-j, .card.-question-b, .card.-question-k, .card.-question-l, .card.-question-m, .card.-question-n, .card.-question-o, .card.-question-p").find(".answers input").change(function() {
      card_answer(this);
    });
    /* 「1つ前の設問へ」でカードの選択を取り消す */
    $(".form-flame").find(".card.-question-e, .card.-question-i, .card.-question-g, .card.-question-h, .card.-question-j, .card.-question-c, .card.-question-b, .card.-question-k, .card.-question-l, .card.-question-m, .card.-question-n, .card.-question-o, .card.-question-p, .card.-question-preferredConditions").find(".card-back").click(function() {
      $(this).parents('.card').find('input').prop('checked', false);
      card_back(this);
    });
    /* 「1つ前の設問へ」でチェックをつけ直す */
    $(".form-flame").find(".card.-question-d2").find(".card-back").click(function() {
      $(this).parents('.card').find('input').prop('checked', true);
      card_back(this);
    });
    /* 非言語診断F */
    var Array = [];
    /* タップしたら配列にカスタム属性を追加・削除して数字を表示 */
    $(".form-flame").find(".card.-question-f, .card.-question-d, .card.-question-q").find(".answers input").change(function() {
      var type = $(this).parent("label").data('type');
      if (Array.indexOf(type) != -1) { //含む場合の処理
        var idx = Array.indexOf(type);
        if (idx >= 0) {
          Array.splice(idx, 1);
        }
        $(".card.-current label .selected-num").text("");
      } else { //含まない場合の処理
        Array.push(type); //配列に追加する
      }
      $.each(Array, function(index, value) {
        $('.card.-current label[data-type="' + value + '"] .selected-num').text(index + 1);
      })
    });
    /* 全て選択したら.cardのclassによって各動作を実行 */
    $(".form-flame").find(".card.-question-f, .card.-question-d, .card.-question-q").find(".answers input").change(function() {
      var input_count = $('.card.-current input').length;
      var check_count = $('.card.-current input:checked').length;
      /* 全て選択したら次へ */
      if ($(this).parents(".card").hasClass("-question-f")) {
        if (input_count == check_count) {
          Array = [];
          card_answer(this);
        }
        /* 全て選択したら「次へ」ボタンがアクティブ */
      } else if ($(this).parents(".card").hasClass("-question-d")) {
        if (input_count == check_count) {
          $(this).parents(".card").find(".next-card").removeClass("no-active");
        } else {
          $(this).parents(".card").find(".next-card").addClass("no-active");
        }
      } else if ($(this).parents(".card").hasClass("-question-q")) {
        setTimeout(() => {
          if (input_count == check_count) {
            Array = [];
            card_answer(this);
          }
        }, 300);
      }
    });
    /* 「次へ」ボタンで次へ */
    $(".form-flame").find(".card").find(".next-card").click(function() {
      Array = [];
      card_answer(this);
    });
    /* 「1つ前の設問へ」でカードの選択を取り消す */
    $(".form-flame").find(".card.-question-f, .card.-question-d, .card.-question-q").find(".card-back").click(function() {
      $(this).parents('.card').find('input').prop('checked', false);
      $(this).parents('.card').find(".selected-num").text("");
      Array = [];
      card_back(this);
      if ($(this).parents(".card").hasClass("-question-d") || $(this).parents(".card").hasClass("-question-q")) {
        $(this).parents(".card").find(".next-card").addClass("no-active");
      }
    });
    /* 複数選択中に「1つ前の設問へ」をタップした場合は配列を空にしてカードの選択を取り消す */
    $(".form-flame .card .card-back").click(function() {
      var this_is = this;
      if ($(this_is).parents(".card").next(".card").hasClass("-question-f") || $(this_is).parents(".card").next(".card").hasClass("-question-d") || $(this_is).parents(".card").next(".card").hasClass("-question-q")) {
        $(this_is).parents(".card").next('.card').find('input').prop('checked', false);
        $(this_is).parents('.card').find(".selected-num").text("");
        Array = [];
        if ($(this_is).parents(".card").next(".card").hasClass("-question-d") || $(this_is).parents(".card").next(".card").hasClass("-question-q")) {
          $(this_is).parents(".card").next('.card').find(".next-card").addClass("no-active");
        }
      }
    });

    /* 診断C：△を10回答でアラートが出現 */
    $('.card.-question-c input[type="radio"]').click(function() {
      var count = $('.card.-question-c .neither input[type="radio"]:checked').length;
      if (count >= 10) {
        $('.cheering-comments li').css('display', 'none');
        $(".cheering-comments").find('li[data-index="question-c"]').css('display', 'flex').addClass("act");
        $(".card.-question-c.-current").addClass("neither-full");
        setTimeout(function() {
          $(".card.-question-c.-current").removeClass("neither-full");
        }, 300);
      } else {
        card_answer(this);
        $(".cheering-comments").find('li[data-index="question-c"]').css('display', 'none').removeClass("act");
        $(".card").removeClass("neither-full");
      }
    });

    //【共通】診断中の応援コメント
    if ($('.cheering-comments').length) {
      var timing = [];
      $('.cheering-comments li').each(function() {
        var index = $(this).attr('data-index');
        timing.push(index);
      });
      //設問の回答で、応援コメントが出現
      $(".card label").on('click', function() {
        var current_num = $(this).parents('.card').find('.num span').text();
        if (timing.indexOf(current_num) !== -1) {
          $('.cheering-comments li').css('display', 'none');
          $(".cheering-comments").find('li[data-index="' + current_num + '"]').css('display', 'flex').addClass("act");
        }
      });
    }

    //【共通】設問D2のみ常時出現
    if ($('.card-wrap.-question-d2').length) {
      $(".cheering-comments").find('li[data-index="question-d2"]').css('display', 'flex').addClass("act");
      $(".card label").on('click', function() {
        $(".cheering-comments").find('li[data-index="question-d2"]').css('display', 'flex').addClass("act");
      });
    }

    // 必ず1つはチェックを入れないとボタンがアクティブにならない
    if (!!document.querySelectorAll('.card.-question-preferredConditions.js-checkRequiredCard')) {
      const cards = document.querySelectorAll('.card.-question-preferredConditions.js-checkRequiredCard');
      const checkedLength = (card) => card.querySelectorAll('input[type="checkbox"]:checked').length;

      cards.forEach((card) => {
        const button = card.querySelector('.next-card');
        checkedLength(card) >= 1 ? button.classList.remove('no-active') : button.classList.add('no-active');

        card.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
          checkbox.addEventListener('change', () => {
            if (checkedLength(card) >= 1) {
              button.classList.remove('no-active');
            } else {
              button.classList.add('no-active');
            }
          });
        });
      });
    }
  }
});
