import Croppie from 'croppie';
import {
  getSearchCompany
} from "./ajax.js";

import {
  EMOJI_REGEX
} from '../../utils/emoji-regex'

import {
  popupSuccess,
  popupError
} from "./popup.js";

const qs = require("../../utils/const.js")

$(window).on('turbolinks:load', function() {

  /* 全般：送信ボタンを押したときのバリデーション処理
  -------------------------------*/
  if ($('.js-validateButton').length) {
    var err_cnt;
    $(".js-validateButton").on("click", (e) => {
      err_cnt = 0;
      $(e.target).parents("form").find(".js-validateFlag").each(function() {
        err_cnt += front_varidation(this);
      });
      if (0 < err_cnt) {
        e.preventDefault();
      } else {}
    });

    /* 各入力欄の値を変えたときのバリデーション
    -------------------------------*/
    // $(".js-validateFlag input,.js-validateFlag select,.js-validateFlag textarea").on("change", (e) => {
    //   console.log(e.target);
    //   $(e.target).parents(".js-validateFlag").each(function() {
    //     $(e.target).parents('form').find('.js-validateButton').prop("disabled", false);
    //     front_varidation(e.target);
    //   });
    // });
  }

  /* 全般：プロフィール全般：質問箱に入力があった場合にクラス付与
  -------------------------------*/
  if ($('.js-questionBox').length) {
    $('.js-questionBox textarea').keyup(function() {
      var input = $(this).val();
      if (input != false) {
        $(this).parents('.js-questionBox').addClass('-answered');
      } else {
        $(this).parents('.js-questionBox').removeClass('-answered');
      }
    });
  }

  /* 全般：数字とドットのみ入力許可＋全角数字を半角数字に変換
  -------------------------------*/
  $('input.js-numOnly').on('input', function(e) {
    let value = $(e.currentTarget).val();
    value = value
      .replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/．/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/[^0-9\.]/g, '');
    $(e.currentTarget).val(value);
  });

  /* 全般：絵文字が入力されたら削除
  -------------------------------*/
  $('input[type!="file"], textarea').on('input', function(e) {
    const replaceValue = $(this).val().replace(EMOJI_REGEX, '');
    $(this).val(replaceValue);
  });

  /* 全般：親子関係にあるチェックボックスの制御
   -------------------------------*/
  $('.js-CheckSync-parent').on('click', function() {
    $(this).parents('.js-CheckSync-parent-wrap').find('.js-CheckSync-child').prop('checked', this.checked);
  });
  $('.js-CheckSync-child').on('click', function() {
    if ($(this).parents('.js-CheckSync-parent-wrap').find('.js-CheckSync-child:checked').length == $(this).parents('.js-CheckSync-parent-wrap').find('.js-CheckSync-child').length) {
      $(this).parents('.js-CheckSync-parent-wrap').find('.js-CheckSync-parent').prop('checked', 'checked');
    } else {
      $(this).parents('.js-CheckSync-parent-wrap').find('.js-CheckSync-parent').prop('checked', false);
    }
  });

  /* 求職者：企業カテゴリー絞り込み用JS
  -------------------------------*/
  if ($(".js-selectCategory-Target").length || $('.js-searchCompaniesList').length) {
    //モーダル内で選択した内容がページ上にも反映される
    $(".js-modalClose").on('click', function() {
      var target = $(this).parents(".js-searchCompaniesList");
      var target_type = target.data("copyval");
      var text = target.find("[type='checkbox']:checked").map(function() {
        return $(this).next('span').text();
      }).get().join('、');
      if (text == "") {
        text = "未指定"
      }
      $(".js-selectCategory-Target[data-pasteval='" + target_type + "']").text(text);
    });


    if ($('.js-countUp').length) {
      //読み込み時と、モーダルを消した際に下のカウントアップモジュールを実行
      $(function() {
        $(window).on('load', selectedCategoryCountUp());
        $(".js-modalClose").on('click', function() {
          selectedCategoryCountUp();
        });
      });
      ////ここから、選択されたカテゴリーの企業数を計算してカウントアップさせるモジュール
      function selectedCategoryCountUp() {
        //1:モーダルを閉じたときに、各タイプに対してチェックされているものを抽出
        var type = $("[name='type[]'][type='checkbox']:checked").not('.js-CheckSync-parent').map(function() {
          return $(this).val();
        }).get().join(',');
        var area = $("[name='area[]'][type='checkbox']:checked").not('.js-CheckSync-parent').map(function() {
          return $(this).val();
        }).get().join(',');
        var business = $("[name='business[]'][type='checkbox']:checked").not('.js-CheckSync-parent').map(function() {
          return $(this).val();
        }).get().join(',');
        var job = $("[name='job[]'][type='checkbox']:checked").not('.js-CheckSync-parent').map(function() {
          return $(this).val();
        }).get().join(',');
        //2:カウントアップのモジュールを宣言(ui.jsにもあるので後に統合できないか検討)
        var shuffleNumberCounter = (target) => {
          var targetNum = Number(target.data('num'))
          if (!targetNum) {
            return
          }
          var counterData = null
          var speed = 800 / targetNum
          var initNum = 0
          var countUp = () => {
            if (Number.isInteger(targetNum)) {
              target.text(initNum);
            } else {
              target.innerHTML = `${initNum}.${Math.floor(Math.random() * 9)}`
            }
            initNum++
            if (initNum > targetNum) {
              target.innerHTML = targetNum
              clearInterval(counterData)
            }
          }
          counterData = setInterval(countUp, speed)
        }
        //3:Ajaxで企業情報を取得し、成功したら数に反映させてカウントアップ
        getSearchCompany(type, area, business, job)
          .done((res) => {
            var target = $(".js-countUp");
            target.data("num", res.length);
            target.text("0");
            shuffleNumberCounter(target);
          })
          .fail((err) => {
            if (err.responseJSON.required_profile_completed) {
              location.href = err.responseJSON.redirect_url;
            } else {}
          });
      };
    }
    //「すべてのチェックを外す」
    $(".js-searchCompaniesList-allClear").on('click', function() {
      let target = $(this).parents(".js-searchCompaniesList");
      const uncheckedList = target.find("[type='checkbox']:checked").prop('checked', false);
      uncheckedList.each(function() {
        this.dispatchEvent(new Event('change'))
      });
      selectedLabel();
    });
    //子カテゴリーにチェックがついていたら「選択中」ラベルを出す
    $(".js-categprySelectedCheck label").on('click', selectedLabel);
    $(window).on('load', selectedLabel());

    function selectedLabel() {
      $(".js-categprySelectedCheck-box").each(function() {
        if ($(this).find("[type='checkbox']:checked").length) {
          $(this).find(".js-categprySelectedCheck-label").show();
        } else {
          $(this).find(".js-categprySelectedCheck-label").hide();
        }
      });
    };
  };

  /* トーテムスタイル：生年月日入力時のフォーカス自動遷移
  -------------------------------*/
  if ($('.js-cardnumber').length) {
    $('.js-cardnumber.-year').on('keyup', function() {
      var thisValueLength = $(this).val().length;
      if (thisValueLength == 4) {
        $(".js-cardnumber.-month").focus();
      }
    });
    $('.js-cardnumber.-month').on('keyup', function() {
      var thisValueLength = $(this).val().length;
      if (thisValueLength == 2) {
        $(".js-cardnumber.-day").focus();
      }
    });
  }

  /* レコメンド企業：お問い合わせフォームの確認モーダル
  -------------------------------*/
  if ($(".js-confirmContactForm").length) {
    const contactCategoriesPerson = [
      "この仕事についての質問",
      "企業訪問、説明会の参加希望",
      "インターンについて / インターン希望",
      "応募について",
    ]
    const contactCategoriesCompany = [
      "大学での専攻・研究内容についての質問",
      "企業訪問・説明会などへの招待",
      "これまでの職歴・経歴に関する質問",
      "その他",
    ]
    $(".js-confirmContactForm").click(function(e) {
      e.preventDefault();
      err_cnt = 0;
      $(this).parents("form").find(".js-validateFlag").each(function() {
        err_cnt += front_varidation(this);
      });
      var $form = $('form[name="contact_form"]')
      var category = $form.find('input[name="contact[category]"]:checked').val();
      var content = $form.find('textarea[name="contact[content]"]').val();
      if (err_cnt == 0) {
        var $modal = $('.js-modalWrap[data-modal="message"]');
        $modal.find("#contact-category-person").text(contactCategoriesPerson[category - 1]);
        $modal.find("#contact-category-company").text(contactCategoriesCompany[category - 1]);
        $modal.find("#contact-content").text(content);
        $modal.fadeIn(200).addClass('is-active').css('display', 'flex');
      }
    })
  }

  /* レコメンド企業：エントリーフォームの確認モーダル
  -------------------------------*/
  if ($(".js-confirmEntryForm").length) {
    $(".js-confirmEntryForm").click(function(e) {
      e.preventDefault();
      err_cnt = 0;
      $('form[name="entry_form"]').find(".js-validateFlag").each(function() {
        err_cnt += front_varidation(this);
      });
      var $form = $('form[name="entry_form"]')
      if (err_cnt == 0) {
        var $modal = $('.js-modalWrap[data-modal="entry"]');
        $modal.find("#entry-content").text($form.find('textarea[name="contact[content]"]').val());
        $modal.fadeIn(200).addClass('is-active').css('display', 'flex');
      }
    });
  }

  /*	レコメンド企業：業種・職種を１つだけ選択するモーダル
  -------------------------------*/
  if ($('.js-modalSearch').length) {
    //キーワード検索
    const searchWord = function() {
      var searchResult,
        searchText = $(this).val(), // 検索ボックスに入力された値
        targetText;
      // 検索ボックスに値が入ってる場合
      if (searchText != '') {
        $(this).parents('.js-typeSelectRadio').find('.box').each(function() {
          searchResult = [];
          $(this).find('label span').each(function() {
            targetText = $(this).text();
            $(this).parents('li').show();
            // 検索対象となるリストに入力された文字列が存在するかどうかを判断
            if (targetText.toLowerCase().indexOf(searchText) != -1) {
              // 存在する場合はそのリストのテキストを用意した配列に格納
              searchResult.push(targetText);
              $(this).show();
            } else {
              $(this).parents('li').hide();
            }
          });
          if (searchResult.length == 0) {
            $(this).hide();
          } else {
            $(this).show();
          }
        });
      } else {
        // 検索ボックスに値が入ってない場合
        $('.js-typeSelectRadio li').show();
        $('.js-typeSelectRadio .box').show();
      }
    };
    $('.js-modalSearch-text').on('input', searchWord);

    //モーダルで選んだ選択肢を、閉じたあとにselectに代入する
    $('.js-typeSelectRadio input[type="radio"]').change(function() {
      var type = $(this).attr('name');
      var val = $(this).val();
      $('select[name="' + type + '"]').val(val);
      setTimeout(() => {
        $('.js-modalWrap[data-modal="select-job"], .js-modalWrap[data-modal="select-business"]').fadeOut(200)
      }, 200);
    });
    //selectを押したときに、モーダル内の選んだ選択肢にチェックを入れる
    $('.js-modalSearch-selectTag').on('click', function() {
      var val = $(this).children('select').val();
      $('.js-typeSelectRadio input[type="radio"][value="' + val + '"]').prop('checked', true);
    });
  }

  /* プロフィール全般：自己PRから「自己紹介」入力欄にコピー
  -------------------------------*/
  if ($('.js-modalPrText-toggle').length) {
    //PRモーダルでのタブ切り替え
    $(".js-modalPrText-toggle").on("click", (e) => {
      var tab = $(e.currentTarget).data('tab');
      $('.js-modalPrText-toggle').removeClass("is-active");
      $(e.currentTarget).addClass("is-active");
      $('.js-modalPrText-body').css("display", "none");
      $('.js-modalPrText-body[data-tab="' + tab + '"]').css("display", "block");
    });
    //「挿入」ボタンを押すと、「自己紹介」入力欄にテキストが挿入される
    $(".js-modalPrText-button").on("click", (e) => {
      var text = $(e.currentTarget).parents('.js-modalPrText-body').find('.js-modalPrText-targetText').text();
      text = text.replace(/\t+/g, ""); //タブキーの空白を削除
      text = $.trim(text); //最初と最後の改行タグを削除
      text = text.replace(/ /g, ""); //半角スペースを削除
      text = text.replace(/\n/g, "\n\n"); //改行を空行に変換
      var target = $('.js-modalPrText-insertTarget');
      var target_text = target.val();
      target.val(target_text + text); //textareaにテキストを流す
      target.height(target.get(0).scrollHeight); //高さを自動調整
      $('.js-initialSubmit,.js-initialProgressBar').addClass("is-active"); //初回プロフィール入力時は、挿入ボタンを押したら次にいけるようにする
      const perNext = $('.js-initialProgressBar span').data('per-next');
      $('.js-initialProgressBar span').css('width', `${perNext}%`);
    });
  }

  /* プロフィール全般：サジェストを反映させるモジュール
  -------------------------------*/
  function suggestion_add() {
    $(".js-suggestField").each(function() {
      let type = $(this).data("suggesttype");
      if (type == "qualification") {
        $(this).autocomplete({
          source: qs.QUALIFICATION
        });
      } else if (type == "school") {
        $(this).autocomplete({
          source: qs.SCHOOL
        });
      } else if (type == "faculty") {
        $(this).autocomplete({
          source: qs.FACULTY
        });
      }
    });
  }
  suggestion_add();

  /* プロフィール全般：「増減／並び替え」BOX
  -------------------------------*/
  if ($('.js-variableBox-wrapper').length) {
    //読み込み時に要素が一つもない場合、１個空のBOXを作る
    $('.js-variableBox-wrapper').each(function(_, element) {
      if ($(element).find('.u-input-variableBox-main').length == 0) {
        var target = $(element).find('.u-input-variableBox-main-hide').clone();
        $(element).find('.js-variableBox-inner').append(target);
        $(target).removeClass('u-input-variableBox-main-hide').addClass('u-input-variableBox-main');
      }
    });
    variableBox_orderSet();
    suggestion_add();
    //項目を増やす
    $('.js-variableBox-addButton').on('click', function() {
      var target = $(this).parents('.js-variableBox-wrapper').find('.u-input-variableBox-main-hide').clone();
      $(this).parents('.js-variableBox-wrapper').children('.js-variableBox-inner').append(target);
      $(target).removeClass('u-input-variableBox-main-hide').addClass('u-input-variableBox-main');
      variableBox_orderSet();
      variableBox_initialRequireCheck(true);
      suggestion_add();
    });
    //項目を消す
    $('.js-variableBox-wrapper').on('click', '.js-variableBox-delete', function() {
      $(this).parents('.js-variableBox').fadeOut(500).queue(function() {
        this.remove();
        variableBox_orderSet();
        variableBox_initialRequireCheck(true);
      });
    });
    //並び替え：PREVを押したとき
    $('.js-variableBox-wrapper').on('click', '.js-variableBox-prev', function() {
      const selector = $(this).parents('.js-variableBox');
      selector.attr('data-order', Number(selector.attr("data-order")) - 1.1);
      variableBox_sort();
      variableBox_orderSet();
    });
    //並び替え：NEXTを押したとき
    $('.js-variableBox-wrapper').on('click', '.js-variableBox-next', function() {
      const selector = $(this).parents('.js-variableBox');
      selector.attr('data-order', Number(selector.attr("data-order")) + 1.1);
      variableBox_sort();
      variableBox_orderSet();
    });
    //ソートを行うモジュール
    function variableBox_sort() {
      $('.js-variableBox-inner').each(function() {
        $(this).html(
          $(this).find('.js-variableBox').sort(function(a, b) {
            //昇順に並べ替え
            if ($(a).data('order') > $(b).data('order')) {
              return 1;
            } else {
              return -1;
            }
          })
        )
      });
    };
    //番号(data-order)の再振り分けを行うモジュール
    function variableBox_orderSet() {
      $('.js-variableBox-inner').each(function() {
        var counter = 1;
        $(this).find('.js-variableBox').each(function() {
          $(this).attr('data-order', counter);
          counter++;
        });
      });
    };
  };

  /* フォーム: 増減するフィールド 改変
  -------------------------------*/
  if (
    !!document.querySelectorAll('.js-variableField').length &&
    !!document.querySelectorAll('.js-variableField-template').length &&
    !!document.querySelectorAll('.js-variableField-addButton').length
  ) {
    const addField = (key) => {
      const field = document.querySelector(`.js-variableField-template[data-variable-field-name="${key}"]`).content.cloneNode(true);
      const insertTarget = document.querySelector(`.js-variableField[data-variable-field-name="${key}"]`);
      insertTarget.appendChild(field);
      suggestion_add();
    }

    // 各フィールドのnameをインデックス付きにリネームする
    const renameFields = (fields) => {
      for (const [index, item] of Object.entries(fields.querySelectorAll('.js-variableField-item'))) {
        item.dataset.index = index;

        for (const field of item.querySelectorAll('[name]')) {
          const renamed = `${fields.dataset.variableFieldName}[${index}]${field.dataset.name}`;
          field.name = renamed;

          for (const errroTxt of item.querySelectorAll(`.js-ErrorTxt[data-field-name="${field.dataset.name}"]`)) {
            errroTxt.dataset.fieldName = renamed;
          }
        }
      }
    }

    for (const fields of document.querySelectorAll('.js-variableField')) {
      const key = fields.dataset.variableFieldName;

      const items = fields.querySelectorAll('.js-variableField-item');
      if (items.length <= 0) {
        addField(key);
      }

      renameFields(fields);

      // 追加ボタンを押したとき
      const addButton = document.querySelector(`.js-variableField-addButton[data-variable-field-name="${key}"]`);
      addButton.addEventListener('click', (e) => {
        addField(e.currentTarget.dataset.variableFieldName);
        renameFields(fields);
      });

      fields.addEventListener('click', (e) => {
        // 削除ボタンを押したとき
        const deleteButton = e.target.closest('.js-variableField-deleteButton');
        if (!!deleteButton) {
          const item = deleteButton.closest('.js-variableField-item');
          if (!!item) {
            item.animate({ opacity: [1, 0] }, 300);
            setTimeout(() => item.remove(), 300);
          }
        }
      });
    }
  }


  /* マイページ・プロフィール編集画面：送信する前に可変BOXのname値をリネームする
  -------------------------------*/
  if ($('.js-RenameOnSubmit-profilePage').length) {
    $('.js-RenameOnSubmit-profilePage').on("submit", function() {
      let renames = {
        "user_academic_histories": ["insert_year", "insert_month", "insert_school_kind", "insert_status", "insert_school", "insert_faculty"],
        "user_qualifications": ["insert_year", "insert_month", "insert_name"],
        "user_career_histories": ["insert_year", "insert_month", "insert_content", "insert_content_detail"],
      }
      Object.keys(renames).forEach(function(valI) {
        const key = valI
        renames[key].forEach(function(valJ) {
          const elNm = "[name='" + key + "_" + valJ + "']"
          const cnt = $(elNm).length - 1
          for (let i = 0; i < cnt; i++) {
            $(elNm + ":eq(0)").attr("name", key + "[" + ((i + 1) * -1) + "][" + valJ.replace("insert_", "") + "]")
          }
        })
      })
    })
  }

  /* マイページ・アカウント管理：送信する前に可変BOXのname値をリネームする
  -------------------------------*/
  if ($('.js-RenameOnSubmit-accountPage').length) {
    //オプション次へ(送信/保存)専用
    $('.js-RenameOnSubmit-accountPage').on("submit", function() {
      let renames = {
        "user_secrets": ["insert_company_name"]
      }
      Object.keys(renames).forEach(function(valI) {
        const key = valI
        renames[key].forEach(function(valJ) {
          const elNm = "[name='" + key + "_" + valJ + "']"
          const cnt = $(elNm).length - 1
          for (let i = 0; i < cnt; i++) {
            $(elNm + ":eq(0)").attr("name", key + "[" + ((i + 1) * -1) + "][" + valJ.replace("insert_", "") + "]")
          }
        })
      })
    })
  }

  /* イニシャルプロフィール全般：可変ボックスの必須チェックを行い、OKだったらボタンが押せるようにするモジュール
  -------------------------------*/
  const variableBox_initialRequireCheck = function(load) {
    let allOk = true
    let count = 0
    //プロフィールオプションはすべて必須がなくなってもOK
    if (!($('.js-variableBox').length)) {
      $('.js-initialSubmit, .js-initialProgressBar').removeClass("is-active");
      const perNow = $('.js-initialProgressBar span').data('per-now');
      $('.js-initialProgressBar span').css('width', `${perNow}%`);
    } else {
      $('.js-variableBox.u-input-variableBox-main').each(function(orderIdx, boxElement) {
        $(boxElement).find(".js-initialSubmitRequire").each(function(reqIdx, reqElement) {
          count++
          if (!$(reqElement).val()) {
            $('.js-initialSubmit,.js-initialProgressBar').removeClass("is-active");
            const perNow = $('.js-initialProgressBar span').data('per-now');
            $('.js-initialProgressBar span').css('width', `${perNow}%`);
            allOk = false
          }
          if (load) {
            $(reqElement).on('change.req keyup.req', function() {
              variableBox_initialRequireCheck(false)
            })
          }
        })
      })
      //必須が一つもない
      if (count === 0) {
        $('.js-initialSubmit,.js-initialProgressBar').removeClass("is-active");
        const perNow = $('.js-initialProgressBar span').data('per-now');
        $('.js-initialProgressBar span').css('width', `${perNow}%`);
      }
      //必須がある
      else {
        //必須が全部OKなら
        if (allOk) {
          $('.js-initialSubmit,.js-initialProgressBar').addClass("is-active");
          const perNext = $('.js-initialProgressBar span').data('per-next');
          $('.js-initialProgressBar span').css('width', `${perNext}%`);
        }
      }
    }
  }

  /* イニシャルプロフィール全般：「中断して保存」ボタンを押したときの送信
  -------------------------------*/
  //【求職者】イニシャルプロフィール全般：
  if ($('a.js-initialSaveBtn').length) {
    $('a.js-initialSaveBtn').on("click", function() {
      $('input[name="next"]').val("false");
      $('form#js-initialForm').submit();
    })
  }

  /* イニシャルプロフィール全般：ページごとのバリデーションがOKだったら、送信ボタンが押せるようになる
  -------------------------------*/
  if ($('.js-initialSubmit').length) {

    //次にいっていいときのDOM操作を、関数化！
    const initialProfileNextOk = () => {
      $('.js-initialSubmit,.js-initialProgressBar').addClass("is-active");
      const perNext = $('.js-initialProgressBar span').data('per-next');
      $('.js-initialProgressBar span').css('width', `${perNext}%`);
    };
    //次にいったらダメなときのDOM操作を、関数化！
    const initialProfileNextNg = () => {
      $('.js-initialSubmit,.js-initialProgressBar').removeClass("is-active");
      const perNow = $('.js-initialProgressBar span').data('per-now');
      $('.js-initialProgressBar span').css('width', `${perNow}%`);
    };

    //【テキスト、テキストエリア】
    if ($('.js-initialFlag[type="text"],textarea.js-initialFlag').length) {
      //ロード時
      if ($('.js-initialFlag[type="text"],textarea.js-initialFlag').val().length > 0) {
        initialProfileNextOk();
      }
      //変更時
      $('.js-initialFlag[type="text"],textarea.js-initialFlag').on('change keyup', function() {
        if ($(this).val().length > 0) {
          initialProfileNextOk();
        } else {
          initialProfileNextNg();
        };
      });
    }

    //【画像】
    if ($('.js-cropImage').length) {
      //ロード時
      if (!$('img[src="/img/profile/noimage-profile.png"]').length) {
        initialProfileNextOk();
      }
      //登録時
      $('.js-cropImage-cropButton').on('click', function() {
        initialProfileNextOk();
      });
      //削除時
      $('.js-cropImage-deleteButton').on('click', function() {
        initialProfileNextNg();
      });
    }

    //【自己紹介】（自己PR自動挿入ボタンを押したとき）
    $('.pr-text-modal .btn').on('click', function() {
      initialProfileNextOk();
    });

    // 希望雇用形態専用
    if ($('.js-requireCheck-recruitType').length) {
      //ロード時
      if ($('.js-requireCheck-recruitType').find('input[type="checkbox"]:checked').length > 0) {
        initialProfileNextOk();
      }
      //変更時
      $('.js-requireCheck-recruitType').on('change', function(e) {
        if ($(this).find('input[type="checkbox"]:checked').length > 0) {
          initialProfileNextOk();
        } else {
          initialProfileNextNg();
        }
      });
      //「すべてのチェックを外す」のクリック時
      $('.js-resetCheckbox').on('click', function() {
        initialProfileNextNg();
      });
    }

    // 【希望年収】
    if ($('.js-requireSelect-salary').length) {
      //ロード時
      if ($('.js-requireSelect-salary').val().length > 0) {
        initialProfileNextOk();
      }
      //変更時
      $('.js-requireSelect-salary').on('change', function(e) {
        if (!!e.target.value) {
          initialProfileNextOk();
        } else {
          initialProfileNextNg();
        }
      });
    }
  };

  /* イニシャルプロフィール（学歴）：送信する前に可変BOXのname値をリネームする
  -------------------------------*/
  if ($('.js-initialEducationForm').length) {
    $('.js-initialEducationForm').on("submit", function() {
      const renames = {
        "user_academic_histories": ["insert_year", "insert_month", "insert_school_kind", "insert_status", "insert_school", "insert_faculty"]
      }
      Object.keys(renames).forEach(function(valI) {
        const key = valI
        renames[key].forEach(function(valJ) {
          const elNm = "[name='" + key + "_" + valJ + "']"
          const cnt = $(elNm).length - 1
          for (let i = 0; i < cnt; i++) {
            $(elNm + ":eq(0)").attr("name", key + "[" + ((i + 1) * -1) + "][" + valJ.replace("insert_", "") + "]")
          }
        })
      })
    })
    //ロード時に必須が入力されているかチェック
    variableBox_initialRequireCheck(true)
  }

  /* イニシャルプロフィール（オプション）：送信する前に可変BOXのname値をリネームする
  -------------------------------*/
  if ($('.js-RenameOnSubmit-optionalPage').length) {
    //オプション次へ(送信/保存)専用
    $('.js-RenameOnSubmit-optionalPage').on("submit", function() {
      let renames = {
        "user_qualifications": ["insert_year", "insert_month", "insert_name"],
        "user_career_histories": ["insert_year", "insert_month", "insert_content", "insert_content_detail"],
        "user_secrets": ["insert_company_name"]
      }
      Object.keys(renames).forEach(function(valI) {
        const key = valI
        renames[key].forEach(function(valJ) {
          const elNm = "[name='" + key + "_" + valJ + "']"
          const cnt = $(elNm).length - 1
          for (let i = 0; i < cnt; i++) {
            $(elNm + ":eq(0)").attr("name", key + "[" + ((i + 1) * -1) + "][" + valJ.replace("insert_", "") + "]")
          }
        })
      })
    })
    //ロード時に必須が入力されているかチェック
    variableBox_initialRequireCheck(true)
  }


  /* メッセージ画面：添付ファイル系の挙動
  -------------------------------*/
  if ($('.js-MessageSendBlock').length) {

    //メッセージ最下部に移動
    setTimeout(function() {
      window.scroll(0, $(document).height());
    }, 0);

    //添付ファイルを選択したときに、テキストエリア内に選択ファイルを表示する
    const input = document.getElementById('js-fileAttachField');
    let fileObjectList = [];
    input.addEventListener('change', () => {
      Array.from(input.files).forEach(fileObject => {
        fileObjectList.push(fileObject);
      });
      const dataTransfer = new DataTransfer();
      fileObjectList.forEach(fileObject => {
        dataTransfer.items.add(fileObject);
      });
      input.files = dataTransfer.files;
      $('.textarea-wrap .attachments').empty();
      fileObjectList.forEach((file) => {
        document.querySelector('.textarea-wrap .attachments').insertAdjacentHTML(
          'beforeend',
          `<li class="file"><p>${file.name}</p><span class="delete-button" data-key="${file.lastModified}"></span></li>`
        );
      });
      document.querySelectorAll('.textarea-wrap .attachments .file .delete-button').forEach(node => {
        node.addEventListener('click', (e) => {
          e.target.parentNode.remove();
          fileObjectList = fileObjectList.filter(item => {
            return !(item.lastModified == e.target.dataset.key);
          });
          const dataTransfer = new DataTransfer();
          fileObjectList.forEach(fileObject => {
            dataTransfer.items.add(fileObject);
          });
          input.files = dataTransfer.files;
        });
      });
    });

    //添付ファイル付きのメッセージバリデーション
    $(".js-MessageSendForm").submit(function(e) {
      //一度だけ送信
      if ($(".js-MessageSendForm textarea").val() !== "") {
        $(".js-MessageSendForm button[type='submit']").css("pointer-events", "none");
      }
      //バリデーション
      $('#message-errors').empty();
      const fileInput = $(this).find('input[type="file"]');
      const textArea = $(this).find('textarea[name="content"]');
      const sizeList = Array.from(fileInput.prop('files')).map(item => item.size);
      let totalSize = 0;
      if (sizeList.length) {
        totalSize = sizeList.reduce((a, b) => a + b);
      }
      if (totalSize >= 5242880) {
        e.preventDefault();
        $('<p class="fz85 c-red fw-b mt5">添付ファイルのサイズは5MB以下にしてください。</p>').appendTo('#message-errors');
      }
      if (sizeList.length > 5) {
        e.preventDefault();
        $('<p class="fz85 c-red fw-b mt5">添付ファイルは一度に5ファイルまで送信可能です。</p>').appendTo('#message-errors');
      }
      if (!(textArea.val().length)) {
        e.preventDefault();
        $('<p class="fz85 c-red fw-b mt5">テキストを入力してください。</p>').appendTo('#message-errors');
      }
    })

  }

  /*	メモ一覧：メモの保存
  -------------------------------*/
  if (!!document.querySelectorAll('.js-personMemoForm')) {
    // メモの送信:データ
    const postMemo = async (form) => {
      const formData = new FormData(form);
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      return await fetch(form.action, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': token,
        },
        body: formData,
      });
    };

    // メモの削除:データ
    const deleteMemo = async (form) => {
      const formData = new FormData(form);
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      return await fetch(form.action, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': token,
        },
        body: formData,
      });
    };

    // 送信ハンドラ:メモ編集フォーム
    const submitMemoHandler = async (event) => {
      const button = event.currentTarget.save;
      const wait = button.querySelector('[data-button-state="wait"]');
      const load = button.querySelector('[data-button-state="load"]');

      event.preventDefault();
      wait.style.setProperty('display', 'none');
      load.style.setProperty('display', 'block');

      const res = await postMemo(event.currentTarget);
      wait.style.setProperty('display', 'block');
      load.style.setProperty('display', 'none');
      if (res.ok) {
        popupSuccess('保存しました');
      } else {
        popupError('保存に失敗しました もう一度お試しください');
      }
    };

    // 送信ハンドラ:メモ削除フォーム
    const deleteMemoHandler = async (event) => {
      event.preventDefault();
      const res = await deleteMemo(event.currentTarget);
      const responseJSON = await res.json();
      deleteMemoBox(responseJSON);
      if (res.ok) {
        popupSuccess('削除しました');
      } else {
        popupError('削除に失敗しました もう一度お試しください');
      }
    }

    // DOM操作:メモボックスの追加
    const addMemoBox = (responseJson) => {
      const copySource = document.getElementById(`js-memoCopySource-${responseJson.type}`);

      responseJson.create_list.forEach((memo) => {
        const clone = copySource.cloneNode(true);
        const cloneForm = clone.querySelector('.js-personMemoForm');
        const cloneAction = cloneForm.action;
        const cloneDeleteForm = clone.querySelector('.js-personDeleteMemoForm');
        const cloneDeleteFormAction = cloneDeleteForm.action;

        clone.querySelector('.js-personMemo-question').textContent = memo.question;

        cloneForm.action = `${cloneAction}?${new URLSearchParams({ memo_id: memo.memo_id }).toString()}`;
        cloneForm.addEventListener('submit', submitMemoHandler);

        cloneDeleteForm.action = `${cloneDeleteFormAction}?${new URLSearchParams({ memo_id: memo.memo_id }).toString()}`;
        cloneDeleteForm.addEventListener('submit', deleteMemoHandler);

        cloneForm.querySelector('.js-autoFlexTextarea').addEventListener('input', (e) => {
          e.currentTarget.style.setProperty('height', 'auto');
          e.currentTarget.style.setProperty('height', `${e.currentTarget.scrollHeight + 3}px`);
        });

        clone.id = `js-memoList-${responseJson.type}-item-${memo.memo_id}`;
        clone.removeAttribute('style');

        document.getElementById(`js-memoList-${responseJson.type}`).insertAdjacentElement('beforeend', clone);
      });
    };

    // DOM操作:メモボックスの削除
    const deleteMemoBox = (responseJson) => {
      responseJson.delete_list.forEach((memo) => {
        if (document.getElementById(`form-add${responseJson.type}`)) {
          const checkbox = document.getElementById(`form-add${responseJson.type}`).querySelector(`input[type="checkbox"][name$="${memo.question_id}"]`);
          checkbox.checked = false;
        }
        $(document.getElementById(`js-memoList-${responseJson.type}-item-${memo.memo_id}`)).fadeOut(200).queue(function() {
          this.remove();
        });
      });
    };

    document.querySelectorAll('.js-personMemoForm').forEach((form) => {
      form.addEventListener('submit', submitMemoHandler);
    });

    /*	メモ一覧：メモ追加モーダル
    -------------------------------*/
    if (!!document.querySelectorAll('.js-personAddQuestionForm')) {
      const selectQuestion = async (form) => {
        const formData = new FormData(form);
        return await fetch(form.action, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': form.authenticity_token.value,
          },
          body: formData,
        });
      };

      document.querySelectorAll('.js-personAddQuestionForm').forEach((form) => {
        form.addEventListener('submit', async (event) => {
          event.preventDefault();
          const res = await selectQuestion(event.currentTarget);
          if (res.ok) {
            const responseJSON = await res.json();
            addMemoBox(responseJSON);
            deleteMemoBox(responseJSON);
            popupSuccess('質問を追加しました');
            $('.js-modalWrap').removeClass('is-active').fadeOut(200);
          } else {
            popupError('質問の追加に失敗しました もう一度お試しください');
            $('.js-modalWrap').removeClass('is-active').fadeOut(200);
          }
        });
      });
    }

    /*	メモ一覧：メモの削除
    -------------------------------*/
    if (!!document.querySelectorAll('.js-personMemoDeleteForm')) {

      document.querySelectorAll('.js-personDeleteMemoForm').forEach((form) => {
        form.addEventListener('submit', deleteMemoHandler);
      });
    }
  }

  /* フォーム：チェックボックスのチェックを一括で解除
  -------------------------------*/
  if (!!document.querySelectorAll('.js-resetCheckbox')) {
    const resetCheckboxButtons = document.querySelectorAll('.js-resetCheckbox');
    resetCheckboxButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        resetChekboxList(button.dataset.resetCheckbox);
      });
    });

    const resetChekboxList = (key) => {
      const target = document.querySelector(`[data-reset-checkbox-target="${key}"]`);
      if (!!target) {
        target.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        return null;
      }
    };
  }

  /* フォーム：最低n個以上の選択を求めるチェックボックス
  -------------------------------*/
  if (!!document.querySelectorAll('.js-checkboxRequiredGroup').length) {
    // .js-checkboxRequiredGroupというクラス名がついた要素の子要素であるチェックボックスが対象
    const checkboxRequiredGroup = document.querySelectorAll('.js-checkboxRequiredGroup');

    for (const g of checkboxRequiredGroup) {
      const checkboxes = g.querySelectorAll('input[type="checkbox"]');
      if (checkboxes.length <= 1) throw new Error('チェックボックスは2つ以上のみ有効です。');
      if (g.dataset.requiredMin === undefined) throw new Error('`data-required-min`属性がありません。');

      const requiredMin = parseInt(g.dataset.requiredMin, 10);
      if (Number.isNaN(requiredMin)) throw new TypeError('`data-required-min`には数値を指定してください。');
      if (requiredMin <= 0) throw new Error('`data-required-min`属性には1以上を指定してください。');

      if (Array.from(checkboxes).filter((c) => c.checked).length >= requiredMin) {
        for (const c of checkboxes) c.required = false;
      } else {
        for (const c of checkboxes) c.required = true;
      }

      for (const checkbox of checkboxes) {
        checkbox.addEventListener('change', () => {
          if (Array.from(checkboxes).filter((c) => c.checked).length >= requiredMin) {
            for (const c of checkboxes) c.required = false;
          } else {
            for (const c of checkboxes) c.required = true;
          }
        });
      }
    }
  }

  /* フォーム：対象のすべてのチェックボックスのチェックを外すボタン
  -------------------------------*/
  if (!!document.querySelectorAll('.js-uncheckAll-button').length) {
    const uncheckAllButtonList = document.querySelectorAll('.js-uncheckAll-button');

    for (const button of uncheckAllButtonList) {
      button.addEventListener('click', (e) => {
        // .js-uncheckAll-checkbox[data-uncheckall-name="{name}"]の子要素のチェックボックスが対象
        const checkboxGroup = document.querySelectorAll(
          `.js-uncheckAll-checkbox[data-uncheckall-name="${e.currentTarget.dataset.uncheckallName}"]`
        );
        for (const cg of checkboxGroup) {
          const checkboxList = cg.querySelectorAll('input[type="checkbox"]');
          for (const checkbox of checkboxList) {
            checkbox.checked = false;
            checkbox.dispatchEvent(new Event('change'));
          }
        }
      });
    }
  }

  /* フォーム：disabledの切り替えによって要素のクラスを切り替える。
  /* data-button-enable属性  : disabled=falseのときに追加されるクラス
  /* data-button-disable属性 : disabled=trueのときに追加されるクラス
  -------------------------------*/
  if (!!document.querySelectorAll('[data-button-enable][data-button-disable]').length) {
    const toggleButtonState = (target, isDisabled) => {
      const enableClassList = target.dataset.buttonEnable.split(' ');
      const disableClassList = target.dataset.buttonDisable.split(' ');

      if (isDisabled) {
        target.style.cursor = 'not-allowed';
        target.classList.add(...disableClassList);
        target.classList.remove(...enableClassList);
      } else {
        target.style.cursor = null;
        target.classList.add(...enableClassList);
        target.classList.remove(...disableClassList);
      }
    };

    const obs = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          toggleButtonState(mutation.target, mutation.target.disabled);
        }
      }
    });

    const buttonList = document.querySelectorAll('[data-button-enable][data-button-disable]');
    for (const button of buttonList) {
      toggleButtonState(button, button.disabled);
      obs.observe(button, { attributes: true });
    }
  }


  /* フォーム: 入力中に動的に<fieldset>をバリデーションする
  /* <fieldset>が`data-validate-form-name`という属性で紐づけられている.js-dynamicValidateFieldset-buttonをもつ場合は、
  /* フォーム全体のバリデーションの可否によって.js-dynamicValidateFieldset-buttonのdisabled属性を切り替える
  -------------------------------*/
  if (!!document.querySelectorAll('.js-dynamicValidateFieldset')) {
    const obs = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        const fieldset = mutation.target.closest('.js-dynamicValidateFieldset');
        toggleValidateButton(fieldset);

        if (mutation.type === 'childList') {
        } else if (mutation.type === 'attributes') {
          if (mutation.attributeName === 'hidden') {
            mutation.target.disabled = mutation.target.hidden;
          } else if (mutation.attributeName === 'disabled') {
          }
        }
      }
    });

    const toggleValidateButton = (fieldset) => {
      const buttons = document.querySelectorAll(
        `.js-dynamicValidateFieldset-button[data-validate-form-name="${fieldset.dataset.validateFormName}"]`
      );
      const { isValid } = validateFormOrFieldset(fieldset);
      if (!!buttons.length) {
        for (const button of buttons) {
          button.disabled = !isValid;
        }
      }
      fieldset.dispatchEvent(new CustomEvent(isValid ? 'valid' : 'invalid'))
    }

    for (const fieldset of document.querySelectorAll('.js-dynamicValidateFieldset')) {
      toggleValidateButton(fieldset);
      obs.observe(fieldset, { attributes: true, childList: true, subtree: true });

      let timeoutId = 0;

      fieldset.addEventListener('input', (e) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          validateField(e.target, true);
          toggleValidateButton(e.target.closest('.js-dynamicValidateFieldset'));
        }, 200);
      });
    }
  }

  if (!!document.querySelectorAll('.js-ErrorTxt')) {
    for (const err of document.querySelectorAll('.js-ErrorTxt')) {
      err.hidden = true;
    }
  }

  /**
   * 画像ファイルを切り抜く機能があるフィールド
   * */
  for (const cropField of document.getElementsByClassName('js-imageCropField')) {
    let _croppie;
    const input = cropField.getElementsByClassName('js-imageCropField-input')[0];
    const inputChanged = cropField.getElementsByClassName('js-imageCropField-inputChanged')[0];
    const currentImg = cropField.getElementsByClassName('js-imageCropField-currentImg')[0];
    const deleteButton = cropField.getElementsByClassName('js-imageCropField-deleteButton')[0];
    const modal = document.querySelector(`.js-imageCropField-modal[data-field-name="${cropField.dataset.fieldName}"]`);
    const modalView = modal.getElementsByClassName('js-imageCropField-modalView')[0];
    const execButton = modal.getElementsByClassName('js-imageCropField-execButton')[0];
    const croppieMountTarget = modal.getElementsByClassName('js-imageCropField-mount')[0];

    // init
    input.value = null;
    inputChanged.value = false;

    const magnification = parseInt(cropField.dataset.magnification, 10);
    let [vW, vH] = cropField.dataset.viewport.split('x').map((n) => parseInt(n, 10));
    let bW = vW + 50;
    let bH = vH + 50;

    const attached = input.dataset.attached === 'true';
    const setSelected = (bool) => {
      if (bool) {
        cropField.classList.add('selected');
      } else {
        cropField.classList.remove('selected');
      }
    };
    const setChanged = (bool) => inputChanged.value = bool;
    const clearInput = () => input.value = null;

    // ファイルを選択
    input.addEventListener('change', (e) => {
      modal.classList.add('is-active');
      // モーダルからはみ出す場合はサイズを調整する
      if ((modalView.getBoundingClientRect().width - 40) - bW < 0) {
        const adjustRatio = (modalView.getBoundingClientRect().width - 40) / bW;
        vW = vW * adjustRatio;
        vH = vH * adjustRatio;
        bW = bW * adjustRatio;
        bH = bH * adjustRatio;
      }
      _croppie = new Croppie(croppieMountTarget, {
        viewport: { width: vW, height: vH },
        boundary: { width: bW, height: bH },
      });
      _croppie.bind({ url: URL.createObjectURL(input.files[0]) });
    });

    // クロップをキャンセル
    modal.addEventListener('click', (e) => {
      const cancel = e.target.closest('.js-imageCropField-cancelButton');
      if (cancel) {
        setTimeout(() => _croppie.destroy(), 200);
        clearInput();
        setSelected(false);
        if (attached) {
          setChanged(true);
        } else {
          setChanged(false);
        }
      };
    });

    // クロップを実行
    execButton.addEventListener('click', () => {
      _croppie.result({
        type: 'blob',
        size: { width: vW * magnification, height: vH * magnification },
        format: 'webp',
      }).then((blob) => {
        // 切り取り結果のblobをfile inputに設定し直す
        const croppedImage = new File([blob], `${Date.now()}.webp`, { type: blob.type });
        const newFileList = new DataTransfer();
        newFileList.items.add(croppedImage);
        input.files = newFileList.files;

        currentImg.src = URL.createObjectURL(croppedImage);
        modal.classList.remove('is-active');
        setTimeout(() => _croppie.destroy(), 200);
        setSelected(true);
        setChanged(true);
      });
    });

    // 削除
    deleteButton.addEventListener('click', () => {
      clearInput();
      setSelected(false);
      setChanged(attached);
    });
  }

  /**
   * labelタグの代わりにbuttonを使用する場合
  */
  for (const labelButton of document.getElementsByClassName('js-labelButton')) {
    labelButton.addEventListener('click', () => {
      const input = document.getElementById(labelButton.dataset.for);
      if (!input) return;
      input.click();
    });
  }
});

// フォーム：<fieldset>のバリデーション
const validateFormOrFieldset = (formOrFieldset) => {
  const result = {
    get isValid() {
      return Object.keys(this.errorMessages).length === 0
    },
    errorMessages: {},
  }

  for (const elem of formOrFieldset.elements) {
    if (elem.validationMessage !== '') {
      if (elem.type === 'checkbox') {
        result.errorMessages[elem.name] = 'チェックをつけてください。';
      } else {
        result.errorMessages[elem.name] = elem.validationMessage;
      }
    }
  }

  return result;
};

const validateField = (field, printError = false) => {
  const errTxtList = (name) => document.querySelectorAll(`.js-ErrorTxt[data-field-name="${name}"]`);

  const result = {
    isValid: field.validationMessage === '',
    name: field.name,
    errorMessage: field.validationMessage,
  };

  if (printError && field.validationMessage !== '') {
    for (const errTxt of errTxtList(field.name)) {
      if (field.type === 'checkbox') {
        errTxt.querySelector('.js-ErrorTxt-content').textContent = 'チェックをつけてください。';
      } else {
        errTxt.querySelector('.js-ErrorTxt-content').textContent = field.validationMessage;
      }
      errTxt.hidden = false;
    }
  } else {
    for (const errTxt of errTxtList(field.name)) {
      errTxt.hidden = true;
    }
  }

  return result;
};
