import { CanvasUtil } from './canvas-util.js';
import { calcurateRadian, calculateVertexCoordinate } from './calculate-helper.js';

export class ChartC extends CanvasUtil {
  static CANVAS_RATIO = [4, 3];
  static RECTANGLE_RATIO = [1, 1];
  static COL_AND_ROW = [10, 8];
  static SCALE_LABEL = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
  static ITEM_LABEL = ['理知的', '責任感', '従順度', '受容性', '独創性', '主体性', '適応性', '決断力'];
  #baseCircleRadius;
  #baseCircleCoordinate;
  #baseRectangle;
  #renderQue = [];

  constructor(canvas, canvasWrapper) {
    super(canvas, canvasWrapper);
  }

  addChart({color = CanvasUtil.C_BLACK, score = [...Array(ChartC.ITEM_LABEL.length)].map(() => 50)} = {}) {
    this.#renderQue.push({ color, score });
    return this;
  }

  render() {
    this.#calculateCanvas();
    this.#renderRuledLine();
    this.#renderScaleLabel();
    this.#renderItemLabel();
    this.#renderQue.forEach((chart, index) => {
      this.#renderData(chart, index);
    });
  }

  #renderRuledLine() {
    for (let colIndex = 0; colIndex <= ChartC.COL_AND_ROW[0]; colIndex++) {
      const startCoordinate = [
        this.#baseRectangle.tl[0] + (this.#baseRectangle.xInterval * colIndex),
        this.#baseRectangle.tl[1]
      ];
      const endCoordinate = [
        this.#baseRectangle.bl[0] + (this.#baseRectangle.xInterval * colIndex),
        this.#baseRectangle.bl[1]
      ];
      this.drawStraightLineShape([startCoordinate, endCoordinate], { strokeStyle: CanvasUtil.C_GRAY, lineWidth: 2, setLineDash: [4, 4], lineCap: 'round' }, false, true, false);
    }
  }

  #renderScaleLabel() {
    ChartC.SCALE_LABEL.forEach((label, index) => {
      const coordinate = [
        this.#baseRectangle.bl[0] + (this.#baseRectangle.xInterval * index),
        this.#baseRectangle.bl[1] + (this.#baseRectangle.bl[1] * 0.01)
      ];
      this.drawText(coordinate, label, { font: `bold 9px ${CanvasUtil.DEFAULT_FONT}`, textAlign: 'center', textBaseline: 'top' });
    });
  }

  #renderItemLabel() {
    ChartC.ITEM_LABEL.forEach((label, index) => {
      const coordinate = [
        this.#baseRectangle.tl[0] - (this.#baseRectangle.xInterval * 0.5),
        this.#baseRectangle.tl[1] + (this.#baseRectangle.yInterval * index) + this.#baseRectangle.yInterval * 0.5
      ];
      this.drawText(coordinate, label, { font: `bold 13px ${CanvasUtil.DEFAULT_FONT}`, textAlign: 'right', textBaseline: 'middle' });
    });
  }

  #renderData({ color, score }, chartQueIndex) {
    const styles = { fillStyle: color };
    const barHeight = this.#baseRectangle.yInterval * 0.3;
    const barBaseHeight = barHeight * this.#renderQue.length;
    const calculated = this.#baseRectangle.yInterval / 2 - barBaseHeight / 2 + barHeight * chartQueIndex;

    score.forEach((score, index) => {
      const coordnate = [
        this.#baseRectangle.tl[0], 
        this.#baseRectangle.tl[1] + this.#baseRectangle.yInterval * index + calculated
      ];
      this.drawRectangle(coordnate, this.#baseRectangle.width * (score / 100), barHeight, styles, true, false);
    });
  }

  #calculateCanvas() {
    super.calculateCanvas(ChartC.CANVAS_RATIO);
    this.#baseCircleRadius = this.width * 0.46;
    this.#baseCircleCoordinate = [
      this.canvasCenterCoordinate[0] + this.canvasCenterCoordinate[0] * 0.18,
      this.canvasCenterCoordinate[1]
    ];
    this.#calculateRectangleCoordinate();
  }

  #calculateRectangleCoordinate() {
    const angle = Math.atan((ChartC.RECTANGLE_RATIO[1]) / ChartC.RECTANGLE_RATIO[0])  * 180 / Math.PI;
    this.#baseRectangle = {
      tl: calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(180 + angle), 100),
      tr: calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(360 - angle), 100),
      br: calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(angle), 100),
      bl: calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(180 - angle), 100),
      get width() {
        return this.tr[0] - this.tl[0];
      },
      get height() {
        return this.bl[1] - this.tl[1];
      },
      get xInterval() {
        return this.width / ChartC.COL_AND_ROW[0];
      },
      get yInterval() {
        return this.height / ChartC.COL_AND_ROW[1];
      }
    };
  }
}
