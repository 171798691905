export const popupSuccess = (message) => {
  const element = createPopAlart({
    message
  });
  document.body.insertAdjacentElement('beforeend', element);
  setTimeout(() => element.remove(), 2000);
};

export const popupError = (message) => {
  const err = true;
  const element = createPopAlart({
    message,
    err
  });
  document.body.insertAdjacentElement('beforeend', element);
  setTimeout(() => element.remove(), 2000);
};

const createPopAlart = ({
  message,
  err = false
}) => {
  const box = document.createElement('div');
  box.classList.add(...(err ? ['u-box-popAlart', '-err'] : ['u-box-popAlart']));
  box.insertAdjacentHTML('beforeend', `
    <div class="inner">
      <p><i class="icon-info pr5 fz125 va-m"></i>${message}</p>
    </div>
  `);
  return box;
};
