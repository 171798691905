<template>
  <div class="sidebar">
    <div>
      <a href="javascript:void(0)" class="create-original hov" @click='createEditableTemplate'>
        <p>オリジナルを新規作成</p>
        <div class="circle-with-plus-icon"></div>
      </a>
      <ul class="template-list -editable" :ref='"editableTemplateList"'>
        <li
          :class='{ nowInput: currentInputIndex === index, active: currentPreview.id == item.id && currentPreview.editable == item.editable }'
          :ref='"editableTemplateListItem"'
          v-for='(item, index) in editableTemplateList'
          :key='item.id'
          @click='setPreview(item.id, item.editable)'
        >
          <div class="edit-title" @click="toggleInput(index)">
            <span class="title" :class='{ active: currentInputIndex === index }'>
              <input type="text" class="text"
                :value='item.title'
                :ref='"titleInput"'
                @blur='titleInputFunctionWrapper(index, item.id)'
                @keydown.enter='blurInput(index)'
                v-show='currentInputIndex === index'
              >
              <p class="text" v-show='currentInputIndex !== index'>{{item.title}}</p>
            </span>
            <i class="icon-status-pen"></i>
          </div>
          <div class="delete-editable-template" @click='deleteEditableTemplate(item.id)'>
            <i class="icon-delete"></i>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt15 pt15 bt-black50">
      <ul class="template-list -ciy">
        <li
          :class='{ active: currentPreview.id == item.id && currentPreview.editable == item.editable }'
          @click='setPreview(item.id, item.editable)'
          v-for='(item, i) in ciyTemplateList'
          :key='i'
        >
          {{item.title}}
        </li>
      </ul>
    </div>
    <div class="blocker" v-show="blockerShow"></div>
  </div>
</template>

<script>
export default {
  props: {
    propEditableTemplateList: Array,
    propCiyTemplateList: Array,
    propCurrentPreview: Object,
  },

  data() {
    return {
      editableTemplateList: [],
      ciyTemplateList: [],
      currentInputIndex: null,
      currentPreview: {},
      blockerShow: false,
    }
  },

  created() {
    this.$set(this.$data, 'editableTemplateList', this.propEditableTemplateList);
    this.$set(this.$data, 'ciyTemplateList', this.propCiyTemplateList);
    this.setPreview(1, false);
  },

  watch: {
    propEditableTemplateList(newValue, _) {
      this.$set(this.$data, 'editableTemplateList', newValue);
    },
    propCurrentPreview(newValue, _) {
      this.$set(this.$data, 'currentPreview', newValue);
    }
  },

  methods: {
    toggleInput(index) {
      this.$set(this.$data, 'currentInputIndex', index);
      this.$nextTick(() => {
        this.$refs.titleInput[index].focus();
      });
    },

    blurInput(index) {
      this.$refs.titleInput[index].blur();
    },

    titleInputFunctionWrapper(index, id) {
      this.$set(this.$data, 'currentInputIndex', null);
      this.updateEditableTemplate(index, id);
    },

    setPreview(selectId, editableBool) {
      const templateList = editableBool ? this.editableTemplateList : this.ciyTemplateList;
      const { id, subject, content, editable } = templateList.find(item => {
        return item.id == selectId;
      });
      const currentPreview = {
        id: id,
        subject: subject,
        content: content,
        editable: editable,
      };
      this.$emit('setPreview', currentPreview);
    },

    async createEditableTemplate() {
      this.blockerShow = true;
      const res = await fetch('/company/ajax/applicant/create_editable_template', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
      });
      const updatedEditableTemplateList = await res.json();
      this.$emit('updateEditableTemplateList', updatedEditableTemplateList);
      const { id, subject, content, editable } = updatedEditableTemplateList.slice(-1)[0];
      this.$emit('setPreview', {
        id: id,
        subject: subject,
        content: content,
        editable: editable,
      });
      this.blockerShow = false;
    },

    async updateEditableTemplate(index, id) {
      this.blockerShow = true;
      const data = { id: id, title: this.$refs.titleInput[index].value }
      const res = await fetch('/company/ajax/applicant/update_editable_template', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const { list, current } = await res.json();
      this.$emit('updateEditableTemplateList', list);
      this.blockerShow = false;
    },

    async deleteEditableTemplate(targetId) {
      this.blockerShow = true;
      const data = { id: targetId }
      const res = await fetch('/company/ajax/applicant/delete_editable_template', {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const updatedEditableTemplateList = await res.json();
      this.$emit('updateEditableTemplateList', updatedEditableTemplateList);
      const { id, subject, content, editable } = updatedEditableTemplateList.length
                                                  ? updatedEditableTemplateList[0]
                                                  : this.ciyTemplateList[0];
      this.$emit('setPreview', {
        id: id,
        subject: subject,
        content: content,
        editable: editable,
      });
      this.blockerShow = false;
    },
  }
}
</script>
