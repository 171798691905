<template>
  <div>
    <div class="pt40">
      <a class="modal-tgl d-ib lh100 ff-logo fz120 bgc-lemonyellow bdrs-max ptb10 plr30 hov" href="javascript:void(0)" @click="newVoice"><i class="icon-add pr10"></i>先輩社員の声を追加する</a>
    </div>
    <div class="cols nega pt20 gutter-10">
      <div v-for="voice in employeeVoices" :key="voice.id" class="col-6 col-sm-12 pt20">
        <article class="bd-black40 p15 h-eq">
          <div>
            <p>{{ voice.department }} 入社{{ voice.join_year }}</p>
            <h5 class="fw-b fz115 lh140 pt5">{{ voice.name }}</h5>
            <div class="d-f pt15">
              <a class="modal-tgl bgc-orange c-white lh140 d-ib ptb5 plr15 bdrs4 mr5 hov" href="javascript:void(0)" @click="editVoice(voice.id)">編集</a>
              <a class="modal-tgl bgc-black80 c-white lh140 d-ib ptb5 plr15 bdrs4 hov" href="javascript:void(0)" @click="confirmDelete(voice.id)">削除</a>
            </div>
          </div>
        </article>
      </div>
    </div>

    <div class="modal-wrap" data-modal="voice-edit" style="display: none;">
      <div class="modal-wrap-view">
        <!-- .modal -->
        <div class="modal">
          <p class="ptb20 fz140 fw-b lh140 ta-c">先輩社員の声を追加</p>
          <employee-voice-form
            :voice="currentVoice"
            :handleChange="handleChange"
            @submit="submit"
            @handleData="handleData"
          />
          <div class="close-btn modal-close">閉じる</div>
        </div>
      </div>
      <!-- .black-back -->
      <div class="black-back modal-close"></div>
    </div>

    <div class="modal-wrap" data-modal="voice-delete" style="display: none;">
      <div class="modal-wrap-view">
        <!-- .modal -->
        <div class="modal">
          <p class="ptb20 fz140 fw-b lh140 ta-c">先輩社員の声を削除</p>
          <form class="form-flame ptb30 plr20">
            <p class="ta-c">本当にこの先輩社員の声を削除しますか？<br>削除すると、記載されていた内容は失われます。</p>
            <div class="cols nega gutter-10 pt30">
              <div class="col-4 col-sm-6">
                <a href="javascript:void(0)" class="modal-close basic-btn d-b bgc-black80 ff-logo sm-fz120 c-white hov">キャンセル</a>
              </div>
              <div class="col-8 col-sm-6">
                <a href="javascript:void(0)" @click="deleteCurrentVoice" class="basic-btn d-b bgc-key ff-logo sm-fz120 c-white hov">削除する</a>
              </div>
            </div>
          </form>
          <div class="close-btn modal-close">閉じる</div>
        </div>
      </div>
      <!-- .black-back -->
      <div class="black-back modal-close"></div>
    </div>
  </div>
</template>

<script>
import { ENDPOINT } from '../utils/endpoint.js'
import { METHOD } from '../utils/method.js'
import { api, apiFormData } from '../packs/company/ajax.js'
import EmployeeVoiceForm from './Form/EmployeeVoiceForm.vue'

export default {
  components: {
    EmployeeVoiceForm,
  },
  props: {
    propEmployeeVoices: {
      type: Array,
    },
    recruitId: Number
  },
  data() {
    return {
      employeeVoices: [],
      currentVoice: {},
      currentIndex: null,
      deletedId: null,
    }
  },
  computed: {
    formData() {
      const {name, department, join_year, message} = this.currentVoice;
      return {
        name, department, join_year, message
      }
    }
  },
  created() {
    this.employeeVoices = Array.from(this.propEmployeeVoices)
  },
  methods: {
    newVoice() {
      this.initializeForm()
      $('.modal-wrap[data-modal="voice-edit"]').fadeIn(200);
    },
    editVoice(id) {
      this.initializeForm()
      const index = this.employeeVoices.findIndex(el => { return el.id === id })
      if (index === -1) { return }
      this.currentVoice = Object.assign({}, this.employeeVoices[index])
      this.currentIndex = index
      $('.modal-wrap[data-modal="voice-edit"]').fadeIn(200);
    },
    handleChange(e) {
      this.$set(this.currentVoice, e.target.name, e.target.value)
    },
    handleData(name, value) {
      this.$set(this.$data, name, value)
    },
    initializeForm() {
      $(".error-txt").each(function(){
        $(this).fadeOut(20);
      })
      this.currentIndex = -1;
      this.currentVoice = {
        name: "",
        department: "",
        join_year: "",
        message: "",
      }
    },
    submit(e) {
      if (this.currentIndex >= 0) {
        // 編集
        apiFormData(METHOD.PUT, ENDPOINT.COMPANY.EMPLOYEE_VOICE_PATH(this.currentVoice.id), this.formData).done(res => {
          this.employeeVoices.splice(this.currentIndex, 1, res)
        }).always(res => {
          $('.modal-wrap[data-modal="voice-edit"]').fadeOut(200);
          this.currentIndex = null;
        })
      } else {
        // 新規作成
        this.formData["recruit_id"] = this.recruitId;
        apiFormData(METHOD.POST, ENDPOINT.COMPANY.EMPLOYEE_VOICES_PATH, this.formData).done(res => {
          this.employeeVoices.push(res)
        }).always(res => {
          this.currentIndex = null;
          $('.modal-wrap[data-modal="voice-edit"]').fadeOut(200);
        })
      }
    },
    confirmDelete(id) {
      this.deletedId = id;
      $('.modal-wrap[data-modal="voice-delete"]').fadeIn(200);
    },
    deleteCurrentVoice(e) {
      // 削除
      e.preventDefault();
      const id = this.deletedId;
      const index = this.employeeVoices.findIndex(el => { return el.id === id })
      if (index === -1) {
        $('.modal-wrap[data-modal="voice-delete"]').fadeOut(200);
        return
      }
      api(METHOD.DELETE, ENDPOINT.COMPANY.EMPLOYEE_VOICE_PATH(this.deletedId), {}).done(res => {
        this.employeeVoices.splice(index, 1)
        $('.modal-wrap[data-modal="voice-delete"]').fadeOut(200);
      })
    }
  },
}
</script>
