<template>
  <div class="mt30">
    <dl v-for="field in fieldNameList" :key="field">
      <dt class="fz85 fw-b" style="width: 145px;"><span class="required-tag -option mr5">任意</span>{{field}}</dt>
      <dd>
        <input type="hidden" name="addition_field[][key]" :value="field">
        <textarea name="addition_field[][value]" rows="6">{{otherAnswers ? otherAnswers[`${field}`] : ''}}</textarea>
      </dd>
    </dl>
    <div id="manual-addition-field" class="modal-wrap" data-modal="manual-addition-field" style="display: none;">
      <div class="modal-wrap-view">
        <!-- .modal -->
        <div class="modal p20">
          <p class="fz140 fw-b lh140 ta-c">応募者への入力項目編集</p>
          <p class="fw-b ta-c mt20">※この変更は、すべての応募者に適用されます。</p>
          <div class="plr40">
            <div class="field-list mt30">
              <div class="field-list-item" v-for="(fieldName, index) in fieldNameList" :key="`${fieldName}_${index}`">
                <input type="text" name="field_name" placeholder="項目名を入力" :value="fieldName" @input="setInput(index, $event)">
                <div class="delete" @click="deleteField(index)"></div>
                <div class="up" @click="moveField(index, true)"></div>
                <div class="down" @click="moveField(index, false)"></div>
              </div>
              <a href="javascript:void(0)" class="add d-f ai-c jc-c c-key fw-b hov ptb20" @click="addField"><span class="mr5"></span>入力項目を追加</a>
              <div class="ta-c">
                <a href="javascript:void(0)" @click="submit" class="basic-btn d-ib sm-d-b bgc-key ff-logo fz120 sm-fz140 c-white hov">更新する</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .black-back -->
      <div class="black-back modal-close"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propOtherQuestions: Array,
    propOtherAnswers: Object,
    propParams: Array,
  },
  data() {
    return {
      fieldNameList: [],
      otherAnswers: null,
    }
  },
  created() {
    this.fieldNameList = this.propOtherQuestions || [];
    this.otherAnswers = this.propOtherAnswers || {};
    if (this.propParams) {
      this.propParams.forEach(item => {
        this.$set(this.otherAnswers, item.key, item.value);
      });
    }
  },
  methods: {
    addField() {
      this.fieldNameList.push("");
    },
    deleteField(index) {
      this.fieldNameList.splice(index, 1);
    },
    moveField(index, direction) {
      if (direction && index === 0) {
        return;
      }
      if (!(direction) && index === this.fieldNameList.length - 1) {
        return;
      }
      const clone = [...this.fieldNameList];
      const target = direction ? index - 1 : index + 1;
      [clone[target], clone[index]] = [this.fieldNameList[index], this.fieldNameList[target]];
      this.fieldNameList = clone;
    },
    createFormData() {
      const formData = new FormData();
      this.fieldNameList.forEach(value => {
        formData.append('field_name[]', value);
      })
      return formData;
    },
    async submit() {
      const res = await fetch('/company/ajax/applicant/set_other_questions', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: this.createFormData(),
      });
      const json = await res.json();
      this.fieldNameList = json.fields;
      document.getElementById('manual-addition-field').style.display = 'none';
    },
    setInput(index, event) {
      this.fieldNameList[index] = event.target.value;
    }
  }
}
</script>

<style>
</style>
