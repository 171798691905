<template>
  <div class="cols nega mt10">
    <div class="col-7 col-sm-12">
      <div class="add-item-block-wrap-timelapse">
        <label class="add-item-block">
          <input type="file" accept="video/mp4, video/quicktime" @change="putTimeLapse" :disabled="inputDisabled" :value="init">
          <video class="video-ratio16_9"
            :src="`/rails/active_storage/blobs/${currentTimeLapse.signed_id}/${currentTimeLapse.blob.filename}`"
            v-if="currentTimeLapse && currentTimeLapsePoster"
            controls
            muted
            :poster="`/rails/active_storage/blobs/${currentTimeLapsePoster.signed_id}/${currentTimeLapsePoster.blob.filename}`"
            preload="none"
          >
          </video>
        </label>
        <div class="loading-modal" v-if="timeLapseUploading">
          <div class="inner">
            <div class="ta-c"><img src="/img/mypage/loader.png" width="32" height="32" alt="アップロード中"></div>
            <p class="c-white ta-c pt5 fw-b fz130 mt10">{{timeLapseProgressText}}</p>
            <div class="progress-bar"><div class="bar" :style="{ width: `${timeLapseProgress}%` }"></div></div>
          </div>
          {{progress}}
        </div>
        <div class="my-delete-btn icon-add hov" @click="deleteTimeLapse" v-if="currentTimeLapse"></div>
      </div>
      <p class="fz80 lh140 mt10">※最大100MB、MP4、MOVファイルのみ。<a href="https://ciy-biz.com/supports/3888/#chapter-6" target="_blank" class="under-line">ファイル圧縮の方法はこちら ></a><br>
      ※アップロードした動画は、自動で圧縮されます。</p>
    </div>
  </div>
</template>

<script>
import cableData from '../channels/cable_data';

export default {
  props: {
    propTimeLapse: Array,
    propTimeLapsePoster: Array,
    recruitId: Number,
  },
  data() {
    return {
      init: null,
      src: null,
      currentTimeLapse: null,
      currentTimeLapsePoster: null,
      timeLapseUploading: false,
      timeLapseProgress: 0,
      timeLapseProgressText: 'アップロード中',
      cableData: cableData,
      inputDisabled: false,
    }
  },
  created() {
    this.currentTimeLapse = this.propTimeLapse[0];
    this.currentTimeLapsePoster = this.propTimeLapsePoster[0];
  },
  computed: {
    progress() {
      const timeLapseProgressAddConvert = this.cableData.progress?.percentage && this.cableData.progress?.percentage / 2 + 50;
      this.timeLapseProgress = timeLapseProgressAddConvert;
    },
  },
  methods: {
    createFormDate(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('timelapse', file);
      formData.append('recruitId', this.recruitId);
      return {
        body: formData,
        filesize: file.size,
      };
    },

    putTimeLapse(e) {
      const xhr = new XMLHttpRequest();
      const body = this.createFormDate(e).body;
      const fileSize = this.createFormDate(e).filesize;
      const fileSizeLimit = 1024 * 1024 * 100;
      if (fileSize > fileSizeLimit) {
        alert('ファイルサイズの上限は100MBまでです');
        return;
      }
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      xhr.open('PUT', '/company/ajax/time_lapse');
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      xhr.onloadstart = () => {
        this.timeLapseUploading = true;
        this.inputDisabled = true;
      };
      xhr.upload.onprogress = (e) => {
        this.timeLapseProgress = e.loaded / e.total * 100 / 2;
      };
      xhr.upload.onload = () => {
        this.timeLapseProgressText = '圧縮中';
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          this.currentTimeLapse = data.video;
          this.currentTimeLapsePoster = data.poster;
          this.timeLapseUploading = false;
          this.inputDisabled = false;
          this.timeLapseProgress = 0;
          this.timeLapseProgressText = 'アップロード中';
        } else {
          if (!alert('エラーが発生しました。再度お試しください')) {
            location.reload();
          }
        }
      };
      xhr.send(body);
    },

    deleteTimeLapse() {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const delete_data = {
          recruitId: this.recruitId
      };
      fetch('/company/ajax/time_lapse', {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(delete_data)
      }).then(res => {
        this.currentTimeLapse = null;
        this.currentTimeLapsePoster = null;
        this.init = null;
      })
    }
  },
}
</script>
