$(window).on('turbolinks:load', function() {
  //【共通】チャートのレスポンシブ化
  $.fn.responsiveTable = (function() {
    var $window = $(window);
    return function() {
      var $el = $(this).parent(".responsive-table");
      var $table = this;
      var onResize = function() {
        var width = $table.outerWidth();
        var height = $table.outerHeight();
        var $parent = $el.parent();
        var containerWidth = $parent.width();
        var ratio = containerWidth / width;
        if (ratio < 1) {
          $el.height(height * ratio);
          $table.css('transform', 'scale(' + ratio + ')');
        } else {
          $el.height('');
          $table.css('transform', '');
        }
      };
      $table.css('transformOrigin', '0 0');
      $window.on('resize', onResize);
      onResize();
    };
  }());
  if ($('.js-svg-responsive').length) {
    $(document).on('click', '.js-svg-responsive', function() {
      $('.responsive-table').toggle();
      $('.resultChart').each(function(index) {
        $(this).responsiveTable();
      });
    });
  }

  if ($('.js-responsiveTable-target').length) {
    $('.resultChart').each(function(index) {
      $(this).wrap('<div class="responsive-table">');
      $(this).responsiveTable();
    });
  }

  //レポート用の折れ線グラフ
  if ($('.js-linechart-target').length) {
    if ($('.resultChart-competency-company').length) {
      var target = '.resultChart-competency-company';
      var skills;
      var val;
      skills = $(target).data('chart');
      for (var k = 0; k < skills.length; k++) {
        val = (100 - skills[k]);
        if (val < 20) {
          val = 0;
        } else if (val < 40) {
          val = 1;
        } else if (val < 48) {
          val = 2;
        } else if (val < 52) {
          val = 3;
        } else if (val < 60) {
          val = 4;
        } else if (val < 80) {
          val = 5;
        } else if (val <= 100) {
          val = 6;
        }
        val = val * 32;
        $(target).find('.chart-circle-company:eq(' + k + ')').attr("cx", val);
        $(target).find('.chart-line-company:eq(' + k + ')').attr("x1", val);
        $(target).find('.chart-line-company:eq(' + (k - 1) + ')').attr("x2", val);
      };
    }
    if ($('.resultChart-competency-person').length) {
      var target = '.resultChart-competency-person';
      var skills;
      var val;
      skills = $(target).data('chart');
      for (var k = 0; k < skills.length; k++) {
        val = (100 - skills[k]);
        if (val < 20) {
          val = 0;
        } else if (val < 40) {
          val = 1;
        } else if (val < 48) {
          val = 2;
        } else if (val < 52) {
          val = 3;
        } else if (val < 60) {
          val = 4;
        } else if (val < 80) {
          val = 5;
        } else if (val <= 100) {
          val = 6;
        }
        val = val * 32;
        $(target).find('.chart-circle-person:eq(' + k + ')').attr("cx", val);
        $(target).find('.chart-line-person:eq(' + k + ')').attr("x1", val);
        $(target).find('.chart-line-person:eq(' + (k - 1) + ')').attr("x2", val);
      };
    }
  };

  //レポート用のレーダーチャート
  if ($('.js-radarchart-target').length) {
    if ($('.resultChart-strengths-company').length) {
      var target = '.resultChart-strengths-company';
      var allPentagonPoints = [];
      var skills;
      skills = $(target).data('chart');
      drawChartFoundation(target)
      //polygon要素を生成
      var chartPolygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
      var chartPolygonPoint = ""; //各点の座標
      //chartPolygonの座標を設定
      for (var k = 0; k < skills.length; k++) {
        var levelNum = parseInt(10 - (skills[k] / 10));
        var selectedPoint = allPentagonPoints[levelNum].split(" ")[k].split(",");
        chartPolygonPoint += selectedPoint[0] + "," + selectedPoint[1] + " ";
        $(target).find('.chart-edgepoint-company:eq(' + k + ')').attr("cx", selectedPoint[0]).attr("cy", selectedPoint[1]);
      };
      chartPolygon.setAttribute("points", chartPolygonPoint);
      chartPolygon.setAttribute("class", "status");
      $(target).find(".chart_Polygon").append(chartPolygon);
    };
    if ($('.resultChart-strengths-person').length) {
      //（求職者）
      var target = '.resultChart-strengths-person';
      var allPentagonPoints = [];
      var skills;
      skills = $(target).data('chart');
      drawChartFoundation(target)
      //五角形を生成
      //polygon要素を生成
      var chartPolygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
      var chartPolygonPoint = ""; //各点の座標
      //chartPolygonの座標を設定
      for (var k = 0; k < skills.length; k++) {
        var levelNum = parseInt(10 - (skills[k] / 10));
        var selectedPoint = allPentagonPoints[levelNum].split(" ")[k].split(",");
        chartPolygonPoint += selectedPoint[0] + "," + selectedPoint[1] + " ";
        $(target).find('.chart-edgepoint-person:eq(' + k + ')').attr("cx", selectedPoint[0]).attr("cy", selectedPoint[1]);
      };
      chartPolygon.setAttribute("points", chartPolygonPoint);
      chartPolygon.setAttribute("class", "status");
      $(target).find(".chart_Polygon").append(chartPolygon);
    };
    function createPentagons(dis) {
      //polygon要素を生成
      var Pentagon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
      var pentagonPoints = "";
      for (var i = 0; i < 5; i++) {
        var x = Math.cos(((72 * i) - 90) * Math.PI / 180) * dis;
        var y = Math.sin(((72 * i) - 90) * Math.PI / 180) * dis;
        pentagonPoints += (x + 150) + "," + (y + 150) + " ";
      }
      allPentagonPoints.push(pentagonPoints);
      //座標を設定
      Pentagon.setAttribute("points", pentagonPoints);
      return Pentagon;
    }
    //生成した五角形を表示＋プロットした点を表示
    function drawChartFoundation(where) {
      //中心からの距離ごとに五角形をつくる
      var distance = [150, 135, 120, 105, 90, 75, 60, 45, 30, 15, 1];
      for (var i = 0; i < distance.length; i++) {
        createPentagons(distance[i]);
      }
    }
  };

  //【求職者】企業詳細のむらさきの折れ線グラフ用
  if ($('.js-ChartlineGraph').length) {
    $('.js-ChartlineGraph-list').each(function() {
      var index = $(this).children('.js-ChartlineGraph-target').data("num");
      $(this).find('span').eq(index - 1).addClass('is-active');
      var next_index = $(this).next().children('.js-ChartlineGraph-target').data("num");
      if (next_index) {
        $(this).find('span').eq(index - 1).addClass('is-line' + (next_index - index));
      }
    })
  }

});
