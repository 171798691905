<template>
  <div class="applicant-email-template">
    <Sidebar
      :prop-editable-template-list='editableTemplateList'
      :prop-ciy-template-list='ciyTemplateList'
      :prop-current-preview='currentPreview'
      @updateEditableTemplateList='updateEditableTemplateList'
      @setPreview='setPreview'
    />
    <Main
      :prop-other-template-list='otherTemplateList'
      :prop-current-preview='currentPreview'
      :modal-type='modalType'
      @updateEditableTemplateList='updateEditableTemplateList'
      @setPreview='setPreview'
    />
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Main from './Main.vue';

export default {
  components: {
    Sidebar,
    Main
  },

  props: {
    userType: String,
    recruitId: Number,
    userId: Number,
    propEditableTemplateList: Array,
    propCiyTemplateList: Array,
  },

  data() {
    return {
      editableTemplateList: [],
      ciyTemplateList: [],
      otherTemplateList: [],
      currentPreview: {},
      modalType: {},
    }
  },

  created() {
    this.$set(this.$data, 'editableTemplateList', this.propEditableTemplateList);
    this.$set(this.$data, 'ciyTemplateList', this.propCiyTemplateList);
    this.$set(this.$data, 'modalType', {
      userType: this.userType,
      recruitId: this.recruitId,
      userId: this.userId,
    });
  },

  watch: {
    currentPreview() {
      if (this.currentPreview.editable) {
        const filtered = [...this.editableTemplateList, ...this.ciyTemplateList].filter(item => {
          return !item.editable || item.id != this.currentPreview.id;
        });
        this.$set(this.$data, 'otherTemplateList', filtered);
      }
    }
  },

  methods: {
    updateEditableTemplateList(param) {
      this.$set(this.$data, 'editableTemplateList', param);
    },
    setPreview(param) {
      this.$set(this.$data, 'currentPreview', param);
    },
  },
}
</script>
