import consumer from './consumer';
import cableData from './cable_data';

$(window).on('turbolinks:load', function() {
  if ($('body#c-recruit-edit').length || $('body#c-work-recruit').length) {
    const recruitId = $('#v-editable-recruit-time-lapse').data('recruit-id');
    consumer.subscriptions.create({
      channel: 'TimeLapseConvertProgressChannel',
      id: recruitId
    }, {
      received(data) {
        cableData.progress = data;
      }
    });
  }
});
