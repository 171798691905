export function postRecruitFavorite(recruit_id, hadFavorite) {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  var method = hadFavorite ? "DELETE" : "POST";
  return $.ajax({
    url: "/person/ajax/user/favorite",
    method: method,
    data: {
      recruit_id: recruit_id,
    },
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  })
}

export function postJobHunting() {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  return $.ajax({
    url: '/person/ajax/job_hunting',
    method: "POST",
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  });
}

export function deleteJobHunting() {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  return $.ajax({
    url: '/person/ajax/job_hunting',
    method: "DELETE",
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  });
}

export function getSearchCompany(type, area, business, job) {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  return $.ajax({
    url: "/person/ajax/search_company",
    method: "GET",
    data: {
      type: type,
      area: area,
      business: business,
      job: job
    },
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  })
}
