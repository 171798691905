<template>
  <div>
    <div class="application-document">
      <input
        type="file"
        name="document[]"
        :id="`document_${index}`"
        style="display: none;"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png"
        @change="setDocument(index, $event)"
        v-for="(doc, index) in hiddenFileFields"
        :key="doc"
      >
      <input type="hidden" name="delete_ids" v-model="deleteIds">
      <div class="field" v-for="(doc, index) in soldDocuments" :key="`${doc.key}_${index}`">
        <a :href="activeStoragePath(doc.signed_id, doc.name)" target="_blank" class="hov" v-if="doc.id && doc.signed_id">
          <div class="entity">
            <span class="fw-b"><i class="icon-doc mr5"></i>{{doc.name}}</span>
            <span class="fz70 ml10">{{doc.size}}KB</span>
          </div>
        </a>
        <label :for="`document_${index}`" v-else>
          <div class="entity" v-if="!(doc.name) || !(doc.size)">
            <span class="button">ファイルを選択</span>
            <span class="fz70 ml10">選択されていません</span>
          </div>
          <div class="entity" v-else>
            <span class="fw-b"><i class="icon-doc mr5"></i>{{doc.name}}</span>
            <span class="fz70 ml10">{{doc.size}}KB</span>
          </div>
        </label>
        <div class="delete" @click="deleteField(index, doc.id)"></div>
      </div>
      <a href="javascript:void(0)" class="d-f ai-c jc-c hov ptb10">
        <div class="add mr5"></div>
        <p class="fw-b c-key" @click="addField">応募書類を追加</p>
      </a>
    </div>
    <p class="fz70 mt10">※ファイルは5MB以下、拡張子はpdf・doc・docx・xls・xlsx・jpg・pngのみ可能です。</p>
  </div>
</template>

<script>
export default {
  props: {
    propDocuments: Array,
  },
  data() {
    return {
      soldDocuments: [],
      hiddenFileFields: [],
      deleteIds: [],
    }
  },
  created() {
    if (this.propDocuments) {
      this.hiddenFileFields.push(Math.random());
      this.propDocuments.forEach(doc => {
        this.soldDocuments.push({
          key: Math.random(),
          id: doc.id,
          signed_id: doc.signed_id,
          name: doc.blob.filename,
          size: doc.blob.byte_size
        });
      })
    }
    this.addField();
  },
  methods: {
    setDocument(index, event) {
      const file = event.target.files[0];
      const object = {
        key: Math.random(),
        name: file.name,
        size: file.size * 0.001
      };
      this.soldDocuments.splice(index, 1, object);
    },
    addField() {
      this.hiddenFileFields.push(Math.random());
      this.soldDocuments.push({
        key: Math.random(),
        name: null,
        size: null
      });
    },
    deleteField(index, id) {
      this.soldDocuments.splice(index, 1);
      this.hiddenFileFields.splice(index, 1);
      if (id) {
        this.deleteIds.push(id);
      }
    },
    activeStoragePath(signed_id, name) {
      return `/rails/active_storage/blobs/${signed_id}/${name}`
    }
  }
}
</script>

<style>
</style>
