<template>
  <form class="form-flame ptb30 plr20" id="voice-form">
    <div class="dl-table-rspsv">
      <dl>
        <dt class="fz110 fw-b">所属 <span class="required-tag -must">必須</span></dt>
        <dd>
          <div class="validate-flag">
            <input class="w100" type="text" placeholder="部署など" required
              name="department"
              :value="voice.department"
              @input="handleChange"
            >
          </div>
        </dd>
      </dl>
      <dl>
        <dt class="fz110 fw-b">入社 <span class="required-tag -must">必須</span></dt>
        <dd>
          <div class="validate-flag">
            <div class="select w100 d-ib">
              <select required name="join_year" :value="voice.join_year" @input="handleChange">
                <option style="display: none" value="">選択</option>
                <option value="1年目">1年目</option>
                <option value="2年目">2年目</option>
                <option value="3年目">3年目</option>
                <option value="4年目">4年目</option>
                <option value="5年目">5年目</option>
                <option value="6年目">6年目</option>
                <option value="7年目">7年目</option>
                <option value="8年目">8年目</option>
                <option value="9年目">9年目</option>
                <option value="10年目">10年目</option>
                <option value="10年以上~">10年以上~</option>
              </select>
            </div>
          </div>
        </dd>
      </dl>
    </div>
    <div class="mt30">
      <dl>
        <dt class="fz110 fw-b">先輩社員の声 <span class="required-tag -must">必須</span></dt>
        <dd class="pt10">
          <div class="validate-flag">
            <input
              class="w100"
              type="text"
              placeholder="タイトル"
              required
              name="name"
              :value="voice.name"
              @input="handleChange"
            >
          </div>
          <div class="validate-flag pt10">
            <textarea class="w100"
              rows="6"
              name="message"
              placeholder="本文"
              required
              :value="voice.message"
              @input="handleChange"
            ></textarea>
          </div>
        </dd>
      </dl>
    </div>
    <div class="cols nega gutter-10 pt30">
      <div class="col-4 col-sm-6">
        <a href="javascript:void(0)" class="modal-close basic-btn d-b bgc-black80 ff-logo sm-fz120 c-white hov">キャンセル</a>
      </div>
      <div class="col-8 col-sm-6">
        <button type="submit" @click="submit($event)" class="validate-btn basic-btn d-b sm-d-b bgc-key ff-logo fz120 sm-fz140 c-white hov">保存する</button>
      </div>
    </div>
    
  </form>
</template>

<script>
import { BLOB_URL } from '../../utils/endpoint.js'

export default {
  props: {
    voice: {
      type: Object,
      required: true,
    },
    handleChange: {
      type: Function,
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      if (this.isValid()) {
        this.$emit('submit')
      } else {
        this.errorShown = true;
      }
    },
    isValid() {
      const { name, department, join_year, message } = this.voice;
      return name !== "" && department !== "" && join_year !== "" && message !== ""
    },
  }
}
</script>
