import { CanvasUtil } from './canvas-util.js';
import { calcurateRadian, calculateVertexCoordinate } from './calculate-helper.js';

export class ChartA extends CanvasUtil {
  static CANVAS_RATIO = [1, 1];
  static ITEM_LABEL = ['積極性', '安定性', '自制心', '協調性', '発想力'];
  static POLYGON = 5;
  static POINT1_ANGLE = 270;
  #baseCircleRadius;
  #baseCircleCoordinate;
  #renderQue = [];

  constructor(canvas, canvasWrapper) {
    super(canvas, canvasWrapper);
  }

  addChart({color = CanvasUtil.C_BLACK, score = [...Array(ChartA.ITEM_LABEL.length)].map(() => 50)} = {}) {
    this.#renderQue.push({ color, score });
    return this;
  }

  render() {
    this.#calculateCanvas();
    this.#renderRadar();
    this.#renderText();
    this.#renderQue.forEach((chart, index) => {
      this.#renderData(chart, index);
    });
  }

  #renderRadar() {
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius, 54, 126, {strokeStyle: CanvasUtil.C_GRAY}, false, true, true);
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius, 126, 198, {strokeStyle: CanvasUtil.C_GRAY}, false, true, true);
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius, 198, 270, {strokeStyle: CanvasUtil.C_GRAY}, false, true, true);
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius, 270, 342, {strokeStyle: CanvasUtil.C_GRAY}, false, true, true);
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius, 342, 54, {strokeStyle: CanvasUtil.C_GRAY}, false, true, true);

    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius * 0.75, 0, 360, {strokeStyle: CanvasUtil.C_GRAY});
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius * 0.5, 0, 360, {strokeStyle: CanvasUtil.C_GRAY});
    this.drawCircle(this.#baseCircleCoordinate, this.#baseCircleRadius * 0.25, 0, 360, {strokeStyle: CanvasUtil.C_GRAY});
  }

  #renderText() {
    const percentageStyles = { font: `bold 9px ${CanvasUtil.DEFAULT_FONT}`, textAlign: 'right' }
    this.drawText(this.#calculatePercentageTextCoordinate(0), '0%', {...percentageStyles});
    this.drawText(this.#calculatePercentageTextCoordinate(25), '25%', {...percentageStyles});
    this.drawText(this.#calculatePercentageTextCoordinate(50), '50%', {...percentageStyles});
    this.drawText(this.#calculatePercentageTextCoordinate(75), '75%', {...percentageStyles});
    this.drawText(this.#calculatePercentageTextCoordinate(100), '100%', {...percentageStyles});

    const labelStyles = { font: `bold 14px ${CanvasUtil.DEFAULT_FONT}` }
    this.drawText(this.#calculateLabelCoordinate(270, 125), '積極性', { ...labelStyles, textAlign: 'center' });
    this.drawText(this.#calculateLabelCoordinate(342, 105), '安定性', { ...labelStyles, textAlign: 'left' });
    this.drawText(this.#calculateLabelCoordinate(54, 105), '自制心', { ...labelStyles, textAlign: 'left', textBaseline: 'top' });
    this.drawText(this.#calculateLabelCoordinate(126, 105), '協調性', { ...labelStyles, textAlign: 'right', textBaseline: 'top' });
    this.drawText(this.#calculateLabelCoordinate(198, 105), '発想力', { ...labelStyles, textAlign: 'right' });
  }

  #renderData({ color, score }, chartQueIndex) {
    const coordinateList = score.map((score, index) => {
      return calculateVertexCoordinate(
        this.#baseCircleCoordinate,
        this.#baseCircleRadius,
        calcurateRadian(360 / ChartA.POLYGON * index + ChartA.POINT1_ANGLE),
        score
      );
    });
    this.drawStraightLineShape(
      coordinateList,
      { fillStyle: `${color}40`, strokeStyle: color, lineWidth: 2, setLineDash: [2, 4], lineCap: 'round' },
      true, true, true
    );
    coordinateList.forEach((coordinate) => {
      if ((chartQueIndex + 1) % 2 === 0) {
        this.drawCircle(coordinate, 4, 0, 360, { fillStyle: color }, true, false, false);
      } else {
        this.drawCircle(coordinate, 5, 0, 360, { strokeStyle: color, fillStyle: '#fff', lineWidth: 3 }, true, true, false);
      }
    });
  }

  #calculateCanvas() {
    super.calculateCanvas(ChartA.CANVAS_RATIO);
    this.#baseCircleRadius = this.width / 2 * 0.7;
    this.#baseCircleCoordinate = this.canvasCenterCoordinate;
  }

  #calculatePercentageTextCoordinate(percentage) {
    const [x, y] = calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(270), percentage);
    return [x - 5, y - 5];
  }

  #calculateLabelCoordinate(angle, percentage) {
    return calculateVertexCoordinate(this.#baseCircleCoordinate, this.#baseCircleRadius, calcurateRadian(angle), percentage);
  }
}
