<template>
  <div class="main">
    <div class="preview-area">
      <div class="copy-other-template" v-show='currentPreview.editable'>
        <div class="button" @click='otherTemplateListShow = !otherTemplateListShow'>別の定型文からコピー</div>
        <ul class="list" v-show='otherTemplateListShow'>
          <li
            v-for='(item, index) in otherTemplateList'
            :key='index'
            @click='copyTemplate(item.subject, item.content)'
          >
            {{item.title}}
          </li>
        </ul>
      </div>
      <div class="subject">
        <input type="text" :ref='"subject"' class="text" :value='currentPreview.subject' placeholder="件名を入力ください" v-show='currentPreview.editable'>
        <p class="text" v-show='!currentPreview.editable'>{{currentPreview.subject}}</p>
      </div>
      <div class="content" :style='{overflowY: currentPreview.editable ? "initial" : "scroll"}'>
        <textarea class="text" :ref='"content"' :value='currentPreview.content' placeholder="本文を入力ください" v-show='currentPreview.editable'></textarea>
        <p class="text" v-show='!currentPreview.editable'>{{currentPreview.content}}</p>
      </div>
      <a class="save-editable-template" @click='updateEditableTemplate(currentPreview.id)' v-show='currentPreview.editable'>変更を保存する</a>
    </div>
    <div class="mt20">
      <p class="fz70 c-black80 fw-b ta-l" v-if='currentPreview.editable'>{{explanation1}}</p>
      <p class="fz70 c-black80 fw-b ta-c" v-else>{{explanation2}}</p>
    </div>
    <div class="mt20">
      <a class="use-template" @click='useTemplate'>この定型文を使う</a>
    </div>
    <div class="blocker" v-show="blockerShow"></div>
  </div>
</template>

<script>
export default {
  props: {
    propOtherTemplateList: Array,
    propCurrentPreview: Object,
    modalType: Object,
  },

  data() {
    return {
      otherTemplateList: [],
      currentPreview: {},
      otherTemplateListShow: false,
      blockerShow: false,
      explanation1: "{{応募者名}} {{企業名}} {{担当者名}} {{本社所在地}} {{電話番号}} を入れると、挿入時にシステム登録情報から自動的に変換されます。",
      explanation2: "{{ }}の中の内容は、登録情報などから自動的に挿入されます",
    }
  },

  watch: {
    propOtherTemplateList(newValue, _) {
      this.$set(this.$data, 'otherTemplateList', newValue);
    },
    propCurrentPreview(newValue, _) {
      this.$set(this.$data, 'currentPreview', newValue);
    }
  },

  methods: {
    copyTemplate(subject, content) {
      this.$set(this.currentPreview, 'subject', subject);
      this.$set(this.currentPreview, 'content', content);
      this.otherTemplateListShow = !this.otherTemplateListShow;
    },

    async updateEditableTemplate(argId) {
      this.blockerShow = true;
      const data = {
        id: argId,
        subject: this.$refs.subject.value,
        content: this.$refs.content.value,
      };
      const res = await fetch('/company/ajax/applicant/update_editable_template', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const { list, current } = await res.json();
      this.$emit('updateEditableTemplateList', list);
      const { id, subject, content, editable } = current;
      this.$emit('setPreview', {
        id: id,
        subject: subject,
        content: content,
        editable: editable,
      });
      this.blockerShow = false;
    },

    async useTemplate() {
      this.blockerShow = true;
      const body = {
        recruit_id: this.modalType.recruitId,
        user_id: this.modalType.userId,
        subject: this.$refs.subject.value,
        content: this.$refs.content.value,
      };
      const res = await fetch(`/company/ajax/mustache/${this.modalType.userType}/use_template`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      document.getElementById('message-ui-subject').value = data.subject;
      document.getElementById('message-ui-content').value = data.content;
      document.querySelector('.modal-wrap[data-modal="email-template"]').style.display = 'none';
      this.blockerShow = false;
    },
  },
}
</script>
