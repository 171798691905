<template>
  <div id="create-mail">
    <form ref="createMailForm" enctype="multipart/form-data" :action="formAction" accept-charset="UTF-8" method="post">
      <input type="hidden" name="authenticity_token" :value="csrfToken">
      <div v-if="scout" id="js-scoutPersonList-codeMountTarget">
      </div>
      <input type="hidden" name="recruit_id" :value="recruitId" v-if="scout">
      <input type="hidden" name="user_code[]" :value="userCode" v-if="scout && userCode">
      <div class="message-input-ui js-messageFocusUi mt20">
        <div class="bd-key bgc-white bdrs5 d-f ai-c mb10 ptb5 plr10" style="gap: 20px;" v-if="scout">
          <span class="c-key fw-b">スカウトする求人</span>
          <span class="fw-b fz90">{{ recruitTitle }}</span>
        </div>
        <div class="inner">
          <div class="subject">
            <input type="text" id="message-ui-subject" name="subject" placeholder="件名を入力してください" ref="subject" :value="subject">
          </div>
          <div class="content bt-black50">
            <textarea id="message-ui-content" name="content" placeholder="本文を入力してください" ref="content">{{ content }}</textarea>
          </div>
          <div class="attachments">
            <div class="item" v-for="(fileObject, index) in fileObjectList" :key="fileObject.name">
              <p class="fw-b mr5">{{fileObject.name}}</p>
              <p class="fz85 c-black50 mr10">{{Math.round(fileObject.size / 1024)}}KB</p>
              <div class="delete" @click="deleteFile(index)"></div>
            </div>
          </div>
        </div>
        <div class="mt20 c-red fw-b" v-show="errors.hasError">
          <p v-for="message in errors.subject" :key="message">{{message}}</p>
          <p v-for="message in errors.content" :key="message">{{message}}</p>
          <p v-for="message in errors.attachments" :key="message">{{message}}</p>
        </div>
        <div class="d-f ai-c ptb15">
          <a href="javascript:void(0)" class="function-button hov mr20 modal-tgl" data-modal="email-template" v-if="!propScout">
            <span class="icon mr5"><img src="/img/message/template.svg"></span>定型文を使う
          </a>
          <a href="javascript:void(0)" class="function-button hov mr20">
            <label>
              <span class="icon mr5"><img src="/img/message/attachment.svg"></span>ファイルを添付する
              <input type="file" name="attachments[]" multiple accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.png" @change="setFile" ref="attachments">
            </label>
          </a>
          <a href="javascript:void(0)" class="function-button hov modal-tgl" data-modal="request-schedule" v-if="!propScout">
            <span class="icon mr5"><img src="/img/message/schedule.svg"></span>面接日程を調整する
          </a>
        </div>
        <button type="submit" class="submit-button hov" @click="clickSubmit" ref="submitButton">送信する</button>
      </div>
    </form>

    <div class="modal-wrap large" data-modal="request-schedule" style="display: none;" ref="requestScheduleModal">
      <div class="modal-wrap-view">
        <!-- .modal -->
        <div class="modal ptb30 plr20">
          <p class="fw-b fz170 ta-c">面接日程を調整する</p>
          <p class="mt35 fw-b ta-c">応募者に、面接候補を第三候補まで指定してもらうフォームURLを添えたメールを送ります。</p>
          <p class="fw-b ta-c c-red">※面接対応不可な曜日や時間帯がある場合、あらかじめ<a href="/company/profile/edit" class="under-line hov">企業情報ページ</a>にて編集ください。</p>
          <figure class="ta-c mt20">
            <img src="/img/message/request-schedule-modal-pic.png" width="204" alt="面接日程を調整する">
          </figure>
          <div class="ta-c mt20">
            <button class="basic-btn d-ib sm-d-b bgc-key ff-logo fz120 sm-fz140 c-white hov" @click="getScheduling">面接日程の調整を依頼する</button>
          </div>
          <div class="ta-c mt15">
            <a href="javascript:void(0)" class="modal-close hov fw-b fz120">キャンセル</a>
          </div>
          <div class="close-btn modal-close">閉じる</div>
        </div>
      </div>
      <!-- .black-back -->
      <div class="black-back modal-close"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propUserType: String,
    propFormAction: String,
    propRecruitId: Number,
    propUserId: Number,
    propScout: Boolean,
    propRecruitTitle: String,
    propSubject: String,
    propContent: String,
    propUserCode: String,
  },
  data() {
    return {
      csrfToken: document.querySelector('meta[name="csrf-token"]').content,
      userType: null,
      code: null,
      formAction: null,
      recruitId: null,
      userId: null,
      fileObjectList: [],
      errors: {
        hasError: false,
        subject: [],
        content: [],
        attachments: [],
      },
      scout: false,
      recruitTitle: null,
      subject: null,
      content: null,
      userCode: null,
    }
  },
  created() {
    this.formAction = this.propFormAction || "/";
    this.userType = this.propUserType;
    this.recruitId = this.propRecruitId;
    this.userId = this.propUserId;
    this.scout = this.propScout;
    this.recruitTitle = this.propRecruitTitle;
    this.subject = this.propSubject;
    this.content = this.propContent;
    this.userCode = this.propUserCode;
  },
  mounted() {
    this.$refs.createMailForm.addEventListener('submit', this.doubleSubmissionBlock);
  },
  methods: {
    setFile() {
      Array.from(this.$refs.attachments.files).forEach(file => {
        this.fileObjectList.push(file);
      });
      this.$refs.attachments.files = this.createFileList();
    },
    deleteFile(index) {
      this.fileObjectList.splice(index, 1);
      this.$refs.attachments.files = this.createFileList();
    },
    createFileList() {
      const dataTransfer = new DataTransfer();
      this.fileObjectList.forEach(file => {
        dataTransfer.items.add(file);
      });
      return dataTransfer.files;
    },
    isValid() {
      this.errors = {
        hasError: false,
        subject: [],
        content: [],
        attachments: [],
      }

      if (!(this.$refs.subject.value.length)) {
        this.errors.subject.push('件名をご入力ください。');
        this.$set(this.errors, 'hasError', true);
      }
      if (!(this.$refs.content.value.length)) {
        this.errors.content.push('本文をご入力ください。');
        this.$set(this.errors, 'hasError', true);
      }
      if (this.$refs.content.value.includes('●')) {
        this.errors.content.push('本文内に、定型文のサンプル文字「●」が含まれています。');
        this.$set(this.errors, 'hasError', true);
      }
      if (this.$refs.content.value.includes('}}')) {
        this.errors.content.push('本文内に、定型文のサンプル文字「}}」が含まれています。');
        this.$set(this.errors, 'hasError', true);
      }
      if (this.$refs.content.value.includes('{{')) {
        this.errors.content.push('本文内に、定型文のサンプル文字「{{」が含まれています。');
        this.$set(this.errors, 'hasError', true);
      }
      if (this.$refs.attachments.files.length > 5) {
        this.errors.attachments.push('添付ファイルは一度に5ファイルまで送信可能です。');
        this.$set(this.errors, 'hasError', true);
      }
      if (this.fileObjectList.length && this.fileObjectList.map(file => file.size).reduce((a,b) => a + b) >= 5242880) {
        this.errors.attachments.push('添付ファイルのサイズは5MB以下にしてください。');
        this.$set(this.errors, 'hasError', true);
      }

      if (this.errors.hasError) {
        return false;
      } else {
        return true;
      }
    },
    doubleSubmissionBlock() {
      this.$refs.submitButton.disabled = true;
      this.$refs.submitButton.textContent = "送信中です";
    },
    clickSubmit(event) {
      if (!(this.isValid())) {
        event.preventDefault();
      }
    },
    async getScheduling() {
      const params = new URLSearchParams({
        recruit_id: this.recruitId,
        user_id: this.userId,
      });
      const res = await fetch(`/company/ajax/mustache/${this.userType}/scheduling?${params.toString()}`, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        }
      });
      const obj = await res.json();
      this.$refs.subject.value = obj.subject;
      this.$refs.content.value = obj.content;
      this.$refs.requestScheduleModal.style.display = 'none';
    }
  }
}
</script>
