import { calcurateRadian } from './calculate-helper.js';

export class CanvasUtil {
  static DEFAULT_FONT = '"Noto Sans JP"';
  static C_GRAY = '#e8e8e8';
  static C_YELLOW = '#c6c031';
  static C_BLACK = '#363636';
  #canvas;
  #canvasWrapper;
  #context;
  #canvasCenterCoordinate;
  #width;
  #height;

  constructor(canvas, canvasWrapper) {
    this.#canvas = canvas;
    this.#canvasWrapper = canvasWrapper;
    this.#context = canvas.getContext('2d');
  }

  drawText(coordinate, text, styles = {}, fill = true, stroke = false) {
    this.#setStyles(styles);
    fill && this.#context.fillText(text, ...coordinate);
    stroke && this.#context.strokeText(text, ...coordinate);
    this.#resetStyles();
  }

  drawRectangle(coordinate, width, height, styles = {}, fill, stroke) {
    this.#setStyles(styles);
    this.#context.beginPath();
    this.#context.rect(...coordinate, width, height);
    fill && this.#context.fill();
    stroke && this.#context.stroke();
    this.#resetStyles();
  }

  drawCircle(coordinate, radius, startAngle, endAngle, styles = {}, fill = false, stroke = true, sector = false) {
    this.#setStyles(styles);
    this.#context.beginPath();
    sector && this.#context.moveTo(...coordinate);
    this.#context.arc(
      ...coordinate,
      radius,
      calcurateRadian(startAngle),
      calcurateRadian(endAngle)
    );
    sector && this.#context.lineTo(...coordinate);
    this.#context.closePath();
    fill && this.#context.fill();
    stroke && this.#context.stroke();
    this.#resetStyles();
  }

  drawStraightLineShape(coordinateList, styles = {}, fill, stroke, close) {
    this.#setStyles(styles);
    this.#context.beginPath();
    coordinateList.forEach((coordinate, index) => {
      index === 0
      ? this.#context.moveTo(...coordinate)
      : this.#context.lineTo(...coordinate)
    });
    close && this.#context.closePath();
    fill && this.#context.fill();
    stroke && this.#context.stroke();
    this.#resetStyles();
  }

  #setStyles({
    strokeStyle = CanvasUtil.C_BLACK,
    fillStyle = CanvasUtil.C_BLACK,
    lineWidth = 1,
    setLineDash = [],
    lineCap = 'butt',
    lineJoin = 'miter',
    font = `bold 10px ${CanvasUtil.DEFAULT_FONT}`,
    textAlign = 'start',
    textBaseline = 'alphabetic',
  }) {
    this.#context.strokeStyle = strokeStyle;
    this.#context.fillStyle = fillStyle;
    this.#context.lineWidth = lineWidth;
    this.#context.setLineDash(setLineDash);
    this.#context.lineCap = lineCap;
    this.#context.lineJoin = lineJoin;
    this.#context.font = font;
    this.#context.textAlign = textAlign;
    this.#context.textBaseline = textBaseline;
  }

  #resetStyles() {
    this.#context.strokeStyle = CanvasUtil.C_BLACK;
    this.#context.fillStyle = CanvasUtil.C_BLACK;
    this.#context.lineWidth = 1;
    this.#context.setLineDash([]);
    this.#context.lineCap = 'butt';
    this.#context.lineJoin = 'miter';
    this.#context.font = `bold 10px ${CanvasUtil.DEFAULT_FONT}`;
    this.#context.textAlign = 'start';
    this.#context.textBaseline = 'alphabetic';
  }

  calculateCanvas(canvasRatio) {
    this.#width = this.#canvasWrapper.clientWidth;
    this.#height = this.#canvasWrapper.clientWidth * (canvasRatio[1] / canvasRatio[0]);
    this.#canvas.style.width = `${this.#width}px`;
    this.#canvas.style.height = `${this.#height}px`;

    const scale = window.devicePixelRatio;
    this.#canvas.width = Math.floor(this.#width * scale);
    this.#canvas.height = Math.floor(this.#height * scale);

    this.#context.scale(scale, scale);

    this.#canvasCenterCoordinate = [this.#width / 2, this.#height / 2];
  }

  get width() {
    return this.#width;
  }

  get height() {
    return this.#height;
  }

  get canvas() {
    return this.#canvas;
  }

  get context() {
    return this.#context;
  }

  get canvasCenterCoordinate() {
    return this.#canvasCenterCoordinate;
  }
}

