import {
  postRecruitFavorite,
  postJobHunting,
  deleteJobHunting,
} from './ajax.js';

$(window).on('turbolinks:load', function () {
  //【共通】URLなどコピーするボタン
  var clipboard = new ClipboardJS('.jsClipboardCopy');
  clipboard.on('success', (e) => {
    const title = e.trigger.dataset.clipboardTitle;
    alert(title + 'をコピーしました');
  });

  //【共通】お気に入りボタン
  //someday_shame:いつか、企業詳細ページのお気に入りボタン２つが連動していないので連動させたい
  if ($('.js-favoriteButton').length) {
    $(document).on('click', '.js-favoriteButton', (e) => {
      var recruit_id = $(e.currentTarget).data('recruit-id');
      var hadFavorite = $(e.currentTarget).hasClass('is-active');
      if (recruit_id) {
        postRecruitFavorite(recruit_id, hadFavorite).then((res) => {
          $(e.currentTarget).toggleClass('is-active');
        });
      }
    });
  }

  //【共通】数値を兆億万の表記に変換する
  if ($('.js-addNumeral').length) {
    $('.js-addNumeral').each((index, e) => {
      var num = $(e).text() + '000';
      var keta = ['', '万', '億', '兆'];
      var nums = String(num)
        .replace(/(\d)(?=(\d\d\d\d)+$)/g, '$1,')
        .split(',')
        .reverse();
      var data = '';
      for (var i = 0; i < nums.length; i++) {
        if (!nums[i].match(/^[0]+$/)) {
          data = nums[i].replace(/^[0]+/g, '') + keta[i] + data;
        }
      }
      $(e).text(data);
    });
  }

  //【共通】URLの項目で「http」が入ってなかったときに「http://」を入れる
  if ($('.js-urlCheck').length) {
    $('.js-urlCheck').each((index, e) => {
      var url = $(e).attr('href');
      if (url.indexOf('http') !== 0 && url.indexOf('//') !== 0) {
        url = 'http://' + url;
        $(e).attr('href', url);
      }
    });
  }

  //【共通】"http"が存在すればリンク化
  if ($('.js-linkWrap').length) {
    $('.js-linkWrap').each(function () {
      $(this).html(
        $(this)
          .html()
          .replace(
            /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g,
            '<a href="$1" target="_blank" class="c-blue under-line">$1</a> '
          )
      );
    });
  }

  //【共通】モーダル系の記述
  if ($('.js-modalWrap').length) {
    $('.js-modalToggle').on('click', (e) => {
      //モーダルの表示
      var modal = $(e.currentTarget).data('modal');
      $('.js-modalWrap[data-modal="' + modal + '"]')
        .fadeIn(200)
        .addClass('is-active')
        .css('display', 'flex');
    });
    $('.js-modalClose').on('click', () => {
      //モーダルの非表示
      $('.js-modalWrap').removeClass('is-active').fadeOut(200);
    });
  }

  //【共通】スライドモーダル系の記述
  if ($('.js-modalSlideWrap').length) {
    $('.js-modalSlideToggle').on('click', (e) => {
      //モーダルの表示
      if (!$(e.currentTarget).hasClass('is-active')) {
        var modal = $(e.currentTarget).data('modal');
        $('.js-modalSlideToggle').removeClass('is-active');
        $(e.currentTarget).addClass('is-active');
        $('.js-modalSlideWrap').removeClass('is-active');
        $('.js-modalSlideWrap[data-modal="' + modal + '"]').addClass('is-active');
      } else {
        $(e.currentTarget).removeClass('is-active');
        $('.js-modalSlideWrap').removeClass('is-active');
      }
    });
    $('.js-modalSlideWrap .js-modalClose').on('click', function () {
      //モーダルの非表示
      $('.js-modalSlideWrap').removeClass('is-active');
      $('.js-modalSlideToggle').removeClass('is-active');
    });
  }

  //【共通】ツールチップ
  if ($('.js-toolTip').length) {
    //クリックしたら出現する
    $('.js-toolTip').on({
      mouseenter: function () {
        $(this).addClass('is-active');
        $(this).children('.js-toolTip-body').fadeIn(100);
      },
      mouseleave: function () {
        $(this).removeClass('is-active');
        $(this).children('.js-toolTip-body').fadeOut(100);
      },
    });
  }

  // ツールチップ 改良
  if (!!document.querySelectorAll('.js-awesomeTooltip-trigger')) {
    let tooltipBody;

    document.addEventListener('mouseover', (e) => {
      const trigger = e.target.closest('.js-awesomeTooltip-trigger');

      if (trigger) {
        const tooltipBodyParent = document.querySelector(
          `.js-awesomeTooltip-body[data-tooltip-name="${trigger.dataset.tooltipName}"]`
        );
        if (!tooltipBodyParent) return;
        if (tooltipBody) tooltipBody.remove();
        tooltipBody = tooltipBodyParent.cloneNode(true);

        if (tooltipBody.dataset.minWidth) tooltipBody.style.minWidth = tooltipBody.dataset.minWidth;
        if (tooltipBody.dataset.maxWidth) tooltipBody.style.maxWidth = tooltipBody.dataset.maxWidth;

        document.body.append(tooltipBody);
        tooltipBody.hidden = false;
        tooltipBody.animate({ opacity: [0, 1] }, 100);

        const coords = trigger.getBoundingClientRect();
        let left = coords.left + (trigger.offsetWidth - tooltipBody.offsetWidth) / 2;
        if (left < 0) left = 0;
        let top = coords.top + coords.height - 5;

        tooltipBody.style.left = `${left}px`;
        tooltipBody.style.top = `${top}px`;

        // 画面から出さないように
        const tooltipBodyCoods = tooltipBody.getBoundingClientRect();

        if (document.documentElement.clientHeight < tooltipBodyCoods.bottom) {
          tooltipBody.style.top = `${coords.top - tooltipBodyCoods.height + 5}px`;
        }

        if (document.documentElement.clientWidth < tooltipBodyCoods.right) {
          tooltipBody.style.left = `${
            document.documentElement.clientWidth - tooltipBodyCoods.width
          }px`;
        }
      } else {
        if (e.target.closest('.js-awesomeTooltip-body')) return;
        if (tooltipBody) {
          tooltipBody.remove();
          tooltipBody = null;
        }
      }
    });
  }

  //【共通】スムーススクロール
  $('a[href^="#"]').on('click', (e) => {
    var H = $('header').outerHeight();
    var href = $(e.currentTarget).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top - H;
    var speed = 500;
    $('html, body').animate(
      {
        scrollTop: position,
      },
      speed,
      'swing'
    );
    return false;
  });

  //【共通】ドロップダウンの動き
  $('.js-dropDown-toggle').on('click', (e) => {
    $(e.currentTarget)
      .toggleClass('is-active')
      .next('.js-dropDown-body')
      .slideToggle('fast')
      .toggleClass('is-active');
    $(e.currentTarget).toggleClass('');
  });

  //【求職者】「企業からスカウトをもらう」の切り替え
  for (const checkbox of document.querySelectorAll('.js-changeScoutFromCompany')) {
    checkbox.addEventListener('change', (e) => {
      (async () => {
        const res = await fetch('/person/ajax/change_scout_from_companies', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            is_offered: e.currentTarget.checked,
          }),
        });

        if (res.ok) {
          const data = await res.json();
          checkbox.checked = data.checked;
        } else {
          const data = await res.json();
          checkbox.checked = data.checked;
          if (res.status === 302) {
            location.href = data.location;
          }
        }
      })();
    });
  }

  //【求職者】「希望に合う仕事の紹介を受ける」の切り替え
  for (const checkbox of document.querySelectorAll('.js-changeUsingAgent')) {
    checkbox.addEventListener('change', (e) => {
      (async () => {
        const res = await fetch('/person/ajax/change_using_agent', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            using_agent: e.currentTarget.checked,
          }),
        });

        if (res.ok) {
          const data = await res.json();
          checkbox.checked = data.checked;
        } else {
          const data = await res.json();
          checkbox.checked = data.checked;
          if (res.status === 302) {
            location.href = data.location;
          }
        }
      })();
    });
  }

  //【求職者】就職活動中か否かのトグル
  $('.js-jobHuntingToggle').on('click', (e) => {
    var selector = e.currentTarget;
    var job_hunting_type = false;
    if ($(selector).prop('checked')) {
      job_hunting_type = true;
    }
    if (job_hunting_type) {
      postJobHunting().done((res) => {
        $('.js-jobHuntingToggle').prop('checked', true);
      });
    } else {
      deleteJobHunting().done((res) => {
        $('.js-jobHuntingToggle').prop('checked', false);
      });
    }
  });

  //【求職者】グローバルナビ
  if ($('.js-globalNaviTrigger').length) {
    $('.js-globalNaviTrigger').on('click', function () {
      $(this).toggleClass('is-active').next('.js-globalNavi').toggleClass('is-active');
    });
  }

  //【求職者】読み込み時に画面内に入っていたら、要素の順番にアニメーション表示
  var Position = $(window).height() + $(window).scrollTop();
  $('.js-view').each(function (i) {
    if (!$(this).hasClass('is-on') && Position > $(this).offset().top) {
      $(this)
        .delay(60 * i)
        .queue(function () {
          $(this).addClass('is-on');
        });
    }
  });

  //【求職者】スクロールして、画面内にきたらclass付与
  $(window).on('scroll', function () {
    var scrollPosition = $(window).height() + $(window).scrollTop();
    $('.js-view').each(function () {
      if (scrollPosition - 100 > $(this).offset().top) {
        $(this).addClass('is-on');
      }
    });
  });

  //【求職者】トップページのローディング画面（非ページ遷移）を非表示
  if ($('.js-personToppage-loading').length) {
    setTimeout(function () {
      $('.js-personToppage-loading').addClass('is-loaded');
    }, 1000);
  }

  //【求職者】トップページのメインスライド
  if ($('.p-personToppage-main').length) {
    var sliderThumbnail = new Swiper('.js-p-personToppage-uppNav', {
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var slider = new Swiper('.js-p-personToppage-mainSlide', {
      speed: 800,
      autoplay: {
        delay: 4000,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      thumbs: {
        swiper: sliderThumbnail,
      },
      on: {
        // 切り替わりのアニメーションが終了したとき
        slideChangeTransitionStart: function () {
          swipe_action();
        },
      },
    });

    function swipe_action() {
      var index = slider.activeIndex * -60;
      $('.js-swiper-moveText img').css({
        transform: 'translate(' + index + 'px , 0px)',
      });
    }
  }

  //【求職者】マッチ度のカウントアップ
  if ($('.js-countUp').length) {
    //カウントアップのモジュール
    var shuffleNumberCounter = (target) => {
      var targetNum = Number(target.data('num'));
      if (!targetNum) {
        return;
      }
      var counterData = null;
      var speed = 800 / targetNum;
      var initNum = 0;
      var countUp = () => {
        if (Number.isInteger(targetNum)) {
          if (target.data('padding')) {
            const padNum = String(initNum).padStart(target.data('padding'), '0');
            target.text(padNum);
          } else {
            target.text(initNum);
          }
        } else {
          target.innerHTML = `${initNum}.${Math.floor(Math.random() * 9)}`;
        }
        initNum++;
        if (initNum > targetNum) {
          target.innerHTML = targetNum;
          clearInterval(counterData);
        }
      };
      counterData = setInterval(countUp, speed);
    };
    $('.js-countUp').each(function (i) {
      if (!$(this).hasClass('is-done') && Position > $(this).offset().top) {
        var target = $(this);
        shuffleNumberCounter(target);
        $(this).addClass('is-done');
      }
    });

    //【求職者】スクロールして、画面内にきたらclass付与
    $(window).on('scroll', function () {
      var scrollPosition = $(window).height() + $(window).scrollTop();
      $('.js-countUp').each(function () {
        if (!$(this).hasClass('is-done') && scrollPosition - 100 > $(this).offset().top) {
          var target = $(this);
          shuffleNumberCounter(target);
          $(this).addClass('is-done');
        }
      });
    });
  }

  //【求職者】画面内に入ったらパーセントインジケーターにwidthをセット
  if (!!document.querySelectorAll('.js-ranking-percentBar').length) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.dataset.per == undefined) {
            throw new Error('`data-per`が見つかりません。');
          }
          if (Number.isNaN(parseFloat(entry.target.dataset.per, 10))) {
            throw new Error('`data-per`に数値を渡してください。');
          }
          entry.target.style.width = `${entry.target.dataset.per}%`;
        }
      });
    });
    document
      .querySelectorAll('.js-ranking-percentBar')
      .forEach((element) => observer.observe(element));
  }

  if (!!document.getElementById('js-assessmentTabScroll')) {
    const includeMargineWidth = (element) => {
      if (element) {
        return (
          element.offsetWidth +
          parseFloat(getComputedStyle(element).marginLeft) +
          parseFloat(getComputedStyle(element).marginRight)
        );
      } else {
        return null;
      }
    };
    const scrollBox = document.getElementById('js-assessmentTabScroll');

    scrollBox.addEventListener('scroll', (event) => {
      const max = scrollBox.scrollWidth - scrollBox.clientWidth;
      const min = 0;
      event.currentTarget.scrollLeft >= max
        ? event.currentTarget.parentElement.classList.add('is-scrollMax')
        : event.currentTarget.parentElement.classList.remove('is-scrollMax');
      event.currentTarget.scrollLeft <= min
        ? event.currentTarget.parentElement.classList.add('is-scrollMin')
        : event.currentTarget.parentElement.classList.remove('is-scrollMin');
    });

    let stack = 0;
    const scrollLeft = Object.fromEntries(
      Array.from(scrollBox.querySelectorAll('[data-key]')).map((element) => {
        stack += includeMargineWidth(element);
        return [element.dataset.key, stack - includeMargineWidth(element)];
      })
    );
    scrollBox.scrollLeft = scrollLeft[location.pathname.split('/').pop()] - 15;
  }

  if (!!document.querySelectorAll('.js-autoFlexTextarea').length) {
    const autoFlexTextarea = (textarea) => {
      textarea.style.setProperty('height', 'auto');
      textarea.style.setProperty('height', `${textarea.scrollHeight + 3}px`);
    };

    const textareaList = document.querySelectorAll('.js-autoFlexTextarea');

    textareaList.forEach((textarea) => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          autoFlexTextarea(entry.target);
        }
      });
      resizeObserver.observe(textarea);
      textarea.addEventListener('input', (e) => autoFlexTextarea(e.currentTarget));
    });

    window.addEventListener('resize', () => {
      textareaList.forEach((textarea) => {
        autoFlexTextarea(textarea);
      });
    });
  }

  if (!!document.querySelectorAll('.js-instantMemo').length) {
    const openMemo = (memo) => {
      const durMs = parseFloat(getComputedStyle(memo).getPropertyValue('--dur'));
      memo.classList.remove('is-close');
      setTimeout(() => {
        memo.removeAttribute('style');
      }, durMs);
    };

    const closeMemo = (memo) => {
      const rect = memo.getBoundingClientRect();
      memo.style.width = `${rect.width}px`;
      memo.style.height = `${rect.height}px`;
      memo.classList.add('is-close');
    };

    document.querySelectorAll('.js-instantMemo').forEach((instantMemo) => {
      closeMemo(instantMemo);

      const durMs = parseFloat(getComputedStyle(instantMemo).getPropertyValue('--dur'));
      setTimeout(() => {
        instantMemo.style.visibility = null;
        instantMemo.style.opacity = null;
      }, durMs);

      const openButton = instantMemo.querySelector('.js-instantMemo-open');
      const closeButton = instantMemo.querySelector('.js-instantMemo-close');

      openButton.addEventListener('click', () => {
        openMemo(instantMemo);
      });
      closeButton.addEventListener('click', () => {
        closeMemo(instantMemo);
      });
    });
  }

  // 業種・職種ランキングページの下部の「他の業種・職種を見る」のリスト
  if (!!document.querySelectorAll('.js-industryRanking-otherList').length) {
    const [list] = document.querySelectorAll('.js-industryRanking-otherList');
    const observeTargetList = Array.from(
      list.querySelectorAll('.p-personIndustryRanking-otherList-listItem')
    );
    const active = observeTargetList.find((element) => element.classList.contains('isActive'));
    const scrollLeftButton = document.querySelector('.js-industryRanking-scrollButton.-left');
    const scrollRightButton = document.querySelector('.js-industryRanking-scrollButton.-right');
    const scroll = document.querySelector('.js-industryRanking-scroll');

    const scrollButtonToggle = (scrollTarget) => {
      const scrollLeftMin = 0;
      const scrollLeftMax = scrollTarget.scrollWidth - scrollTarget.clientWidth;

      if (scrollTarget.scrollLeft <= scrollLeftMin) {
        scrollLeftButton.classList.add('isHide');
      } else if (scrollTarget.scrollLeft >= scrollLeftMax) {
        scrollRightButton.classList.add('isHide');
      } else {
        scrollLeftButton.classList.remove('isHide');
        scrollRightButton.classList.remove('isHide');
      }
    };

    let intersected = [];
    let leftIndex = undefined;
    let rightIndex = undefined;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            intersected = [...new Set([...intersected, observeTargetList.indexOf(entry.target)])];
          } else {
            intersected = [
              ...intersected.filter((index) => index !== observeTargetList.indexOf(entry.target)),
            ];
          }
        });

        leftIndex = Math.min(...intersected) - 1;
        rightIndex = Math.max(...intersected) + 1;
      },
      { root: document.querySelector('.p-personIndustryRanking-otherList-list'), threshold: [1] }
    );

    observeTargetList.forEach((element) => observer.observe(element));
    active.scrollIntoView({ inline: 'start' });
    window.scrollTo(0, 0);
    scrollButtonToggle(scroll);

    document
      .querySelector('.js-industryRanking-scrollButton.-left')
      .addEventListener('click', (e) => {
        observeTargetList[leftIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
      });
    document
      .querySelector('.js-industryRanking-scrollButton.-right')
      .addEventListener('click', (e) => {
        observeTargetList[rightIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      });
    document.querySelector('.js-industryRanking-scroll').addEventListener('scroll', (e) => {
      scrollButtonToggle(e.target);
    });
  }

  // メモページのアンカーリンクの重なりの問題を解消
  if (!!document.querySelectorAll('.js-memoAnchor').length) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.zIndex = null;
            entry.target.style.position = null;
            entry.target.style.top = null;
          } else {
            entry.target.style.zIndex = '10000';
            entry.target.style.position = 'sticky';
            entry.target.style.top = '0';
            document.querySelector('.l-header-globalNaviTrigger').classList.remove('is-active');
            document.querySelector('.l-header-globalNavi').classList.remove('is-active');
            document.querySelectorAll('.l-header .js-modalWrap[data-modal]').forEach((modal) => {
              modal.style.display = 'none';
            });
          }
        });
      },
      { rootMargin: '-97px' }
    );
    document.querySelectorAll('.js-memoAnchor').forEach((element) => {
      observer.observe(element);
    });
  }
});
