export const BLOB_URL = (signed_id, filename) => `/rails/active_storage/blobs/${signed_id}/${filename}`

export const USER_STUDENT_PATH = '/person/ajax/student'
export const ENDPOINT = {
  COMPANY: {
    IMAGES_PATH: (num) => `/company/ajax/company/images/${num}`,
    EMPLOYEE_VOICES_PATH: '/company/ajax/employee_voices',
    EMPLOYEE_VOICE_PATH: (id) => `/company/ajax/employee_voices/${id}`,
    IMAGES_PATH: (num) => `/company/ajax/company/images/${num}`,
  },
  USER: {
    SECRET_PATH: (id) => `/person/ajax/user/secrets/${id}`
  }
}
