window.front_varidation = function(selector) {
  if ($(selector).find('input').is(':checkbox')) { //チェックボックスの場合
    var checkname = $(selector).find("input[type='checkbox']").attr('name');
    if ($("input[name='" + checkname + "']:checked").length > 0) { //チェックが1つ以上あったら
      $(selector).find(".error-txt").remove();
      return 0;
    } else {
      $(selector).find(".error-txt").remove();
      $(selector).append("<p class='error-txt'>チェックをつけてください</p>");
      return 1;
    }
  } else {
    var message = $(selector.querySelectorAll(':invalid'));
    if (message[0]) { //エラーがあったら
      message = message[0].validationMessage;
      $(selector).find(".error-txt").remove();
      $(selector).append("<p class='error-txt'>" + message + "</p>");
      return 1;
    } else {
      $(selector).find(".error-txt").remove();
      return 0;
    }
  }
}

window.fetchZipCloud = async (zipCode) => {
  const res = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`);
  const data = await res.json();
  return data;
};
