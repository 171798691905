$(window).on('turbolinks:load', function() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    // PWA時はログインを維持するトグルを常にON
    const keepLogin = document.querySelectorAll('.js-pwaAutoCheck');
    for (const kl of keepLogin) {
      console.log(kl);
      kl.checked = true;
    }

    //PWA初回ログイン時にPUSH通知を知らせるモーダルを出す
    if (location.pathname === '/person/my_page') {
      const m = document.querySelector(
        '.js-modalWrap[data-modal="thankingPWA"]'
      );
      if (localStorage.getItem('thankingPWA') !== 'done' && m) {
        postOfficialMessage();
        $(m).fadeIn(200).addClass('is-active').css('display', 'flex');
        localStorage.setItem('thankingPWA', 'done');
      }
    }

    // PWAでのPull To Refreshの実装
    let startY = null;
    let isPullingDown = false;
    let scrollY = 0;

    window.addEventListener('scroll', () => {
      scrollY = window.scrollY;
    });

    window.addEventListener('touchstart', (e) => {
      // スタートY座標を保存
      startY = e.touches[0].clientY;
    }, false);

    // 既存のコードに以下を追加
    let refreshTriggered = false;

    window.addEventListener('touchmove', (e) => {
      if (!startY) {
        return;
      }
      let currentY = e.touches[0].clientY;
      let distance = currentY - startY;

      // 下にスクロールしているかチェック
      if (distance > 0) {
        isPullingDown = true;
        // 画面上に、100px以上スクロールしたかチェック
        if (distance - scrollY > 100 && !refreshTriggered) {
          // ローダーを表示
          $('#pwaPullToRefreshLoader').fadeIn(300);
          refreshTriggered = true;
        }
      }
    }, false);

    window.addEventListener('touchend', () => {
      if (isPullingDown && refreshTriggered) {
        // ページのリロードを行う
        location.reload();
      } else {
        // ローダーを非表示にする
        $('#pwaPullToRefreshLoader').hide();
        if (refreshTriggered) {
          refreshTriggered = false;
        }
      }
      isPullingDown = false;
      startY = null;
    }, false);
  }

  if ('serviceWorker' in navigator) {
    setAppBadge();

    (async () => {
      try {
        const reg = await navigator.serviceWorker.register('/sw.js');
        if (reg) {
          const webPushToggleBox = document.querySelectorAll(
            '.js-webPushToggle-box'
          );
          if (!!webPushToggleBox.length && (await checkIsWebPushSupported())) {
            await initWebPush();
            for (const wptb of webPushToggleBox) {
              wptb.style.display = 'block';
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }

  const initWebPush = async () => {
    const webPushToggle = document.querySelectorAll('.js-webPushToggle');

    if (!!webPushToggle.length) {
      navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager.getSubscription().then((sub) => {
          if (sub) {
            checkServerSubscription(sub.toJSON()).then((result) => {
              if (result) {
                testButton(true);
                for (const wpt of webPushToggle) {
                  wpt.checked = true;
                }
              } else {
                postSubscription(sub.toJSON()).then((res) => {
                  testButton(true);
                  for (const wpt of webPushToggle) {
                    wpt.checked = true;
                  }
                });
              }
            });
          } else {
            testButton(false);
            for (const wpt of webPushToggle) {
              wpt.checked = false;
            }
          }
        });
      });

      for (const wpt of webPushToggle) {
        wpt.addEventListener('change', (e) => {
          const loadingOvs = document.querySelectorAll(
            '.js-webPushToggle-loading'
          );

          (async (currentTarget) => {
            for (const lov of loadingOvs) {
              lov.style.display = 'flex';
            }

            const reg = await navigator.serviceWorker.ready;
            if (currentTarget.checked) {
              currentTarget.checked = false;
              const allowed = await requestPermission();

              if (allowed) {
                const sub = await reg.pushManager.getSubscription();
                if (sub) {
                  // subscriptionがある場合
                  const res = await postSubscription(sub.toJSON());
                  testButton(true);
                  currentTarget.checked = true;
                } else {
                  // subscriptionがない場合は作成
                  const sub = await createSubscription(reg);
                  if (sub) {
                    const res = await postSubscription(sub.toJSON());
                    testButton(true);
                    currentTarget.checked = true;
                  } else {
                    alert(
                      'push serviceへの登録が失敗しました。\nお使いのブラウザでは利用できない可能性があります。a'
                    );
                  }
                }
              } else {
                testButton(false);
                wpt.checked = false;
              }
            } else {
              currentTarget.checked = true;

              const sub = await reg.pushManager.getSubscription();
              if (sub) {
                const success = await sub.unsubscribe();
                const res = await deleteSubscription(sub.toJSON());
                if (success) {
                  testButton(false);
                  currentTarget.checked = false;
                } else {
                  testButton(true);
                  currentTarget.checked = true;
                }
              }
            }

            for (const lov of loadingOvs) {
              lov.style.display = 'none';
            }
          })(e.currentTarget);
        });
      }
    }
  };
});

const checkIsWebPushSupported = async () => {
  // グローバル空間にNotificationがあればNotification APIに対応しているとみなす
  if (!('Notification' in window)) {
    return false;
  }
  // グローバル変数navigatorにserviceWorkerプロパティがあればサービスワーカーに対応しているとみなす
  if (!('serviceWorker' in navigator)) {
    return false;
  }
  try {
    const sw = await navigator.serviceWorker.ready;
    // 利用可能になったサービスワーカーがpushManagerプロパティがあればPush APIに対応しているとみなす
    if (!('pushManager' in sw)) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const requestPermission = async () => {
  const result = await Notification.requestPermission();
  if (result === 'granted') {
    return true;
  } else if (result === 'denied') {
    alert('アプリの通知を受け取るには、まずは端末の「設定」から通知を許可して、このボタンをONにしてください。');
    return false;
  } else {
    return false;
  }
};

const createSubscription = async (reg) => {
  const vapidPublicKey = await fetchVapidPublicKey();
  const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
  try {
    return await reg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};

const setAppBadge = async () => {
  const dontRunPage = [
    '/person',
    '/person/login',
    '/person/sign_up',
    '/person/terms_of_use',
  ];
  if (navigator.setAppBadge && !dontRunPage.includes(location.pathname)) {
    try {
      const res = await fetch('/api/web_push/get_unread_count');
      if (res.ok) {
        const json = await res.json();
        navigator.setAppBadge(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const urlBase64ToUint8Array = (base64String) => {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const fetchVapidPublicKey = async () => {
  try {
    const res = await fetch('/api/web_push/vapid');
    if (res.ok) {
      return await res.json().then((data) => data.vapid_public_key);
    } else {
      throw new Error(`HTTP ERROR /api/web_push/vapid STATUS: ${res.status}`);
    }
  } catch (error) {}
};

const checkServerSubscription = async (subscriptionJSON) => {
  const res = await fetch('/api/web_push/check_subscription', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      endpoint: subscriptionJSON.endpoint,
    }),
  });

  const json = await res.json();
  return json.result;
};

// サブスクリプションをデータベースに保存
const postSubscription = async (subscriptionJSON) => {
  const _ = await fetch('/api/web_push/subscription', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      endpoint: subscriptionJSON.endpoint,
      expiration_time: (subscriptionJSON.expirationTime !== undefined) ? subscriptionJSON.expirationTime : null,
      keys: {
        p256dh: subscriptionJSON.keys.p256dh,
        auth: subscriptionJSON.keys.auth,
      },
    }),
  });
};

// サブスクリプションをデータベースから削除
const deleteSubscription = async (subscriptionJSON) => {
  const _ = await fetch('/api/web_push/delete_subscription', {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      endpoint: subscriptionJSON.endpoint,
      expiration_time: (subscriptionJSON.expirationTime !== undefined) ? subscriptionJSON.expirationTime : null,
      keys: {
        p256dh: subscriptionJSON.keys.p256dh,
        auth: subscriptionJSON.keys.auth,
      },
    }),
  });
};

const testButton = (bool) => {
  const testModalOpen = document.getElementById('webPushTest-openModal');
  if (!testModalOpen) return;
  if (bool) {
    testModalOpen.style.visibility = 'visible';
  } else {
    testModalOpen.style.visibility = 'hidden';
  }
};

const postOfficialMessage = async () => {
  const _ = await fetch('/api/web_push/thanking_pwa', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
  });
};
