<template>
  <div>
    <div class="addable-set mt10">
      <div class="field" v-for="(workplace, index) in workplaces" :key="workplace[index]">
        <div>
          <label>
            <input type="radio" :name="`workplaces[${index}]place_type`" value="national" @change="changeFields(index, $event)" :checked="workplaces[index].place_type == 'national'">
            <span>勤務地指定あり(国内)</span>
          </label>
        </div>
        <div class="pt10">
          <label>
            <input type="radio" :name="`workplaces[${index}]place_type`" value="foreign" @change="changeFields(index, $event)" :checked="workplaces[index].place_type == 'foreign'">
            <span>勤務地指定あり(海外)</span>
          </label>
        </div>
        <div class="pt10">
          <label>
            <input type="radio" :name="`workplaces[${index}]place_type`" value="remote" @change="changeFields(index, $event)" :checked="workplaces[index].place_type == 'remote'">
            <span>リモート・在宅</span>
          </label>
        </div>

        <div class="mt10" v-if="workplaces[index].place_type == 'national'">
          <div class="d-f">
            <div class="fw-b pt5"><span class="required-tag -must mr5">必須</span>郵便番号</div>
            <div class="ml20">
              <input type="tel" maxlength="7" placeholder="例：8100022" style="width: 200px" :name="`workplaces[${index}]zip_code`" :value="workplaces[index].zip_code" @input="numberOnly($event); fetchZipCloud(index, $event); workplaces[index].zip_code = $event.target.value">
              <p class="error-txt" v-if="errors.zip_code && errors.zip_code[index]">{{errors.zip_code[index]}}</p>
            </div>
          </div>
          <div class="d-f mt10">
            <div class="fw-b pt5"><span class="required-tag -must mr5">必須</span>都道府県</div>
            <div class="ml20">
              <div class="select">
                <select :name="`workplaces[${index}]prefecture_type`" style="width: 200px">
                  <option value="">都道府県</option>
                  <optgroup :label="`${region.name}地方`" v-for="region in areaRegion" :key="region.key">
                    <option
                      v-for="prefecture_id in region.prefecture_ids"
                      :value="prefecture_id"
                      :key="prefecture_id"
                      :selected="prefecture_id == workplaces[index].prefecture_type"
                    >
                      {{areaPrefecture[prefecture_id - 1].full_name}}
                    </option>
                  </optgroup>
                </select>
              </div>
              <p class="error-txt" v-if="errors.prefecture_type && errors.prefecture_type[index]">{{errors.prefecture_type[index]}}</p>
            </div>
          </div>
          <div class="d-f mt10">
            <div class="fw-b pt5"><span class="required-tag -must mr5">必須</span>市区町村</div>
            <div class="ml20 fxg1">
              <input type="text" placeholder="例：福岡市中央区薬院" class="w100" :name="`workplaces[${index}]address`" :value="workplaces[index].address" @input="workplaces[index].address = $event.target.value">
              <p class="error-txt" v-if="errors.address && errors.address[index]">{{errors.address[index]}}</p>
            </div>
          </div>
          <div class="d-f mt10">
            <div class="fw-b pt5"><span class="required-tag -option mr5">任意</span>以降の住所</div>
            <div class="ml20 fxg1">
              <input type="text" placeholder="例：3-16-26 西鉄薬院ビル4F" class="w100" :name="`workplaces[${index}]other`" :value="workplaces[index].other" @input="workplaces[index].other = $event.target.value">
            </div>
          </div>
          <div class="d-f mt10">
            <div class="fw-b pt5"><span class="required-tag -option mr5">任意</span>最寄り駅</div>
            <div class="ml20 fxg1">
              <input type="text" placeholder="例：福岡市地下鉄七隈線 薬院駅" class="w100" :name="`workplaces[${index}]closest_station`" :value="workplaces[index].closest_station" @input="workplaces[index].closest_station = $event.target.value">
            </div>
          </div>
        </div>

        <div class="d-f ai-c mt10" v-if="workplaces[index].place_type == 'foreign' || workplaces[index].place_type == 'remote'">
          <div class="fw-b"><span class="required-tag -option mr5">任意</span>勤務地</div>
          <div class="ml20 fxg1">
            <input type="text" :name="`workplaces[${index}]other`" :value="workplaces[index].other" placeholder="例：フルリモート可です。" class="w100" @input="workplaces[index].other = $event.target.value">
          </div>
        </div>
        <div class="delete-button" @click="onDeleteButtonClick(index)"><span class="cross"></span></div>
      </div>
      <p class="error-txt" v-if="errors.workplaces">{{errors.workplaces}}</p>
      <div class="d-f jc-c mt20">
        <div class="add-button" @click="addEmptyObject"><span class="plus"></span>就業場所を追加</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propAreaRegion: {
      type: Array,
    },
     propAreaPrefecture: {
      type: Array,
    },
    propErrors: {
      type: Object,
    },
    propWorkplaces: {
    }
  },
  data() {
    return {
      areaRegion: [],
      areaPrefecture: [],
      workplaces: [],
      errors: {},
      formatedZipCode: '',
    }
  },
  created() {
    this.areaRegion = this.propAreaRegion;
    this.areaPrefecture = this.propAreaPrefecture;
    this.errors = this.propErrors;
    this.workplaces = this.propWorkplaces;
    if (this.workplaces.length === 0) {
      this.addEmptyObject();
    }
  },
  methods: {
    addEmptyObject() {
      this.workplaces.push(
        {
          place_type: null,
          zip_code: null,
          prefecture_type: null,
          address: null,
          other: null,
        }
      );
    },
    onDeleteButtonClick(index) {
      this.workplaces.splice(index, 1);
    },
    changeFields(index, event) {
      this.workplaces[index].place_type = event.target.value;
    },
    numberOnly(e) {
      const value = e.currentTarget.value.replace(/\D/g, '');
      e.currentTarget.value = value;
    },
    async fetchZipCloud(index, e) {
      if (e.currentTarget.value.length === 7) {
        try {
          const res = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${e.currentTarget.value}`)
          const data = await res.json();
          this.workplaces[index].zip_code = data.results[0].zipcode;
          this.workplaces[index].prefecture_type = data.results[0].prefcode;
          this.workplaces[index].address = `${data.results[0].address2}${data.results[0].address3}`;
        } catch {
          this.workplaces[index].prefecture_type = null;
          this.workplaces[index].address = null;
        }
      }
    }
  }
}
</script>
