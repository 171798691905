export const ACADEMIC_STATUS = {
  admission: '入学',
  graduation: '卒業',
  graduation_prospect: '卒業見込み',
  suspension: '休学・中退',
  transfer: '編入'
}

export const SCHOOL_KIND = {
  high_school: "高校",
  vacational_school: "専門学校",
  university: "大学",
  graduate_school: "大学院",
  junior_college: "短期大学",
  technical_college: "高等専門学校",
  career_school: "キャリアスクール",
}

export const SCHOOL = [
  //短期大学
  "岩手県立大学宮古短期大学部",
  "岩手県立大学盛岡短期大学部",
  "山形県立米沢女子短期大学",
  "会津大学短期大学部",
  "川崎市立看護短期大学",
  "大月短期大学",
  "長野県短期大学",
  "岐阜市立女子短期大学",
  "静岡県立大学短期大学部",
  "静岡県立農林環境専門職大学短期大学部",
  "三重短期大学",
  "島根県立大学短期大学部",
  "倉敷市立短期大学",
  "新見公立短期大学",
  "高知短期大学",
  "大分県立芸術文化短期大学",
  "鹿児島県立短期大学",
  "旭川大学短期大学部",
  "帯広大谷短期大学",
  "釧路短期大学",
  "光塩学園女子短期大学",
  "國學院大學北海道短期大学部",
  "札幌大谷大学短期大学部",
  "札幌国際大学短期大学部",
  "札幌大学女子短期大学部",
  "拓殖大学北海道短期大学",
  "函館大谷短期大学",
  "函館短期大学",
  "北翔大学短期大学部",
  "北星学園大学短期大学部",
  "北海道科学大学短期大学部",
  "北海道武蔵女子短期大学",
  "青森明の星短期大学",
  "青森中央短期大学",
  "八戸学院短期大学",
  "東北女子短期大学",
  "弘前医療福祉大学短期大学部",
  "修紅短期大学",
  "盛岡大学短期大学部",
  "聖和学園短期大学",
  "仙台青葉学院短期大学",
  "仙台赤門短期大学",
  "東北生活文化大学短期大学部",
  "宮城誠真短期大学",
  "秋田栄養短期大学",
  "聖霊女子短期大学",
  "日本赤十字秋田短期大学",
  "聖園学園短期大学",
  "羽陽学園短期大学",
  "東北文教大学短期大学部",
  "いわき短期大学",
  "郡山女子大学短期大学部",
  "桜の聖母短期大学",
  "福島学院大学短期大学部",
  "茨城女子短期大学",
  "つくば国際短期大学",
  "常磐短期大学",
  "足利短期大学",
  "宇都宮短期大学",
  "宇都宮文星短期大学",
  "國學院大學栃木短期大学",
  "作新学院大学女子短期大学部",
  "佐野日本大学短期大学",
  "育英短期大学",
  "関東短期大学",
  "桐生大学短期大学部",
  "群馬医療福祉大学短期大学部",
  "高崎商科大学短期大学部",
  "東京福祉大学短期大学部",
  "新島学園短期大学",
  "明和学園短期大学",
  "秋草学園短期大学",
  "川口短期大学",
  "浦和大学短期大学部",
  "国際学院埼玉短期大学",
  "埼玉医科大学短期大学",
  "埼玉純真短期大学",
  "埼玉女子短期大学",
  "埼玉東萌短期大学",
  "城西短期大学",
  "武蔵丘短期大学",
  "武蔵野短期大学",
  "山村学園短期大学",
  "植草学園短期大学",
  "昭和学院短期大学",
  "聖徳大学短期大学部",
  "千葉敬愛短期大学",
  "千葉経済大学短期大学部",
  "千葉明徳短期大学",
  "東京経営短期大学",
  "清和大学短期大学部",
  "愛国学園短期大学",
  "青山学院女子短期大学",
  "有明教育芸術短期大学",
  "上野学園大学短期大学部",
  "大妻女子大学短期大学部",
  "共立女子短期大学",
  "国際短期大学",
  "駒沢女子短期大学",
  "自由が丘産能短期大学",
  "実践女子大学短期大学部",
  "淑徳大学短期大学部",
  "女子栄養大学短期大学部",
  "女子美術大学短期大学部",
  "白梅学園短期大学",
  "杉野服飾大学短期大学部",
  "星美学園短期大学",
  "創価女子短期大学",
  "鶴川女子短期大学",
  "帝京大学短期大学",
  "帝京短期大学",
  "貞静学園短期大学",
  "戸板女子短期大学",
  "東京家政大学短期大学部",
  "東京交通短期大学",
  "東京歯科大学短期大学",
  "東京女子体育短期大学",
  "東京成徳短期大学",
  "東京農業大学短期大学部",
  "東京立正短期大学",
  "東邦音楽短期大学",
  "桐朋学園芸術短期大学",
  "新渡戸文化短期大学",
  "日本歯科大学東京短期大学",
  "日本大学短期大学部",
  "文化学園大学短期大学部",
  "目白大学短期大学部",
  "ヤマザキ動物看護専門職短期大学",
  "山野美容芸術短期大学",
  "立教女学院短期大学",
  "和泉短期大学",
  "小田原短期大学",
  "鎌倉女子大学短期大学部",
  "カリタス女子短期大学",
  "相模女子大学短期大学部",
  "上智大学短期大学部",
  "神奈川歯科大学短期大学部",
  "湘北短期大学",
  "昭和音楽大学短期大学部",
  "聖セシリア女子短期大学",
  "洗足こども短期大学",
  "鶴見大学短期大学部",
  "東海大学医療技術短期大学",
  "横浜女子短期大学",
  "新潟工業短期大学",
  "新潟青陵大学短期大学部",
  "新潟中央短期大学",
  "日本歯科大学新潟短期大学",
  "明倫短期大学",
  "富山短期大学",
  "富山福祉短期大学",
  "金沢学院短期大学",
  "金城大学短期大学部",
  "小松短期大学",
  "金沢星稜大学女子短期大学部",
  "北陸学院大学短期大学部",
  "仁愛女子短期大学",
  "帝京学園短期大学",
  "山梨学院短期大学",
  "飯田女子短期大学",
  "上田女子短期大学",
  "佐久大学信州短期大学部",
  "信州豊南短期大学",
  "清泉女学院短期大学",
  "長野女子短期大学",
  "松本大学松商短期大学部",
  "松本短期大学",
  "大垣女子短期大学",
  "岐阜聖徳学園大学短期大学部",
  "岐阜保健短期大学",
  "正眼短期大学",
  "高山自動車短期大学",
  "中京学院大学中京短期大学部",
  "中部学院大学短期大学部",
  "東海学院大学短期大学部",
  "中日本自動車短期大学",
  "平成医療短期大学",
  "静岡英和学院大学短期大学部",
  "東海大学短期大学部",
  "常葉大学短期大学部",
  "浜松学院大学短期大学部",
  "愛知医療学院短期大学",
  "愛知きわみ看護短期大学",
  "愛知学院大学短期大学部",
  "愛知学泉短期大学",
  "愛知工科大学自動車短期大学",
  "愛知江南短期大学",
  "愛知産業大学短期大学",
  "愛知大学短期大学部",
  "愛知文教女子短期大学",
  "愛知みずほ大学短期大学部",
  "岡崎女子短期大学",
  "至学館大学短期大学部",
  "修文大学短期大学部",
  "豊橋創造大学短期大学部",
  "名古屋学芸大学短期大学部",
  "名古屋経営短期大学",
  "名古屋女子大学短期大学部",
  "名古屋短期大学",
  "名古屋文化短期大学",
  "名古屋文理大学短期大学部",
  "名古屋柳城短期大学",
  "鈴鹿大学短期大学部",
  "高田短期大学",
  "ユマニテク短期大学",
  "滋賀短期大学",
  "びわこ学院大学短期大学部",
  "滋賀文教短期大学",
  "池坊短期大学",
  "大谷大学短期大学部",
  "華頂短期大学",
  "京都外国語短期大学",
  "京都経済短期大学",
  "京都文教短期大学",
  "京都光華女子大学短期大学部",
  "嵯峨美術短期大学[1]",
  "京都西山短期大学",
  "龍谷大学短期大学部",
  "藍野大学短期大学部",
  "大阪青山短期大学",
  "大阪音楽大学短期大学部",
  "大阪学院大学短期大学部",
  "大阪キリスト教短期大学",
  "大阪芸術大学短期大学部",
  "大阪健康福祉短期大学",
  "大阪国際大学短期大学部",
  "大阪産業大学短期大学部",
  "大阪城南女子短期大学",
  "大阪女学院短期大学",
  "大阪成蹊短期大学",
  "大阪夕陽丘学園短期大学",
  "大阪女子短期大学",
  "大阪信愛女学院短期大学",
  "大阪千代田短期大学",
  "関西外国語大学短期大学部",
  "関西女子短期大学",
  "近畿大学短期大学部",
  "堺女子短期大学",
  "四條畷学園短期大学",
  "四天王寺大学短期大学部",
  "樟蔭東短期大学",
  "常磐会短期大学",
  "東大阪大学短期大学部",
  "プール学院短期大学",
  "平安女学院大学短期大学部",
  "芦屋学園短期大学",
  "大手前短期大学",
  "豊岡短期大学",
  "甲子園短期大学",
  "神戸女子短期大学",
  "神戸常盤大学短期大学部",
  "神戸山手短期大学",
  "産業技術短期大学",
  "夙川学院短期大学",
  "頌栄短期大学",
  "聖和短期大学",
  "園田学園女子大学短期大学部",
  "東洋食品工業短期大学",
  "姫路日ノ本短期大学",
  "兵庫大学短期大学部",
  "湊川短期大学",
  "武庫川女子大学短期大学部",
  "奈良芸術短期大学",
  "奈良佐保短期大学",
  "奈良学園大学奈良文化女子短期大学部",
  "白鳳短期大学",
  "和歌山信愛女子短期大学",
  "鳥取短期大学",
  "岡山短期大学",
  "川崎医療短期大学",
  "作陽音楽短期大学",
  "山陽学園短期大学",
  "就実短期大学",
  "吉備国際大学短期大学部",
  "中国短期大学",
  "美作大学短期大学部",
  "山陽女子短期大学",
  "鈴峯女子短期大学",
  "比治山大学短期大学部",
  "広島国際学院大学自動車短期大学部",
  "広島文化学園短期大学",
  "安田女子短期大学",
  "岩国短期大学",
  "宇部フロンティア大学短期大学部",
  "下関短期大学",
  "山口芸術短期大学",
  "山口短期大学",
  "四国大学短期大学部",
  "徳島工業短期大学",
  "徳島文理大学短期大学部",
  "香川短期大学",
  "高松短期大学",
  "今治明徳短期大学",
  "環太平洋大学短期大学部",
  "聖カタリナ大学短期大学部",
  "松山東雲短期大学",
  "松山短期大学",
  "高知学園短期大学",
  "折尾愛真短期大学",
  "九州大谷短期大学",
  "九州女子短期大学",
  "九州造形短期大学",
  "近畿大学九州短期大学",
  "久留米信愛女学院短期大学",
  "香蘭女子短期大学",
  "純真短期大学",
  "精華女子短期大学",
  "西南女学院大学短期大学部",
  "筑紫女学園大学短期大学部",
  "東海大学福岡短期大学",
  "中村学園大学短期大学部",
  "西日本短期大学",
  "東筑紫短期大学",
  "福岡医療短期大学",
  "福岡工業大学短期大学部",
  "福岡こども短期大学",
  "福岡女学院大学短期大学部",
  "福岡女子短期大学",
  "九州龍谷短期大学",
  "佐賀女子短期大学",
  "西九州大学短期大学部",
  "長崎女子短期大学",
  "長崎短期大学",
  "尚絅大学短期大学部",
  "中九州短期大学",
  "大分短期大学",
  "東九州短期大学",
  "別府大学短期大学部",
  "別府溝部学園短期大学",
  "南九州短期大学",
  "宮崎学園短期大学",
  "鹿児島純心女子短期大学",
  "鹿児島女子短期大学",
  "第一幼児教育短期大学",
  "沖縄キリスト教短期大学",
  "沖縄女子短期大学",
  //専門学校
  "函館工業高等専門学校",
  "苫小牧工業高等専門学校",
  "釧路工業高等専門学校",
  "旭川工業高等専門学校",
  "八戸工業高等専門学校",
  "一関工業高等専門学校",
  "仙台高等専門学校",
  "秋田工業高等専門学校",
  "鶴岡工業高等専門学校",
  "福島工業高等専門学校",
  "茨城工業高等専門学校",
  "小山工業高等専門学校",
  "群馬工業高等専門学校",
  "木更津工業高等専門学校",
  "東京工業高等専門学校",
  "東京都立産業技術高等専門学校",
  "サレジオ工業高等専門学校",
  "長岡工業高等専門学校",
  "富山高等専門学校",
  "石川工業高等専門学校",
  "国際高等専門学校",
  "福井工業高等専門学校",
  "長野工業高等専門学校",
  "岐阜工業高等専門学校",
  "沼津工業高等専門学校",
  "豊田工業高等専門学校",
  "鳥羽商船高等専門学校",
  "鈴鹿工業高等専門学校",
  "近畿大学工業高等専門学校",
  "舞鶴工業高等専門学校",
  "大阪府立大学工業高等専門学校",
  "明石工業高等専門学校",
  "神戸市立工業高等専門学校",
  "奈良工業高等専門学校",
  "和歌山工業高等専門学校",
  "米子工業高等専門学校",
  "松江工業高等専門学校",
  "津山工業高等専門学校",
  "広島商船高等専門学校",
  "呉工業高等専門学校",
  "徳山工業高等専門学校",
  "宇部工業高等専門学校",
  "大島商船高等専門学校",
  "阿南工業高等専門学校",
  "香川高等専門学校",
  "新居浜工業高等専門学校",
  "弓削商船高等専門学校",
  "高知工業高等専門学校",
  "北九州工業高等専門学校",
  "久留米工業高等専門学校",
  "有明工業高等専門学校",
  "佐世保工業高等専門学校",
  "熊本高等専門学校",
  "大分工業高等専門学校",
  "都城工業高等専門学校",
  "鹿児島工業高等専門学校",
  "沖縄工業高等専門学校",
  //大学
  "愛国学園大学",
  "愛知大学",
  "愛知医科大学",
  "愛知学院大学",
  "愛知学泉大学",
  "愛知教育大学",
  "愛知県立大学",
  "愛知県立芸術大学",
  "愛知工科大学",
  "愛知工業大学",
  "愛知産業大学",
  "愛知淑徳大学",
  "愛知東邦大学",
  "愛知文教大学",
  "愛知みずほ大学",
  "会津大学",
  "藍野大学",
  "青森大学",
  "青森県立保健大学",
  "青森公立大学",
  "青森中央学院大学",
  "青山学院大学",
  "秋田大学",
  "秋田看護福祉大学",
  "秋田県立大学",
  "秋田公立美術大学",
  "朝日大学",
  "旭川大学",
  "旭川医科大学",
  "麻布大学",
  "亜細亜大学",
  "足利大学",
  "芦屋大学",
  "跡見学園女子大学",
  "育英大学",
  "石川県立大学",
  "石川県立看護大学",
  "石巻専修大学",
  "一宮研伸大学",
  "茨城大学",
  "茨城キリスト教大学",
  "茨城県立医療大学",
  "医療創生大学",
  "岩手大学",
  "岩手医科大学",
  "岩手県立大学",
  "岩手保健医療大学",
  "植草学園大学",
  "上野学園大学",
  "宇都宮大学",
  "宇都宮共和大学",
  "宇部フロンティア大学",
  "浦和大学",
  "SBI大学院大学",
  "江戸川大学",
  "愛媛大学",
  "愛媛県立医療技術大学",
  "エリザベト音楽大学",
  "奥羽大学",
  "桜花学園大学",
  "追手門学院大学",
  "桜美林大学",
  "大分大学",
  "大分県立看護科学大学",
  "大阪大学",
  "大阪青山大学",
  "大阪医科大学",
  "大阪大谷大学",
  "大阪音楽大学",
  "大阪学院大学",
  "大阪河崎リハビリテーション大学",
  "大阪観光大学",
  "大阪教育大学",
  "大阪経済大学",
  "大阪経済法科大学",
  "大阪芸術大学",
  "大阪工業大学",
  "大阪国際大学",
  "大阪産業大学",
  "大阪歯科大学",
  "大阪樟蔭女子大学",
  "大阪商業大学",
  "大阪女学院大学",
  "大阪市立大学",
  "大阪成蹊大学",
  "大阪総合保育大学",
  "大阪体育大学",
  "大阪電気通信大学",
  "大阪人間科学大学",
  "大阪府立大学",
  "大阪保健医療大学",
  "大阪物療大学",
  "大阪薬科大学",
  "大阪行岡医療大学",
  "大谷大学",
  "大妻女子大学",
  "大手前大学",
  "大原大学院大学",
  "岡崎女子大学",
  "岡山大学",
  "岡山学院大学",
  "岡山県立大学",
  "岡山商科大学",
  "岡山理科大学",
  "沖縄大学",
  "沖縄キリスト教学院大学",
  "沖縄県立看護大学",
  "沖縄県立芸術大学",
  "沖縄国際大学",
  "沖縄科学技術大学院大学",
  "小樽商科大学",
  "お茶の水女子大学",
  "尾道市立大学",
  "帯広畜産大学",
  "開智国際大学",
  "嘉悦大学",
  "香川大学",
  "香川県立保健医療大学",
  "学習院大学",
  "学習院女子大学",
  "鹿児島大学",
  "鹿児島国際大学",
  "鹿児島純心女子大学",
  "活水女子大学",
  "神奈川大学",
  "神奈川県立保健福祉大学",
  "神奈川工科大学",
  "神奈川歯科大学",
  "金沢大学",
  "金沢医科大学",
  "金沢学院大学",
  "金沢工業大学",
  "金沢星稜大学",
  "金沢美術工芸大学",
  "鹿屋体育大学",
  "鎌倉女子大学",
  "亀田医療大学",
  "川崎医科大学",
  "川崎医療福祉大学",
  "川村学園女子大学",
  "関西大学",
  "関西医科大学",
  "関西医療大学",
  "関西外国語大学",
  "関西看護医療大学",
  "関西国際大学",
  "関西福祉大学",
  "関西福祉科学大学",
  "関西学院大学",
  "環太平洋大学",
  "神田外語大学",
  "関東学院大学",
  "関東学園大学",
  "畿央大学",
  "北九州市立大学",
  "北里大学",
  "北見工業大学",
  "吉備国際大学",
  "岐阜大学",
  "岐阜医療科学大学",
  "岐阜協立大学",
  "岐阜県立看護大学",
  "岐阜聖徳学園大学",
  "岐阜女子大学",
  "岐阜保健大学",
  "岐阜薬科大学",
  "九州大学",
  "九州栄養福祉大学",
  "九州看護福祉大学",
  "九州共立大学",
  "九州工業大学",
  "九州国際大学",
  "九州産業大学",
  "九州歯科大学",
  "九州情報大学",
  "九州女子大学",
  "九州保健福祉大学",
  "九州ルーテル学院大学",
  "共愛学園前橋国際大学",
  "共栄大学",
  "京都大学",
  "京都医療科学大学",
  "京都外国語大学",
  "京都華頂大学",
  "京都看護大学",
  "京都教育大学",
  "京都光華女子大学",
  "京都工芸繊維大学",
  "京都先端科学大学",
  "京都産業大学",
  "京都情報大学院大学",
  "京都女子大学",
  "京都市立芸術大学",
  "京都精華大学",
  "京都造形芸術大学",
  "京都橘大学",
  "京都ノートルダム女子大学",
  "京都美術工芸大学",
  "京都府立大学",
  "京都府立医科大学",
  "京都文教大学",
  "京都薬科大学",
  "共立女子大学",
  "杏林大学",
  "桐生大学",
  "近畿大学",
  "金城大学",
  "金城学院大学",
  "釧路公立大学",
  "国立音楽大学",
  "熊本大学",
  "熊本学園大学",
  "熊本県立大学",
  "熊本保健科学大学",
  "倉敷芸術科学大学",
  "くらしき作陽大学",
  "久留米大学",
  "久留米工業大学",
  "グロービス経営大学院大学",
  "群馬大学",
  "群馬医療福祉大学",
  "群馬県立県民健康科学大学",
  "群馬県立女子大学",
  "群馬パース大学",
  "敬愛大学",
  "慶應義塾大学",
  "恵泉女学園大学",
  "敬和学園大学",
  "健康科学大学",
  "県立広島大学",
  "工学院大学",
  "皇學館大学",
  "甲子園大学",
  "高知大学",
  "高知県立大学",
  "高知工科大学",
  "高知リハビリテーション専門職大学",
  "甲南大学",
  "甲南女子大学",
  "神戸大学",
  "神戸医療福祉大学",
  "神戸海星女子学院大学",
  "神戸学院大学",
  "神戸芸術工科大学",
  "神戸国際大学",
  "神戸市外国語大学",
  "神戸市看護大学",
  "神戸松蔭女子学院大学",
  "神戸情報大学院大学",
  "神戸女学院大学",
  "神戸女子大学",
  "神戸親和女子大学",
  "神戸常盤大学",
  "神戸薬科大学",
  "神戸山手大学",
  "高野山大学",
  "公立小松大学",
  "公立諏訪東京理科大学",
  "公立千歳科学技術大学",
  "公立鳥取環境大学",
  "公立はこだて未来大学",
  "郡山女子大学",
  "國學院大學",
  "国際大学",
  "国際医療福祉大学",
  "国際教養大学",
  "国際基督教大学",
  "国際ファッション専門職大学",
  "国際仏教学大学院大学",
  "国際武道大学",
  "国士舘大学",
  "こども教育宝仙大学",
  "駒澤大学",
  "駒沢女子大学",
  "埼玉大学",
  "埼玉医科大学",
  "埼玉学園大学",
  "埼玉県立大学",
  "埼玉工業大学",
  "サイバー大学",
  "佐賀大学",
  "嵯峨美術大学",
  "相模女子大学",
  "佐久大学",
  "作新学院大学",
  "札幌大学",
  "札幌医科大学",
  "札幌大谷大学",
  "札幌学院大学",
  "札幌国際大学",
  "札幌市立大学",
  "札幌保健医療大学",
  "三育学院大学",
  "産業医科大学",
  "産業技術大学院大学",
  "産業能率大学",
  "山陽小野田市立山口東京理科大学",
  "山陽学園大学",
  "滋賀大学",
  "滋賀医科大学",
  "志學館大学",
  "至学館大学",
  "滋賀県立大学",
  "事業創造大学院大学",
  "事業構想大学院大学",
  "滋慶医療科学大学院大学",
  "四国大学",
  "四国学院大学",
  "四條畷学園大学",
  "静岡大学",
  "静岡英和学院大学",
  "静岡県立大学",
  "静岡県立農林環境専門職大学",
  "静岡産業大学",
  "静岡福祉大学",
  "静岡文化芸術大学",
  "静岡理工科大学",
  "自治医科大学",
  "実践女子大学",
  "四天王寺大学",
  "芝浦工業大学",
  "島根大学",
  "島根県立大学",
  "下関市立大学",
  "社会情報大学院大学",
  "就実大学",
  "修文大学",
  "秀明大学",
  "十文字学園女子大学",
  "淑徳大学",
  "種智院大学",
  "首都大学東京",
  "純真学園大学",
  "順天堂大学",
  "松蔭大学",
  "上越教育大学",
  "尚絅大学",
  "尚絅学院大学",
  "城西大学",
  "城西国際大学",
  "上智大学",
  "湘南医療大学",
  "湘南鎌倉医療大学",
  "湘南工科大学",
  "尚美学園大学",
  "上武大学",
  "情報科学芸術大学院大学",
  "情報セキュリティ大学院大学",
  "昭和大学",
  "昭和音楽大学",
  "昭和女子大学",
  "昭和薬科大学",
  "女子栄養大学",
  "女子美術大学",
  "白梅学園大学",
  "白百合女子大学",
  "仁愛大学",
  "信州大学",
  "杉野服飾大学",
  "椙山女学園大学",
  "鈴鹿大学",
  "鈴鹿医療科学大学",
  "駿河台大学",
  "成安造形大学",
  "聖学院大学",
  "聖カタリナ大学",
  "成蹊大学",
  "星槎大学",
  "政策研究大学院大学",
  "星槎道都大学",
  "成城大学",
  "星城大学",
  "聖心女子大学",
  "聖泉大学",
  "清泉女学院大学",
  "清泉女子大学",
  "聖徳大学",
  "西南学院大学",
  "西南女学院大学",
  "西武文理大学",
  "聖マリア学院大学",
  "聖マリアンナ医科大学",
  "聖隷クリストファー大学",
  "聖路加国際大学",
  "清和大学",
  "摂南大学",
  "専修大学",
  "洗足学園音楽大学",
  "仙台大学",
  "仙台白百合女子大学",
  "千里金蘭大学",
  "相愛大学",
  "創価大学",
  "総合研究大学院大学",
  "崇城大学",
  "園田学園女子大学",
  "第一工業大学",
  "第一薬科大学",
  "大学院大学至善館",
  "大正大学",
  "太成学院大学",
  "大同大学",
  "大東文化大学",
  "高岡法科大学",
  "高崎経済大学",
  "高崎健康福祉大学",
  "高崎商科大学",
  "高千穂大学",
  "高松大学",
  "宝塚大学",
  "宝塚医療大学",
  "拓殖大学",
  "多摩大学",
  "玉川大学",
  "多摩美術大学",
  "筑紫女学園大学",
  "千葉大学",
  "千葉科学大学",
  "千葉経済大学",
  "千葉県立保健医療大学",
  "千葉工業大学",
  "千葉商科大学",
  "中央大学",
  "中央学院大学",
  "中京大学",
  "中京学院大学",
  "中国学園大学",
  "中部大学",
  "中部学院大学",
  "筑波大学",
  "筑波学院大学",
  "筑波技術大学",
  "つくば国際大学",
  "津田塾大学",
  "敦賀市立看護大学",
  "都留文科大学",
  "鶴見大学",
  "帝京大学",
  "帝京科学大学",
  "帝京平成大学",
  "デジタルハリウッド大学",
  "帝塚山大学",
  "帝塚山学院大学",
  "田園調布学園大学",
  "電気通信大学",
  "天使大学",
  "天理大学",
  "天理医療大学",
  "東亜大学",
  "桐蔭横浜大学",
  "東海大学",
  "東海学園大学",
  "東海学院大学",
  "東京大学",
  "東京有明医療大学",
  "東京医科大学",
  "東京医科歯科大学",
  "東京医療学院大学",
  "東京医療保健大学",
  "東京音楽大学",
  "東京外国語大学",
  "東京海洋大学",
  "東京学芸大学",
  "東京家政大学",
  "東京家政学院大学",
  "東京基督教大学",
  "東京経済大学",
  "東京芸術大学",
  "東京工科大学",
  "東京工業大学",
  "東京工芸大学",
  "東京国際大学",
  "東京国際工科専門職大学",
  "東京歯科大学",
  "東京慈恵会医科大学",
  "東京純心大学",
  "東京聖栄大学",
  "東京情報大学",
  "東京女子大学",
  "東京女子医科大学",
  "東京女子体育大学",
  "東京神学大学",
  "東京成徳大学",
  "東京造形大学",
  "東京通信大学",
  "東京電機大学",
  "東京都市大学",
  "東京農業大学",
  "東京農工大学",
  "東京福祉大学",
  "東京富士大学",
  "東京未来大学",
  "東京薬科大学",
  "東京理科大学",
  "同志社大学",
  "同志社女子大学",
  "東都大学",
  "東邦大学",
  "同朋大学",
  "東邦音楽大学",
  "桐朋学園大学",
  "桐朋学園大学院大学",
  "東北大学",
  "東北医科薬科大学",
  "東北学院大学",
  "東北芸術工科大学",
  "東北公益文科大学",
  "東北工業大学",
  "東北女子大学",
  "東北生活文化大学",
  "東北福祉大学",
  "東北文化学園大学",
  "東北文教大学",
  "東洋大学",
  "東洋英和女学院大学",
  "東洋学園大学",
  "常磐大学",
  "常磐会学園大学",
  "徳島大学",
  "徳島文理大学",
  "徳山大学",
  "常葉大学",
  "獨協大学",
  "獨協医科大学",
  "鳥取大学",
  "鳥取看護大学",
  "苫小牧駒澤大学",
  "富山大学",
  "富山県立大学",
  "富山国際大学",
  "豊田工業大学",
  "豊橋技術科学大学",
  "豊橋創造大学",
  "長岡大学",
  "長岡技術科学大学",
  "長岡崇徳大学",
  "長岡造形大学",
  "長崎大学",
  "長崎ウエスレヤン大学",
  "長崎外国語大学",
  "長崎県立大学",
  "長崎国際大学",
  "長崎純心大学",
  "長崎総合科学大学",
  "長野大学",
  "長野県看護大学",
  "長野県立大学",
  "長野保健医療大学",
  "長浜バイオ大学",
  "中村学園大学",
  "名古屋大学",
  "名古屋音楽大学",
  "名古屋外国語大学",
  "名古屋学院大学",
  "名古屋学芸大学",
  "名古屋経済大学",
  "名古屋芸術大学",
  "名古屋工業大学",
  "名古屋産業大学",
  "名古屋商科大学",
  "名古屋女子大学",
  "名古屋市立大学",
  "名古屋造形大学",
  "名古屋文理大学",
  "名古屋柳城女子大学",
  "名寄市立大学",
  "奈良大学",
  "奈良学園大学",
  "奈良教育大学",
  "奈良県立大学",
  "奈良県立医科大学",
  "奈良女子大学",
  "奈良先端科学技術大学院大学",
  "鳴門教育大学",
  "南山大学",
  "新潟大学",
  "新潟医療福祉大学",
  "新潟経営大学",
  "新潟県立大学",
  "新潟県立看護大学",
  "新潟工科大学",
  "新潟国際情報大学",
  "新潟産業大学",
  "新潟食料農業大学",
  "新潟青陵大学",
  "新潟薬科大学",
  "新潟リハビリテーション大学",
  "新見公立大学",
  "西九州大学",
  "西日本工業大学",
  "二松學舍大学",
  "日本大学",
  "日本医科大学",
  "日本医療大学",
  "日本医療科学大学",
  "日本ウェルネススポーツ大学",
  "日本映画大学",
  "日本経済大学",
  "日本工業大学",
  "日本歯科大学",
  "日本社会事業大学",
  "日本獣医生命科学大学",
  "日本女子大学",
  "日本女子体育大学",
  "日本赤十字看護大学",
  "日本赤十字秋田看護大学",
  "日本赤十字九州国際看護大学",
  "日本赤十字豊田看護大学",
  "日本赤十字広島看護大学",
  "日本赤十字北海道看護大学",
  "日本体育大学",
  "日本福祉大学",
  "日本文化大学",
  "日本文理大学",
  "日本保健医療大学",
  "日本薬科大学",
  "人間環境大学",
  "人間総合科学大学",
  "ノースアジア大学",
  "ノートルダム清心女子大学",
  "梅花女子大学",
  "梅光学院大学",
  "白鴎大学",
  "函館大学",
  "羽衣国際大学",
  "八戸学院大学",
  "八戸工業大学",
  "花園大学",
  "浜松医科大学",
  "浜松学院大学",
  "ハリウッド大学院大学",
  "阪南大学",
  "東大阪大学",
  "東日本国際大学",
  "光産業創成大学院大学",
  "ビジネス・ブレークスルー大学",
  "比治山大学",
  "一橋大学",
  "姫路大学",
  "姫路獨協大学",
  "兵庫大学",
  "兵庫医科大学",
  "兵庫医療大学",
  "兵庫教育大学",
  "兵庫県立大学",
  "弘前大学",
  "弘前医療福祉大学",
  "弘前学院大学",
  "広島大学",
  "広島経済大学",
  "広島工業大学",
  "広島国際大学",
  "広島国際学院大学",
  "広島修道大学",
  "広島女学院大学",
  "広島市立大学",
  "広島都市学園大学",
  "広島文化学園大学",
  "広島文教大学",
  "びわこ学院大学",
  "びわこ成蹊スポーツ大学",
  "びわこリハビリテーション専門職大学",
  "フェリス女学院大学",
  "福井大学",
  "福井医療大学",
  "福井県立大学",
  "福井工業大学",
  "福岡大学",
  "福岡看護大学",
  "福岡教育大学",
  "福岡県立大学",
  "福岡工業大学",
  "福岡国際医療福祉大学",
  "福岡歯科大学",
  "福岡女学院大学",
  "福岡女学院看護大学",
  "福岡女子大学",
  "福島大学",
  "福島学院大学",
  "福島県立医科大学",
  "福知山公立大学",
  "福山大学",
  "福山市立大学",
  "福山平成大学",
  "富士大学",
  "藤女子大学",
  "藤田医科大学",
  "佛教大学",
  "文化学園大学",
  "文化ファッション大学院大学",
  "文教大学",
  "文京学院大学",
  "文星芸術大学",
  "平安女学院大学",
  "平成音楽大学",
  "平成国際大学",
  "別府大学",
  "法政大学",
  "放送大学",
  "北翔大学",
  "北星学園大学",
  "北陸大学",
  "北陸学院大学",
  "北陸先端科学技術大学院大学",
  "保健医療経営大学",
  "星薬科大学",
  "北海学園大学",
  "北海商科大学",
  "北海道大学",
  "北海道医療大学",
  "北海道科学大学",
  "北海道教育大学",
  "北海道情報大学",
  "北海道千歳リハビリテーション大学",
  "北海道文教大学",
  "前橋工科大学",
  "松本大学",
  "松本歯科大学",
  "松山大学",
  "松山東雲女子大学",
  "三重大学",
  "三重県立看護大学",
  "南九州大学",
  "身延山大学",
  "美作大学",
  "宮城大学",
  "宮城学院女子大学",
  "宮城教育大学",
  "宮崎大学",
  "宮崎県立看護大学",
  "宮崎公立大学",
  "宮崎国際大学",
  "宮崎産業経営大学",
  "武庫川女子大学",
  "武蔵大学",
  "武蔵野大学",
  "武蔵野音楽大学",
  "武蔵野学院大学",
  "武蔵野美術大学",
  "室蘭工業大学",
  "名桜大学",
  "明海大学",
  "明治大学",
  "明治学院大学",
  "明治国際医療大学",
  "明治薬科大学",
  "名城大学",
  "明星大学",
  "目白大学",
  "ものつくり大学",
  "桃山学院大学",
  "桃山学院教育大学",
  "盛岡大学",
  "森ノ宮医療大学",
  "八洲学園大学",
  "安田女子大学",
  "山形大学",
  "山形県立保健医療大学",
  "山形県立米沢栄養大学",
  "山口大学",
  "山口学芸大学",
  "山口県立大学",
  "山口福祉文化大学",
  "ヤマザキ動物看護大学",
  "大和大学",
  "山梨大学",
  "山梨英和大学",
  "山梨学院大学",
  "山梨県立大学",
  "横浜国立大学",
  "横浜商科大学",
  "横浜市立大学",
  "横浜創英大学",
  "横浜美術大学",
  "横浜薬科大学",
  "四日市大学",
  "四日市看護医療大学",
  "酪農学園大学",
  "立教大学",
  "立正大学",
  "立命館大学",
  "立命館アジア太平洋大学",
  "琉球大学",
  "龍谷大学",
  "流通科学大学",
  "流通経済大学",
  "了徳寺大学",
  "ルーテル学院大学",
  "麗澤大学",
  "LEC東京リーガルマインド大学院大学",
  "和歌山大学",
  "和歌山県立医科大学",
  "和歌山信愛大学",
  "和光大学",
  "早稲田大学",
  "稚内北星学園大学",
  "和洋女子大学",
]

export const FACULTY = [
  "医学部",
  "赤坂心理・医療福祉マネジメント学部",
  "医療栄養学部",
  "医療衛生学部",
  "医療科学部",
  "医療学部",
  "医療看護学部",
  "医療技術学部",
  "医療健康学部",
  "医療健康科学部",
  "医療情報学部",
  "医療福祉学部",
  "医療福祉マネジメント学部",
  "医療保健学部",
  "小田原保健医療学部",
  "看護医療学部",
  "看護栄養学部",
  "看護学部",
  "看護福祉学部",
  "看護福祉心理学部",
  "看護リハビリテーション学部",
  "健康医療科学部",
  "健康栄養学部",
  "健康科学部",
  "健康学部",
  "健康管理学部",
  "健康発達学部",
  "健康福祉学部",
  "健康プロデュース学部",
  "健康メディカル学部",
  "産業保健学部",
  "診療放射線学部",
  "総合リハビリテーション学部",
  "地域医療学部",
  "千葉看護学部",
  "人間看護学部",
  "東が丘・立川看護学部",
  "ヒューマンケア学部",
  "福岡医療技術学部",
  "福岡看護学部",
  "福岡保健医療学部",
  "福岡医療技術学部",
  "保健学部",
  "保健科学部",
  "保健医療学部",
  "保健医療技術学部",
  "保健衛生学部",
  "保健看護学部",
  "幕張ヒューマンケア学部",
  "松山看護学部",
  "リハビリテーション科学部",
  "リハビリテーション学部",
  "和歌山看護学部",
  "栄養科学部",
  "栄養学部",
  "家政学部",
  "管理栄養学部",
  "健康生活学部",
  "現代家政学部",
  "現代生活学部",
  "子ども生活学部",
  "食環境科学部",
  "食産業学部",
  "食品栄養科学部",
  "食文化学部",
  "食マネジメント学部",
  "食物栄養科学部",
  "食物栄養学部",
  "食料産業学部",
  "生活科学部",
  "生活環境学部",
  "生活創造学部",
  "生活福祉文化学部",
  "繊維学部",
  "総合家政学部",
  "都市生活学部",
  "人間栄養学部",
  "人間栄養学部",
  "人間健康学部",
  "人間生活科学部",
  "人間生活学部",
  "英語キャリア学部",
  "英語情報マネジメント学部",
  "外国語学部",
  "現代中国学部",
  "国際英語学部",
  "国際・英語学部",
  "国際言語学部",
  "国際言語文化学部",
  "環境ツーリズム学部",
  "観光学部",
  "観光産業科学部",
  "観光コミュニティ学部",
  "観光ビジネス学部",
  "観光文化学部",
  "観光メディア文化学部",
  "観光経営学部",
  "国際観光学部",
  "ホスピタリティ・ツーリズム学部",
  "学校教育学部",
  "学校教師学部",
  "キャリア形成学部",
  "キャリア実践学部",
  "キャリアデザイン学部",
  "教育学部",
  "教育地域学部",
  "教育地域科学部",
  "教育人間科学部",
  "教育福祉学部",
  "教育福祉科学部",
  "教育文化学部",
  "現代教育学部",
  "国際こども教育学部",
  "子ども育成学部",
  "子ども科学部",
  "こども学部",
  "子ども学部",
  "こども教育学部",
  "子ども教育学部",
  "こども心理学部",
  "子ども発達学部",
  "子ども未来学部",
  "次世代教育学部",
  "児童学部",
  "児童保育学部",
  "心身科学部",
  "生涯学習学部",
  "生涯学習システム学部",
  "地域教育文化学部",
  "人間教育学部",
  "人間発達科学部",
  "人間発達学部",
  "人間福祉学部",
  "発達科学部",
  "発達教育学部",
  "保育学部",
  "臨床教育学部",
  "会計ファイナンス学部",
  "危機管理学部",
  "企業情報学部",
  "現代経営学部",
  "現代ビジネス学部",
  "現代マネジメント学部",
  "サービス経営学部",
  "サービス産業学部",
  "サービス創造学部",
  "産業科学技術学部",
  "産業技術学部",
  "産業情報学部",
  "事業構想学部",
  "生産システム科学部",
  "知的財産学部",
  "ビジネス学部",
  "ビジネス情報学部",
  "ビジネス創造学部",
  "ビジネスデザイン学部",
  "不動産学部",
  "不動産学部",
  "マネジメント学部",
  "マネジメント創造学部",
  "流通科学部",
  "流通学部",
  "流通情報学部",
  "映画学部",
  "映像学部",
  "音楽学部",
  "学芸学部",
  "感性デザイン学部",
  "技能工芸学部",
  "空間創造学部",
  "芸術学部",
  "芸術地域デザイン学部",
  "芸術工学部",
  "芸術情報学部",
  "芸術文化学部",
  "健康・スポーツ科学部",
  "工芸科学部",
  "工芸学部",
  "生涯スポーツ学部",
  "鍼灸学部",
  "心身科学部",
  "スポーツ科学部",
  "スポーツ学部",
  "スポーツ健康科学部",
  "スポーツ・健康科学部",
  "スポーツ健康学部",
  "スポーツ健康政策学部",
  "スポーツ人間学部",
  "スポーツプロモーション学部",
  "スポーツ文化学部",
  "スポーツマネジメント学部",
  "先端芸術学部",
  "造形学部",
  "造形芸術学部",
  "造形表現学部",
  "創造芸術学部",
  "体育学部",
  "デザイン学部",
  "デザイン工学部",
  "東京メディア・コンテンツ学部",
  "美術学部",
  "美術工芸学部",
  "美術文化学部",
  "表現学部",
  "服飾学部",
  "服装学部",
  "文芸学部",
  "ホピュラーカルチャー学部",
  "マンガ学部",
  "メディア学部",
  "メディア情報学部",
  "メディア・芸術学部",
  "メディアコミュニケーション学部",
  "メディア造形学部",
  "メディアプロデュース学部",
  "環境・建築学部",
  "建築学部",
  "建築・環境学部",
  "IT総合学部",
  "医用工学部",
  "医療工学部",
  "医療福祉工学部",
  "開発工学部",
  "海洋工学部",
  "科学技術学部",
  "化学生命工学部",
  "環境都市工学部",
  "環境理工学部",
  "基幹工学部",
  "基幹理工学部",
  "基礎工学部",
  "基盤工学部",
  "九州工学部",
  "グローバルエンジニアリング学部",
  "工学資源学部",
  "工学部",
  "国際環境工学部",
  "コンピュータサイエンス学部",
  "コンピュータ理工学部",
  "産業工学部",
  "産業理工学部",
  "システム科学技術学部",
  "システム工学部",
  "システム情報科学部",
  "システムデザイン学部",
  "システム理工学部",
  "情報科学部",
  "情報学部",
  "情報工学部",
  "情報コミュニケーション学部",
  "情報システム学部",
  "情報通信学部",
  "情報通信工学部",
  "情報デザイン工学部",
  "情報ビジネス学部",
  "情報フロンティア学部",
  "情報マネジメント学部",
  "情報メディア学部",
  "情報理工学部",
  "情報連携学部",
  "生産工学部",
  "生命工学部",
  "生命システム工学部",
  "生命理工学部",
  "先進工学部",
  "先進理工学部",
  "総合情報学部",
  "総合理工学部",
  "創生工学部",
  "創造工学部",
  "創造理工学部",
  "ソフトウェア情報学部",
  "第一工学部",
  "第一理工学部",
  "第二工学部",
  "第二理工学部",
  "知識工学部",
  "知能情報学部",
  "デジタルコミュニケーション学部",
  "データサイエンス学部",
  "電気通信学部",
  "電子情報学部",
  "ネットワーク情報学部",
  "光科学部",
  "未来科学部",
  "理工学部",
  "ロボティクス&デザイン工学部",
  "歯学部",
  "生命歯学部",
  "新潟生命歯学部",
  "松戸歯学部",
  "行政社会学部",
  "現代社会学部",
  "現代日本社会学部",
  "現代福祉学部",
  "国際社会学部",
  "コミュニティ福祉学部",
  "産業社会学部",
  "社会安全学部",
  "社会イノベーション学部",
  "社会科学部",
  "社会学部",
  "社会環境学部",
  "社会共創学部",
  "社会システム科学部",
  "社会情報学部",
  "社会福祉学部",
  "生涯福祉学部",
  "情報社会科学部",
  "情報社会学部",
  "情報社会政策学部",
  "人文社会科学部",
  "人文社会学部",
  "総合社会学部",
  "総合人間科学部",
  "総合人間学部",
  "総合福祉学部",
  "ソーシャルワーク学部",
  "人間環境学部",
  "人間関係学部",
  "人間社会学部",
  "人間社会福祉学部",
  "福祉学部",
  "福祉健康学部",
  "福祉貢献学部",
  "福祉社会学部",
  "福祉情報学部",
  "福祉総合学部",
  "福祉保健学部",
  "保健福祉学部",
  "保健医療福祉学部",
  "未来デザイン学部",
  "応用心理学部",
  "現代心理学部",
  "心理学部",
  "心理科学部",
  "心理こども学部",
  "心理福祉学部",
  "心理・福祉学部",
  "臨床心理学部",
  "園芸学部",
  "海洋科学部",
  "海洋学部",
  "海洋生命科学部",
  "海洋生物資源学部",
  "環境園芸学部",
  "共同獣医学部",
  "獣医学部",
  "獣医畜産学部",
  "水産学部",
  "生物資源産業学部",
  "生物資源科学部",
  "生物生産学部",
  "地域創成農学部",
  "畜産学部",
  "動物看護学部",
  "農学生命科学部",
  "農林海洋科学部",
  "農学部",
  "酪農学部",
  "医療経営学部",
  "医療経営管理学部",
  "金融経済学部",
  "経営学部",
  "経営教育学部",
  "経営経済学部",
  "経営情報学部",
  "経営政策学部",
  "経営文化学部",
  "経済科学部",
  "経済学部",
  "経済経営学部",
  "経済情報学部",
  "現代政策学部",
  "公共政策学部",
  "国際経済学部",
  "国際政策学部",
  "国際政治経済学部",
  "コミュニティ政策学部",
  "商学部",
  "商経学部",
  "商船学部",
  "人文経営学部",
  "政経学部",
  "政策科学部",
  "政策学部",
  "政策情報学部",
  "政策創造学部",
  "政策マネジメント学部",
  "政治経済学部",
  "総合経営学部",
  "総合政策学部",
  "第一商学部",
  "第一政治経済学部",
  "第二商学部",
  "第二政治経済学部",
  "地域科学部",
  "地域学部",
  "地域共創学部",
  "地域協働学部",
  "地域経営学部",
  "地域資源創生学部",
  "地域政策学部",
  "地域創生学部",
  "地域創造学部",
  "地域デザイン科学部",
  "地域発展学部",
  "地域マネジメント学部",
  "都市経営学部",
  "都市経済学部",
  "福祉経営学部",
  "法経学部",
  "保健医療経営学部",
  "応用生物科学部",
  "応用生物学部",
  "応用生命科学部",
  "応用バイオ科学部",
  "生物学部",
  "生物産業学部",
  "生物生命学部",
  "生物地球学部",
  "生命医科学部",
  "生命科学部",
  "生命学部",
  "生命健康科学部",
  "バイオ・化学部",
  "バイオサイエンス学部",
  "バイオニクス学部",
  "フロンティアサイエンス学部",
  "アジア太平洋学部",
  "アジア太平洋マネジメント学部",
  "異文化コミュニケーション学部",
  "海事科学部",
  "海洋資源環境学部",
  "環境科学部",
  "環境学部",
  "環境共生学部",
  "環境システム学部",
  "環境社会学部",
  "環境情報学部",
  "環境情報ビジネス学部",
  "環境創造学部",
  "環境人間学部",
  "環境防災学部",
  "環境保健学部",
  "共生科学部",
  "共創学部",
  "教養学部",
  "グローバル教養学部",
  "グローバル・コミュニケーション学部",
  "グローバルスタディーズ学部",
  "グローバル地域文化学部",
  "グローバルビジネス学部",
  "グローバルマネジメント学部",
  "グローバル・メディア・スタディーズ学部",
  "現代教養学部",
  "現代国際学部",
  "現代コミュニケーション学部",
  "現代人間学部",
  "現代ライフ学部",
  "公益学部",
  "公共学部",
  "国際開発学部",
  "国際学部",
  "国際関係学部",
  "国際教養学部",
  "国際経営学部",
  "国際貢献学部",
  "国際交流学部",
  "国際コミュニケーション学部",
  "国際コミュニティ学部",
  "国際情報学部",
  "国際食料情報学部",
  "国際総合科学部",
  "国際地域学部",
  "国際地域創造学部",
  "国際日本学部",
  "国際人間科学部",
  "国際人間学部",
  "国際福祉開発学部",
  "国際文化学部",
  "国際文化交流学部",
  "国際文理学部",
  "コミュニティ振興学部",
  "シティライフ学部",
  "情報環境学部",
  "生物資源環境学部",
  "生物資源学部",
  "生命環境学部",
  "生命・環境科学部",
  "総合科学部",
  "総合学部",
  "総合管理学部",
  "総合キャリア学部",
  "総合光科学部",
  "総合マネジメント学部",
  "地域環境科学部",
  "地球環境科学部",
  "都市科学部",
  "都市環境学部",
  "都市教養学部",
  "都市情報学部",
  "都市デザイン学部",
  "21世紀アジア学部",
  "人間総合学部",
  "バイオ環境学部",
  "福祉環境学部",
  "未来創造学部",
  "ライフデザイン学部",
  "リベラルアーツ学部",
  "言語コミュニケーション学部",
  "言語文化学部",
  "現代文化学部",
  "交流文化学部",
  "国際人文学部",
  "コミュニケーション学部",
  "コミュニケーション文化学部",
  "情報文化学部",
  "神学部",
  "神道文化学部",
  "人文科学部",
  "人文学部",
  "総合文化学部",
  "総合文化政策学部",
  "第一文学部",
  "第二文学部",
  "日本文化学部",
  "人間開発学部",
  "人間科学部",
  "人間学部",
  "人間情報学部",
  "人間文化学部",
  "比較文化学部",
  "表象文化学部",
  "仏教学部",
  "文化学部",
  "文化教育学部",
  "文学部",
  "文化言語学部",
  "文化構想学部",
  "文化社会学部",
  "文化情報学部",
  "文化財学部",
  "文化政策学部",
  "文化創造学部",
  "文化表現学部",
  "文教育学部",
  "文理学部",
  "法文学部",
  "歴史学部",
  "経営法学部",
  "現代法学部",
  "第一法学部",
  "第二法学部",
  "法学部",
  "香川薬学部",
  "薬学部",
  "生物理工学部",
  "総合数理学部",
  "総合生命科学部",
  "総合生命理学部",
  "理学部"
]

export const QUALIFICATION_GROUPS = {
  "その他": 0,
  "医療": 1,
  "福祉・リハビリ・カウンセリング": 2,
  "法律": 3,
  "金融": 4,
  "建築・インテリア": 5,
  "教育・保育": 6,
  "運輸・交通": 7,
  "観光・国際": 8,
  "飲食": 9,
  "IT・情報": 10,
  "デザイン・アート": 11,
  "美容・ファッション": 12,
  "事務・秘書": 13,
}

export const QUALIFICATION = [
  '医師免許',
  '薬剤師免許',
  '看護師免許',
  '保健師免許',
  '助産師免許',
  '救急救命士免許',
  '歯科医師免許',
  '歯科衛生士免許',
  '歯科技工士免許',
  '臨床検査技師免許',
  '診療放射線技師免許',
  '臨床工学技士免許',
  '視能訓練士免許',
  '診療報酬請求事務能力認定試験',
  '医療秘書技能検定',
  '歯科助手検定',
  '登録販売者資格',
  'MR認定試験',
  '日本SMO協会公認CRC',
  '医療事務技能審査試験（メディカルクラーク）',
  '診察情報管理士',
  '獣医師免許',
  '臨床心理士資格',
  '介護福祉士資格',
  '社会福祉士資格',
  '精神保健福祉士資格',
  '介護支援専門員資格',
  '介護職員初任者研修',
  '理学療法士免許',
  '作業療法士免許',
  '言語聴覚士免許',
  '柔道整復師免許',
  'あん摩マッサージ指圧師免許',
  'はり師免許、きゅう師免許',
  '整体師、カイロプラクティック師',
  '義肢装具士免許',
  '社会福祉主事任用資格',
  '児童指導員資格',
  '産業カウンセラー資格',
  '介護事務管理士',
  '健康運動指導士資格',
  '音楽療法士資格',
  'アロマテラピーアドバイザー',
  '手話通訳技能認定',
  'CDA（ キャリア・ デベロップメント・ アドバイザー ）',
  'キャリア・コンサルティング技能士',
  '弁護士資格',
  '弁理士資格',
  '司法書士資格',
  '行政書士資格',
  '社会保険労務士資格',
  '公認会計士資格',
  '税理士資格',
  '生命保険募集人資格',
  'ファイナンシャルプランナー資格',
  'アクチュアリー',
  '建築士資格',
  '宅地建物取引主任者',
  '不動産鑑定士資格',
  '土地家屋調査士資格',
  'マンション管理士資格',
  '測量士資格',
  'インテリアコーディネーター資格',
  '空間ディスプレイデザイナー',
  'インテリアデザイナー資格',
  '建築大工技能士',
  '左官技能士',
  '配管技能士',
  '建築板金技能士',
  '土木施工管理技士',
  '電気工事士',
  '潜水士免許',
  '保育士免許',
  '幼稚園教員免許',
  '認定ベビーシッター',
  '小学校教員免許',
  '中学校教員免許',
  '高校教員免許',
  '養護教諭教員免許',
  '特別支援学校教諭免許',
  '司書資格',
  '日本語教育能力検定',
  '学芸員資格',
  '職業訓練指導員免許',
  '定期運送用操縦士',
  '二等航空整備士',
  '航空無線通信士',
  '運航管理者技能検定',
  '一級〜六級海技士',
  '動力車操縦者運転免許',
  '普通自動車第二種運転免許',
  '中型自動車免許',
  '大型二種自動車運転免許',
  '1級〜3級自動車整備士',
  '通関士資格',
  '貿易実務検定',
  'ホテル実務技能認定検定試験',
  '旅程管理主任者',
  '通訳案内士資格',
  'JTA公認翻訳専門職資格',
  '実用英語技能検定',
  'TOEIC600点以上',
  'TOEIC800点以上',
  '栄養士免許',
  '管理栄養士免許',
  '製菓衛生師免許',
  '調理師免許',
  'ソムリエ資格',
  'NBA認定バーテンダー資格',
  'フードコーディネーター検定',
  'ジュニア野菜ソムリエ',
  'パン製造技能士',
  '臭気判定士',
  '情報処理技術者',
  'CGクリエイター検定',
  'ウェブデザイン技能検定',
  'CCENT',
  'CAD利用技術者試験',
  'Photoshopクリエイター能力認定試験',
  'プロダクトデザイン検定',
  'カラーコーディネーター',
  '美容師免許',
  '理容師免許',
  'ネイリスト技能検定試験',
  '認定エステティシャン',
  '洋裁技術検定',
  'パターンメーキング技術検定',
  '和裁技能士',
  '貴金属装身具製作技能士',
  '秘書検定',
  '日商簿記検定',
  'MOS（ マイクロソフト オフィス スペシャリスト）',
  '文書情報管理士'
]
