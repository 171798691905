export function postUserFavorite(user_id, recruit_id, hadFavorite) {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  var method = hadFavorite ? "DELETE" : "POST";
  return $.ajax({
    url: "/company/ajax/company/favorite",
    method: method,
    data: {
      user_id: user_id,
      recruit_id: recruit_id,
    },
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  })
}

export function api(method, endpoint, params) {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  return $.ajax({
    url: endpoint,
    method: method,
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    data: params,
    dataType: 'json',
  });
}

export function apiFormData(method, endpoint, params) {
  var csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  var formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (params[key] !== null) {
      formData.append(`form[${key}]`, params[key])
    }
  })

  return $.ajax({
    url: endpoint,
    method: method,
    processData: false,
    contentType: false,
    data: formData,
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    dataType: 'json',
  });
}

export function postHiringProcessChecked(checkType, isChecked) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const method = isChecked ? 'PATCH' : 'DELETE';
  return $.ajax({
    url: '/company/ajax/mypage',
    method: method,
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    data: {
      check_type: checkType
    },
  })
}

export function postProccessUpdate(proccess, id, recruitId, userId) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  return $.ajax({
    url: '/company/applicant/proccess_update',
    method: 'PATCH',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
    data: {
      proccess: proccess,
      id: id,
      recruit_id: recruitId,
      user_id: userId,
    },
  })
}
