import { ChartA } from '../../utils/canvas_chart/chart-a.js';
import { ChartB } from '../../utils/canvas_chart/chart-b.js';
import { ChartC } from '../../utils/canvas_chart/chart-c.js';

const userColor = '#c6c031';
const companyColor = '#0f93e6';

const createObserver = (chart) => {
  return new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      mutation.target.classList.contains('is-active') && chart.render();
    });
  });
}

$(window).on('turbolinks:load', function() {
  if (document.getElementById('resultChartA')) {
    const canvas = document.getElementById('resultChartA');
    const canvasWrapper = canvas.parentElement;
    const userScore = canvas.dataset.userScore && JSON.parse(canvas.dataset.userScore);
    const companyScore = canvas.dataset.companyScore && JSON.parse(canvas.dataset.companyScore);

    const chartA = new ChartA(canvas, canvasWrapper);
    userScore && chartA.addChart({ color: userColor, score: userScore });
    companyScore && chartA.addChart({ color: companyColor, score: companyScore });
    chartA.render();

    window.addEventListener('resize', () => chartA.render());
    const observedElement = canvas.closest('.js-dropDown-body')?.previousElementSibling;
    observedElement && createObserver(chartA).observe(observedElement, { attributes: true, childList: false, subtree: false });
  }

  if (document.getElementById('resultChartB')) {
    const canvas = document.getElementById('resultChartB');
    const canvasWrapper = canvas.parentElement;
    const userScore = canvas.dataset.userScore && JSON.parse(canvas.dataset.userScore);
    const companyScore = canvas.dataset.companyScore && JSON.parse(canvas.dataset.companyScore);

    const chartB = new ChartB(canvas, canvasWrapper);
    userScore && chartB.addChart({ color: userColor, score: userScore });
    companyScore && chartB.addChart({ color: companyColor, score: companyScore });
    chartB.render();

    window.addEventListener('resize', () => chartB.render());
    const observedElement = canvas.closest('.js-dropDown-body')?.previousElementSibling;
    observedElement && createObserver(chartB).observe(observedElement, { attributes: true, childList: false, subtree: false });
  }

  if (document.getElementById('resultChartC')) {
    const canvas = document.getElementById('resultChartC');
    const canvasWrapper = canvas.parentElement;
    const userScore = canvas.dataset.userScore && JSON.parse(canvas.dataset.userScore);
    const companyScore = canvas.dataset.companyScore && JSON.parse(canvas.dataset.companyScore);

    const chartC = new ChartC(canvas, canvasWrapper);
    userScore && chartC.addChart({ color: userColor, score: userScore });
    companyScore && chartC.addChart({ color: companyColor, score: companyScore });
    chartC.render();

    window.addEventListener('resize', () => chartC.render());
    const observedElement = canvas.closest('.js-dropDown-body')?.previousElementSibling;
    observedElement && createObserver(chartC).observe(observedElement, { attributes: true, childList: false, subtree: false });
  }
});
